import React, { Fragment, useState, Children, cloneElement, forwardRef, useImperativeHandle, useEffect } from 'react';
import { AppBar, Tabs, Tab, Box, Grid, Icon } from '@mui/material';
import { Tooltip, Button } from 'Components';
import TabScrollButton from '@mui/material/TabScrollButton';
import colors from 'Assets/sass/core/vars.scss';

import './tabs.scss';

const customStyleTabScrollButton = {
	width: '42px',
	height: '42px',
	margin: '3px',
	borderRadius: '50%',
	//overflow: 'hidden',
	transition: 'width 0.5s',
	'&.Mui-disabled': {
		width: 0,
		margin: 0
	},
	'&:hover': {
		backgroundColor: colors.color_tooptip_tab,
		color: colors.color_icon_tab,
	}
}

const IconIndicator = (props) => {
	const { iconIndicator, label, index, value, badge } = props;

	const renderIconIndicator = () => {
		return (
			<span className={`MuiButtonBase-root MuiIconButton-root icon-indicator ${value === index ? 'active' : ''}`}>
				<Icon size="small">{iconIndicator}</Icon>
				{
					badge &&
					<span className={`info-bubble-badge info-bubble-badge-red tab-icon-indicator-badge`}>{badge}</span>
				}
			</span>
		)
	}

	return (
		<Fragment>
			{
				label ?
					<Tooltip title={label} placement={props.placement || 'bottom'}>
						{renderIconIndicator()}
					</Tooltip>
					:
					(renderIconIndicator())
			}

		</Fragment>
	)
}

const IconSpan = (props) => {
	const { iconButton, label, index, value } = props;

	const renderIconSpan = () => {
		return (
			<div className={`MuiButtonBase-root MuiIconButton-root icon-span ${value === index ? 'active' : ''}`}>
				<Icon fontSize="small">{iconButton}</Icon>
			</div>
		)
	}

	return (
		<Fragment>
			{
				label ?
					<Tooltip title={label} placement={props.placement || 'bottom'}>
						{renderIconSpan()}
					</Tooltip>
					:
					(renderIconSpan())
			}

		</Fragment>
	)
};

const TabPanel = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { children, value, index, renderAlways, flagRender } = props;

	return (
		<div
			//role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
		>
			{
				(flagRender.includes(index) || renderAlways) &&
				(
					<Box p={3} style={props.style ? props.style : {}} className={props.className}>
						{children}
					</Box>
				)
			}
		</div>
	);
};

export const TabContainer = (props) => {
	return (
		<TabPanel
			value={props.value}
			index={props.index}
			flagRender={props.flagRender}
			style={props.style ? props.style : {}}
			renderAlways={props.renderAlways}
			className={props.className}
		>
			{props.children}
		</TabPanel>
	);
};

export const TabButton = (props) => {
	return (
		<Button {...props}>
			{props.title}
		</Button>
	)
}

export const TabIconButton = (props) => {
	const { icon, size, tooltip } = props
	return (
		<Button tooltip={tooltip} className="icon-span" type="icon" icon={icon} size={size || "small"} {...props} />
	)
}

export default forwardRef((props, ref) => {
	const [value, setValue] = useState(props.tab || 0);
	const [flagRender, setFlagRender] = useState([props.tab || 0]);

	useImperativeHandle(ref, () => ({
		selectedTab: value,
		onChangeSelectedTab: (index) => { setValue(index) }
	}))

	useEffect(() => {
		if (props.tab != undefined && props.tab != null) {
			changeTab(props.tab);
		}
	}, [props.tab]);

	useEffect(() => {
		if (props.tabs !== undefined) {
			changeTab(props.tabs.length - 1);
		}
	}, [props.tabs])

	const changeTab = (position) => {
		if (!flagRender.includes(position)) {
			flagRender.push(position);
			setFlagRender(flagRender);
		}

		setValue(position);
	};

	const renderHeader = (item) => {
		const { icon, iconButton, iconIndicator, label, closeable, onCloseTab } = item;

		if (iconButton) {
			return (
				<IconSpan {...item} />
			);
		} else if (iconIndicator) {
			return (
				<IconIndicator {...item} />
			);
		} else {
			return (
				<Fragment>
					{
						icon &&
						<Icon style={{ fontSize: 20, marginRight: 10 }}>{icon}</Icon>
					}

					<span>{label}</span>
				</Fragment>
			);
		}
	}

	const headerTab = (item, index) => {
		const { icon, iconButton, label, closeable, onCloseTab, customIconRight } = item;

		return (
			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				style={{ width: 'auto' }}
			>
				{renderHeader(item)}

				{
					closeable && //index === value &&
					<div style={{ paddingLeft: 5, position: 'absolute', right: 10 }}>
						<a
							type="icon"
							icon="close"
							className="btn-close"
							onClick={(e) => {
								//not calling changeTab event
								e.stopPropagation()
								//call remove tab
								onCloseTab(index)
							}}
						>
							<Icon>close</Icon>
						</a>
					</div>
				}
				{customIconRight}
			</Grid >
		)
	}

	const onCloseTab = (index) => {
		if (props.onCloseTab) {
			changeTab(index - 1)
			props.onCloseTab(index)
		}
	}

	let tabsChildrenWithProps = [];
	let buttonsChildrenWithProps = [];
	let iconButtonsChildrenWithProps = [];
	Children.forEach(props.children, (child, i) => {
		if (child && child.type === TabIconButton) {
			iconButtonsChildrenWithProps.push(cloneElement(child, { value, flagRender, onCloseTab: onCloseTab, key: i }));
		} else if (child && child.type === TabButton) {
			buttonsChildrenWithProps.push(cloneElement(child, { value, flagRender, onCloseTab: onCloseTab, key: i }));
		} else if (child) {
			tabsChildrenWithProps.push(cloneElement(child, { value, flagRender, onCloseTab: onCloseTab, key: i }));
		}
	});

	return (
		<Fragment>
			{
				props.tabsPosition === "top" &&
				<React.Fragment>
					{tabsChildrenWithProps}
					{
						props.border &&
						<div style={{ borderTop: 'solid 1px #e4e4e4' }}></div>
					}
				</React.Fragment>
			}

			<AppBar position="static" elevation={0} style={{ backgroundColor: 'white' }}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="flex-start"
				>
					{
						props.title &&
						<Grid item xs={!props.customElement ? true : false}>
							{props.title}
						</Grid>
					}

					<Grid
						item
						xs
						container
						direction="row"
						justifyContent={props.title && props.customElement ? "center" : 'flex-start'}
						alignItems="flex-start"
					>
						<Grid item xs>
							<Tabs
								className={props.tabsClassName || `tabs${props.type ? '-' + props.type : ''} ${props.id}`}
								value={value}
								onChange={(e, newTabPosc) => {
									if(props.disabled) return
									e.stopPropagation()
									changeTab(newTabPosc);
									if (props.onChange) {
										props.onChange(newTabPosc);
									}
								}}
								ScrollButtonComponent={(prop) => {
									if (props.showTooltip) {
										return (
											<Tooltip title={props.labelTooltip || ''} placement={props.positionTooltip || 'bottom'}>
												<TabScrollButton {...prop} sx={customStyleTabScrollButton} />
											</Tooltip>
										)
									} else {
										return (
											<TabScrollButton {...prop} sx={customStyleTabScrollButton} />
										)
									}
								}}
								variant={props.scrollButtons && 'scrollable'}
							//scrollButtons={props.scrollButtons ? 'on' : 'off'}
							//aria-label={props.scrollButtons ? 'scrollable force tabs example' : 'simple tabs example'}
							>
								{
									//is array
									Array.isArray(tabsChildrenWithProps)
										? (
											tabsChildrenWithProps.map((item, index) => {
												return (
													<Tab
														key={index}
														label={headerTab(item.props, index)}
														className={`responsive-name-report ${item.props.className}-${index} ${props.tabClassName || `tab${props.type ? '-' + props.type : ''}`} ${item.props.iconButton ? 'icon-span-tab' : ''}`}
														disableRipple
														disabled={item.props?.disabled}
													/>
												);
											})
										)
										: (
											<Tab
												key={0}
												label={headerTab(tabsChildrenWithProps.props)}
												className={`${props.tabClassName || `tab${props.type ? '-' + props.type : ''}`} ${props.iconButton ? 'icon-span-tab' : ''}`}
												disableRipple
											/>
										)
								}
								{
									props.customButton &&
									<div>
										{
											props.customButton()
										}
									</div>
								}
							</Tabs>
						</Grid>
					</Grid>

					{
						props.customElement &&
						<Grid item>{props.customElement}</Grid>
					}
					{(iconButtonsChildrenWithProps.length > 0) &&
						<Grid item xs style={{ padding: 16 }}>
							{iconButtonsChildrenWithProps}
						</Grid>
					}
					{
						(buttonsChildrenWithProps.length > 0) &&
						<Grid item xs style={{ textAlign: 'right', padding: 16 }}>
							{buttonsChildrenWithProps}
						</Grid>
					}
				</Grid>
			</AppBar>

			{
				(!props.tabsPosition || props.tabsPosition === "bottom")
				&&
				<React.Fragment>
					{
						props.border &&
						<div style={{ borderTop: 'solid 1px #e4e4e4' }}></div>
					}
					{tabsChildrenWithProps}
				</React.Fragment>
			}
		</Fragment>
	);
});
