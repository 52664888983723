import React, { useEffect, useRef, useState, useMemo, useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';

import SidePanel, { SidePanelBody, SidePanelHeader } from 'Components/SidePanel';
import Map from 'Components/Map'
import { Grid } from '@mui/material';

const MapModal = (props) => {

  const { zoom } = props;
  const [showMap, setShowMap] = useState(false)
  const [pointers, setPointers] = useState([])
  const mapRef = useRef()
  /* const landmark = useSelector(state => state.reportsMainRTRedux.landmark) */

  /* useEffect(() => {
      if (mapRef?.current?.drawLandmark && landmark) {
          const { radius, coordinatesCenter } = getCordinatesAndRadius(landmark)
          mapRef.current.drawLandmark(landmark, radiusFeetToMeters(radius), coordinatesCenter);
          mapRef.current.centerByFitBounds(coordinatesCenter, radius)
      }
  }, [mapRef?.current?.drawLandmark]); */

  useEffect(() => {
    if (pointers?.length == 0 && props?.pointers && props?.pointers?.length > 0) {
      setPointers(props.pointers);
      if (props?.zoom) {
        let timer = setTimeout(() => {
         mapRef?.current?.centerPointOnMap(props?.pointers[0]?.latitude || 0, props?.pointers[0]?.longitude || 0, zoom);
          timer = null;
        }, 200);
       
      }
    }
  }, [props?.pointers, zoom]);

  useEffect(() => {
    if (props?.show && !showMap) {
      setShowMap(true)
    }
  }, [props?.show])

  const onToggleSideMap = useCallback((show, _pointers) => {
    setShowMap(value => show || !value)
    if (!show) {
      setPointers([])
      if (props.hideModal)
        props.hideModal()
    }
    mapRef.current = null;
  }, [])

  return (
    <SidePanel
      style={{ zIndex: 20, width: showMap ? '50%' : '' }}
      hiddenButton={props.hiddenButton || false}
      overlay={true}
      isOpen={showMap}
      toggle={() => onToggleSideMap()}
    >
      <SidePanelHeader>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <h2 style={{ margin: 0 }}>{props?.title}</h2>
          </Grid>
        </Grid>
      </SidePanelHeader>
      <SidePanelBody
        style={{
          height: "calc(100% - 145px)"
        }}
      >
        {
          showMap &&
          <Map
            ref={mapRef}
            units={pointers}
            noRealtime={true}
            {...props}
          />
        }
      </SidePanelBody>
    </SidePanel>
  )
}

export default injectIntl(MapModal);