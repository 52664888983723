import realtimeMapRedux from 'Modules/realtimeMaps/main/redux/reducer';
import realtimeMapUtilitiesRedux from 'Modules/realtimeMaps/main/components/MapUtilitiesMenu/redux/reducer';
import realtimeMapUtilitiesVehiclesListRedux from 'Modules/realtimeMaps/main/components/VehicleList/redux/reducer';
import realtimeMapUtilitiesVisibleGroupsTagsRedux from 'Modules/realtimeMaps/main/components/modalVisibleGroupTags/redux/reducer';
import realtimeMapUtilitiesvehiclesTrailRedux from 'Modules/realtimeMaps/main/components/VehicleTrails/redux/reducer';
import realtimeMapLandmarkModuleRedux from 'Modules/realtimeMaps/main/components/LandmarksListMain/redux/reducer'; //remove
import realtimeMapLandmarkBulkModuleRedux from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkBulk/redux/reducer';
import realtimeMapLandmarkCreateEditModuleRedux from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkCreateEdit/redux/reducer';
import realtimeMapLandmarkDeleteModuleRedux from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkDelete/redux/reducer';
import realtimeMapLandmarkGroupDeleteModuleRedux from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkGroupDelete/redux/reducer';
import realtimeMapLandmarkGroupCreateEditModuleRedux from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkGroupCreateEdit/redux/reducer';
import realtimeMapLandmarkGroupVisibleModuleRedux from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkVisibleGroups/redux/reducer';
import realtimeMapLandmarkSettingsModuleRedux from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkSettings/redux/reducer';
import realtimeMapLandmarkVisibleModuleRedux from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkVisibleGroups/redux/reducer';
import realtimeMapLandmarkListModuleRedux from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkList/redux/reducer';
import realtimeMapLanndmarkVisibleTagsAssignRedux from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkVisibleTags/redux/reducer';
import realtimeMapRoutesRedux from 'Modules/realtimeMaps/main/components/routes/redux/reducer';
import realtimeMapGeofenceModuleRedux from 'Modules/realtimeMaps/main/components/GeofenceListMain/redux/reducer';
import realtimeMapNewGeofenceModuleRedux from 'Modules/realtimeMaps/main/components/geofences/components/geofenceList/redux/reducer';
import realtimeMapGeofenceCreateEditModuleRedux from 'Modules/realtimeMaps/main/components/geofences/components/geofenceCreateEdit/redux/reducer';
import realtimeMapGeofenceGroupCreateEditModuleRedux from 'Modules/realtimeMaps/main/components/geofences/components/geofenceGroupCreateEdit/redux/reducer';
import realtimeMapGeofenceDeleteModuleRedux from 'Modules/realtimeMaps/main/components/geofences/components/geofenceDelete/redux/reducer';
import realtimeMapGeofenceGroupDeleteModuleRedux from 'Modules/realtimeMaps/main/components/geofences/components/geofenceGroupDelete/redux/reducer';
import realtimeMapGeofenceSettingsViewUnitsModuleRedux from 'Modules/realtimeMaps/main/components/geofences/components/geofenceSettingsViewUnits/redux/reducer'
import realtimeMapGeofenceBulkModuleRedux from 'Modules/realtimeMaps/main/components/geofences/components/geofenceBulk/redux/reducer';
import realtimeMapSendMessageModuleRedux from 'Modules/realtimeMaps/main/components/SendMessage/redux/reducer';
import realtimeMapLayerMapModuleRedux from 'Modules/realtimeMaps/main/components/LayerMap/redux/reducer';
import realtimeMapFindNearestRedux from 'Modules/realtimeMaps/main/components/findNearest/redux/reducer';
import realtimeMapUnitSelectionRedux from 'Modules/realtimeMaps/main/components/UnitSelection/redux/reducer';
import realtimeMapGeofenceContactsRedux from 'Modules/realtimeMaps/main/components/geofences/components/geofenceContacts/redux/reducer';
import realtimeMapGeofenceAssignRedux from 'Modules/realtimeMaps/main/components/geofences/components/geofenceAssign/redux/reducer';
import realtimeMapGeofenceVisibleTagsAssignRedux from 'Modules/realtimeMaps/main/components/geofences/components/geofenceVisibleTags/redux/reducer';
import realtimeMapLandmarkImportModuleRedux from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkImport/redux/reducer';
import realtimeMapSpeedOverrideCreateEditRedux from 'Modules/realtimeMaps/main/components/speedOverride/components/speedOverrideCreateEdit/redux/reducer';
import realtimeMapSpeedOverrideListRedux from 'Modules/realtimeMaps/main/components/speedOverride/components/speedOverrideList/redux/reducer';
import realtimeMapSpeedOverrideDeleteRedux from 'Modules/realtimeMaps/main/components/speedOverride/components/speedOverrideDelete/redux/reducer';

import realtimeMapsRoutesTasksRedux from '../../main/components/routes/redux/slice'
import realtimeMapsRoutesModalCreateEditRoute from '../../main/components/routes/components/modalCreateEditRoute/redux/slice'
import realtimeMapsRoutesModalUploadRoute from '../../main/components/routes/components/uploadRoute/redux/slice'
import realtimeMapsVicinityTool from '../../main/components/vicinityTool/redux/slice'

import filesRedux from "../../main/components/LayerMap/redux/slice"
import realtimeMapEtaLinkRedux from '../../main/components/eta/redux/slice'
import realtimeMapEtaLinkCreateEditRedux from '../../main/components/eta/components/etaCreateEdit/redux/slice'
import etaDeleteRedux from '../../main/components/eta/components/etaDelete/redux/slice'
import etaContactsRedux from '../../main/components/eta/components/etaContacts/redux/slice'
//export redux objects
export default {
    realtimeMapRedux,
    realtimeMapUtilitiesRedux,
    realtimeMapUtilitiesVehiclesListRedux,
    realtimeMapUtilitiesVisibleGroupsTagsRedux,
    realtimeMapRoutesRedux,
    realtimeMapUtilitiesvehiclesTrailRedux,
    realtimeMapLandmarkModuleRedux,
    realtimeMapLandmarkBulkModuleRedux,
    realtimeMapLandmarkCreateEditModuleRedux,
    realtimeMapLandmarkDeleteModuleRedux,
    realtimeMapLandmarkGroupCreateEditModuleRedux,
    realtimeMapLandmarkSettingsModuleRedux,
    realtimeMapLandmarkVisibleModuleRedux,
    realtimeMapLanndmarkVisibleTagsAssignRedux,
    realtimeMapGeofenceModuleRedux,
    realtimeMapNewGeofenceModuleRedux,
    realtimeMapGeofenceCreateEditModuleRedux,
    realtimeMapGeofenceGroupCreateEditModuleRedux,
    realtimeMapGeofenceDeleteModuleRedux,
    realtimeMapGeofenceGroupDeleteModuleRedux,
    realtimeMapGeofenceSettingsViewUnitsModuleRedux,
    realtimeMapGeofenceBulkModuleRedux,
    realtimeMapSendMessageModuleRedux,
    realtimeMapLayerMapModuleRedux,
    realtimeMapFindNearestRedux,
    realtimeMapLandmarkGroupDeleteModuleRedux,
    realtimeMapLandmarkGroupVisibleModuleRedux,
    realtimeMapUnitSelectionRedux,
    realtimeMapGeofenceContactsRedux,
    realtimeMapGeofenceAssignRedux,
    realtimeMapGeofenceVisibleTagsAssignRedux,
    realtimeMapLandmarkListModuleRedux,
    realtimeMapLandmarkImportModuleRedux,
    realtimeMapSpeedOverrideCreateEditRedux,
    realtimeMapSpeedOverrideListRedux,
    realtimeMapSpeedOverrideDeleteRedux,

    realtimeMapsRoutesTasksRedux,
    realtimeMapsRoutesModalCreateEditRoute,
    realtimeMapsRoutesModalUploadRoute,
    realtimeMapsVicinityTool,
    filesRedux,
    realtimeMapEtaLinkRedux,
    realtimeMapEtaLinkCreateEditRedux,
    etaDeleteRedux,
    etaContactsRedux
};