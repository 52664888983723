import React, { useEffect, Fragment, useCallback, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { endpoints } from 'Core/defaultValues';
import { LogManagerConsole } from 'Core/logManager';

import {
	catalogInitial,
	catalogProcessItem,
	catalogProcessItems,
	refreshToken,
	catalogInitialRefresh,
	catalogSyncPushNotifications,
	logout,
	catalogInitialGetUnit,
	clearRedux
} from 'Redux/actions';

import { processNotification, updateActivityUser } from './redux/slice'


var newInterval = null
let newIntervalHeartBeat = null
var client;
var autoRefresh;
var autoRefreshPN;
var autoConnectSocket = null
var catalogRefresh = false
var lastDatacatalogTime = new Date()
var batchArray = []

let cantAttemps = 0

const Catalog = () => {

	const dispatch = useDispatch();
	const { user, refreshTokenResult, systemPreference } = useSelector(state => state.securityRedux);
	//const [cantAttemps, setCantAttemps] = useState(0)
	const navigate = useNavigate()

	LogManagerConsole("DC", "cantAttemps", cantAttemps)

	//Efect to create socket connection
	useEffect(() => {
		//get variables to override from global preferences
		let refreshTime = systemPreference && systemPreference[process.env.STACK]?.refreshTime || 0
		refreshTime = Number(refreshTime)

		let offSocketConnect = systemPreference && systemPreference[process.env.STACK]?.offSocketConnect || 0
		offSocketConnect = Number(offSocketConnect)

		let refreshTimePN = systemPreference && systemPreference[process.env.STACK]?.refreshTimePN || 0
		refreshTimePN = Number(refreshTimePN)

		LogManagerConsole("DC_Diferencial", "refreshTime", refreshTime, "refreshTimePN", refreshTimePN, "offSocketConnect", offSocketConnect)

		//load initial values
		dispatch(catalogInitial());
		dispatch(updateActivityUser({}))

		//if is enable Diferential Units info
		if (refreshTime > 0) {

			const refreshTimeCallback = () => {
				//valid only for this company
				if (user.companyId == 26091) {
					refreshTime = 15
				}

				autoRefresh && clearTimeout(autoRefresh)
				autoRefresh = setTimeout(() => {
					LogManagerConsole("DC_Diferencial", "Evaluate in")
					let lastReadingSyncString = sessionStorage.getItem("lastSyncLastReading") || moment().add(-1, "minutes").toString()
					let lastReadingSync = moment(lastReadingSyncString).add(refreshTime - 1, 'seconds');

					let lastDatacatalogSync = moment(lastDatacatalogTime).add(refreshTime - 1, 'seconds');
					LogManagerConsole("DC_Diferencial", "Evaluate", lastReadingSync)
					//update info when socket is down or not return information.

					if (lastReadingSync < moment()) {

						// if (offSocketConnect == 0 && lastDatacatalogSync >= moment())
						// 	return false

						//eval the time with dataCatalog
						LogManagerConsole("DC_Diferencial", "Automatic Refresh LastReading")
						sessionStorage.setItem("lastSyncLastReading", moment())
						dispatch(catalogInitialRefresh(refreshTime));
					}

					refreshTimeCallback()
				}, refreshTime * 1000)
			}

			refreshTimeCallback()
		}

		//if is enable PushNotification Diferential
		if (refreshTimePN > 0) {

			const refresTimePNCallback = () => {
				autoRefreshPN && clearTimeout(autoRefreshPN)
				autoRefreshPN = setTimeout(() => {
					let lastSyncNotificationString = sessionStorage.getItem("lastSyncNotification") || moment().add(-1, "minutes").toString()
					let lastSyncNotificationSync = moment(lastSyncNotificationString).add(refreshTimePN, 'seconds');
					if (lastSyncNotificationSync < moment()) {
						sessionStorage.setItem("lastSyncNotification", moment())
						let sessionId = sessionStorage.getItem("sessionId")
						dispatch(catalogSyncPushNotifications(sessionId, user.companyId, { start: moment().add(-2, "hours"), end: moment().toDate() }));
					}

					refresTimePNCallback()
				}, refreshTimePN * 1000)
			}

			refresTimePNCallback()
		}


		//if socket is enabled
		if (offSocketConnect == 0) {
			LogManagerConsole("DC", 'init socket');
			createSocketConnection()
		}

		return () => {
			client && client.close();
			if (autoRefresh) clearTimeout(autoRefresh)
			if (autoRefreshPN) clearTimeout(autoRefreshPN)
			if (autoConnectSocket) clearTimeout(autoConnectSocket)
		};

	}, []);

	if (!newIntervalHeartBeat) {
		newIntervalHeartBeat = setInterval(() => {
			dispatch(updateActivityUser({}))
		}, 120000);
	}

	useEffect(() => {

		LogManagerConsole("DC", refreshTokenResult)
		if (refreshTokenResult) {
			dispatch(clearRedux("DC", "AUTHCOMPONENT", { refreshTokenResult: false }))

			if (client && client?.open) {
				LogManagerConsole("DC", "refresh socket is open")
				//client.open()
			}
			else {
				LogManagerConsole("DC", "refresh socket create")
				reconnectSocket()
			}
		}
	}, [refreshTokenResult])

	const createSocketConnection = () => {
		LogManagerConsole("DC", "Create Conexion")

		if (client && client.readyState === client.OPEN) {
			clearTimeout(autoConnectSocket)
			autoConnectSocket = null
			LogManagerConsole("DC", 'Its already connect');
			return
		}

		catalogRefresh = true
		let WSSURL = systemPreference && systemPreference[process.env.STACK]?.wss || endpoints.WSS_READINGS;
		//sockect
		const token = localStorage.getItem('IdToken');
		const wss = WSSURL + '?token=' + token + "&appId=px-cloud";
		try {
			clearTimeout(autoConnectSocket)
			autoConnectSocket = null
			client = new WebSocket(wss);
		} catch (e) {
			LogManagerConsole("DC", 'ws error', e);
			//reconnectSocket(e);
		}


		const processTopics = (dataArray) => {
			["Unit", "LastReading", "VehicleInformation", "Notification"].forEach(entity => {
				let items = dataArray.filter(x => x.entityName == entity)

				//console.log('Procesando los topicos', items);
				LogManagerConsole("DC", "entites", entity, items)
				if (entity == "LastReading" && items.length > 0) {
					LogManagerConsole("DC", "LastReading", items)
					sessionStorage.setItem("lastSyncLastReading", moment())
				}
				if (entity != "Notification") {
					if (items.length > 0)
						if(items && items.length > 0 && items[0].entityName == 'Unit' && (items[0].event == 'created' || items[0].event == 'updated')){
							dispatch(catalogInitialGetUnit({ ids: items.map((element) => element.entityId) }))
						}else{
							dispatch(catalogProcessItems(entity, items.map(item => {
								if (item?.entityId) {
									return { ...item.payload, id: item.entityId } || {}
								} else {
									return { ...item.payload } || {}
								}
							})));
						}
				}
				else {
					items.forEach(notification => {
						dispatch(catalogProcessItem("Notification", notification.payload));
						dispatch(processNotification({ notification: notification.payload }))
					})
				}
			})
		}

		if (!newInterval) {
			newInterval = setInterval(() => {
				let batchArrayToProcess = [...batchArray];
				batchArray = []
				if (batchArrayToProcess.length > 0) {
					processTopics(batchArrayToProcess)
				}
			}, 2000);
		}

		client.onmessage = ({ data }) => {
			LogManagerConsole("DC2", "DataMessage", data)
			let messageJSON = JSON.parse(data);
			lastDatacatalogTime = new Date()

			if (Array.isArray(messageJSON)) {
				batchArray.push(...messageJSON)
				//processTopics(messageJSON)
			} else {
				batchArray.push(messageJSON)
				//processTopics([messageJSON])
			}


		};

		client.onopen = () => {
			cantAttemps = 0

			let authId = "PX_" + user.companyId;
			if (user.isSubUser)
				authId = authId + '_' + user.subUserId

			let topics = "Unit"

			let wssWithoutRedings = systemPreference && systemPreference[process.env.STACK]?.wssWithoutRedings || 0
			//console.log("offSocketConnect", offSocketConnect)
			wssWithoutRedings = Number(wssWithoutRedings)
			if (wssWithoutRedings == 0) {
				topics = "Unit, LastReading, VehicleInformation"
			}
			topics = "Unit, LastReading, VehicleInformation"

			LogManagerConsole("DC", 'connected socket', authId, topics);
			client.send(
				JSON.stringify({
					"action": "subscribeToTopics",
					"topics": topics
				}),
			);
		};

		client.onclose = (e) => {
			LogManagerConsole("DC", 'onclose', e.type);
			reconnectSocket("close");
		}

		client.onerror = (e) => {
			if (client.readyState == 1) {
				LogManagerConsole("DC", 'ws error after open connection', e.type);
			}
			LogManagerConsole("DC", 'ws error', e.type);
			client && client.close();
		};
	}

	const reconnectSocket = (e) => {

		clearTimeout(autoConnectSocket)
		let newCantAttemps = cantAttemps + 1
		LogManagerConsole("DC", 'reconnectSocket', newCantAttemps, e);

		cantAttemps = newCantAttemps
		//if (cantAttemps < 5) {
		autoConnectSocket = setTimeout(() => {
			autoConnectSocket = null
			catalogRefresh = false
			createSocketConnection()
			LogManagerConsole("DC", "connectSockect")

		}, newCantAttemps < 5 ? newCantAttemps * 10000 : 60000)
		//}
	}

	return <></>;
};



export default Catalog;
