// View Keys

export default {
    reportsMainRT_yes: 'Yes',
    reportsMainRT_no: 'No',
    reportsMainRT_advancedSummaryReport: 'Advanced Summary Report',
    reportsMainRT_advancedSummaryReportInfo: 'Lists total Drive, Idle and Stop time, # of Stops, Average Stop Time, # of Idles, Average Idle Time, Start Odometer, End Odometer and Distance.',
    reportsMainRT_alertLogReport: 'Alert Log Report',
    reportsMainRT_alertLogReportInfo: 'Organized by Vehicle and Day, shows any Alerts triggered, includes Time & Date, Address, Driver, Contacts, Alert Type and Alert Detail.',
    reportsMainRT_deviceSummaryInfoReport: 'Device Summary Info Report',
    reportsMainRT_deviceSummaryInfoReportInfo: ' For Smart One C units only. Tag, Unit Label, Unit SN, Notes, Engine Hours Total, Engine Running Time (hrs), Current Address, # of Days at Current Address, Previous Address, Last Event Time',
    reportsMainRT_temperatureReport: 'Temperature Report',
    reportsMainRT_temperatureReportInfo: 'Organized by Unit, shows historical temperature data for the date range selected',
    reportsMainRT_timecardv2: 'Timecard Report',
    reportsMainRT_detailedReport: 'Detailed Report',
    reportsMainRT_detailed: 'Detailed',
    reportsMainRT_detailedReportInfo: 'List of the individual readings from the device for the date range and time span specified. Each entry shows Time & Date, Driver, Address, Speed, Posted Speed, Direction, Event, Signal (strength) and Odometer.',
    reportsMainRT_driverSafetyReport: 'Driver Safety Report',
    reportsMainRT_driverSafetyReportInfo: 'Lists Hard Turn, Hard Brake, Rapid Acceleration. Each entry includes Time & Date, Driver, Address, Speed, Posted Speed, Direction, Event and Signal.',
    reportsMainRT_exclude: 'Exclude',
    reportsMainRT_excludeTables: 'Exclude tables with no data',
    reportsMainRT_idleMinutesFilter: "Idle Minutes Filter",
    reportsMainRT_idleReport: 'Idle Report',
    reportsMainRT_idleReportInfo: 'Shows Idle events. Use the Idle Minutes Filter to show idle events lasting over a specified amount of time.',
    reportsMainRT_ignitionReport: 'Ignition Report',
    reportsMainRT_ignitionReportInfo: 'Shows each Ignition On and Ignition Off events. Includes Dates, times, Addresses, and duration of the event, along with a daily total of Ignition On and Ignition Off time',
    reportsMainRT_itemsError: "Please select at least one option",
    reportsMainRT_landmarkReport: 'Landmark Report',
    reportsMainRT_landmarkReportInfo: 'Show the Landmarks visited by each vehicle, including Entry and Exit times, Distance traveled within and between Landmarks, the Duration within and between Landmarks',
    reportsMainRT_mileageReport: 'Mileage Report',
    reportsMainRT_mileageReportInfo: 'Details how mileage increased with each reading, shows both the mileage accumulated between readings as well as the total distance increases and the mileage accumulated for each day.',
    reportsMainRT_mileageSummaryReport: 'Mileage Summary Report',
    reportsMainRT_driverMileageSummaryReport: 'Driver Mileage Summary Report',
    reportsMainRT_mileageSummaryReportInfo: 'Total mileage accumulated each day.',
    reportsMainRT_driverMileageSummaryReportInfo: 'Total mileage accumulated by drivers.',
    reportsMainRT_minutesFilter: "Minutes Filter",
    reportsMainRT_noDataMessage: 'No data found. Please refine your search and try again',
    reportsMainRT_loadingInitial: 'Loading report',
    reportsMainRT_reportSetup: 'Report Setup',
    reportsMainRT_reportType: 'Choose a Report Type',
    reportsMainRT_viewReportBy: 'View Report By',
    reportsMainRT_runReportFor: 'Run Report For',
    reportsMainRT_actReport: 'Activity w/ Hour Meter Report',
    reportsMainRT_hourOfUseReport: 'Hour Of Use Report',
    reportsMainRT_driverFormsReportV2: 'Driver Forms Report',
    reportsMainRT_driverStatusReportV2: 'Driver Status Report',
    reportsMainRT_simpleReport: 'Simple Report',
    reportsMainRT_speedFilter: "Only show speeds ≥ :",
    reportsMainRT_speedLimit: "Only show speeds ≥ posted by:",
    reportsMainRT_actReportInfo: 'Engine Hours broken down by Idle versus Move, Shows Duration for event along with Location and Start Date/Time, provides running hour meter showing the increase of hours corresponding to engine usage.',
    reportsMainRT_hourOfUseReportInfo: 'Provides the First On, Last Off, Machine Hours, Working Hours, Weekend Hours, Billing Hours and Overtime Hours.',
    reportsMainRT_driverFormsReportV2Info: 'Completed forms submitted by Drivers via the Driver App. Only option to view Form responses.',
    reportsMainRT_driverStatusReportV2Info: 'Completed forms submitted by Drivers via the Driver App. Only option to view Form responses.',
    reportsMainRT_simpleReportInfo: 'List of the individual readings from the device for the date range and time span specified. Each entry showing Time & Date, Address, Distance, Speed, Posted Speed and Event.',
    reportsMainRT_speedFilterInfo: "Details the dates, locations, and times when a specified speed was violated and/or when the posted speed limit is violated by a specified amount. Set filters to determine which speeding events are displayed.",
    reportsMainRT_startStopIdleReportInfo: 'Organizes readings from engine on to engine off. Shows total Engine On, Engine Off, Idle and Travel Times for the date range selected. Also shows Total Distance, Max Speed and Average Speed. In addition to the overall totals, each event is detailed by the information above as well.',
    reportsMainRT_speedReport: 'Speed Report',
    reportsMainRT_speedReportInfo: 'Details the dates, locations, and times when a specified speed was violated and/or when the posted speed limit is violated by a specified amount. Set filters to determine which speeding events are displayed.',
    reportsMainRT_utilization: 'Utilization Report',
    reportsMainRT_utilizationInfo: 'For each device selected shows the total distance traveled, engine run time, idle time, stopped time, percentage of time moving, days worked, total days in range selected, and percentage of days worked',
    reportsMainRT_eventReport: 'Event Report',
    reportsMainRT_eventReportInfo: 'Provides a list of specific Events for the Date Range and Devices/Drivers selected. Use the Event Filter to show only selected events. The image below displays the event filters.',
    reportsMainRT_formReport: 'Form Report',
    reportsMainRT_pingReport: 'Ping Report',
    reportsMainRT_pingReportInfo: 'Manual request of unit position (asset tracker) using the Locates features on the Status tab, report shows manual requests sent.',
    reportsMainRT_odometerReport: 'Odometer Report',
    reportsMainRT_odometerReportInfo: 'Give the current odometer reading for the vehicle, organized by group.',
    reportsMainRT_landmarkEngineHoursReport: 'Landmark Engine Hours Report',
    reportsMainRT_landmarkEngineHoursReportInfo: 'Summarizes how many times a vehicle visited particular Landmarks, including Total Visit Duration and Engine Hours, organized by unit.',
    reportsMainRT_NonReportingReport: 'Non Reporting Report',
    reportsMainRT_NonReportingReportInfo: 'Lists vehicles that haven’t reported within the chosen date range.',
    reportsMainRT_movingReport: 'Moving Report',
    reportsMainRT_stateMileageSummariesReport: 'State Mileage Summaries Report',
    reportsMainRT_movingReportInfo: 'Shows data for each Travel Start to Travel Stop. Shows address of origin and destination, start and stop times, total travel time. Broken down by day. Can be filtered to eliminate events below a certain duration',
    reportsMainRT_stateMileageSummariesReportInfo: 'State Mileage Summaries Report.',
    reportsMainRT_driverTimesheetReport: 'Driver Timesheet Report',
    reportsMainRT_driverTimesheetReportInfo: 'Breaks down by Driver to show Vehicles assigned and assignment history. Must utilize the Driver tool.',
    reportsMainRT_geofenceReport: 'Geofence Report',
    reportsMainRT_geofenceReportInfo: 'Provides a list of Geofence Events for each vehicle, including the Event, Geofence Name, Address, Tags, Time & Date, Time and Distance.',
    reportsMainRT_UnitInfoReport: 'Unit Info Report',
    reportsMainRT_UnitInfoReportInfo: 'Organized by tag, provides unit type, unit serial number, Garmin serial number (if applicable), unit tag, time zone, driver, odometer, engine hours, equipment, VIN, last event, and time of last event.',
    reportsMainRT_locationReport: 'Location Report',
    reportsMainRT_locationReportInfo: 'History for changing Vehicle Location assignments.',
    reportsMainRT_jbDiagnostics: 'JB Diagnostics Report',
    reportsMainRT_jbDiagnosticsInfo: 'Shows specific Diagnostic readings for: Accel Pedal Position, Battery Voltage, Brake Pedal Position, Eng Coolant Pressure, Eng Coolant Temp, Eng Fuel Rate, Eng Oil Level, Eng Oil Pressure, Eng Oil Temp, Fuel Level 1, Fuel Level 2, Odometer, RPM and Seat Belt Switch. Readings broken down by Vehicle and reported every 10 minutes.',
    reportsMainRT_jbdtcReport: 'JB DTC Report',
    reportsMainRT_jbdtcReportInfo: 'List of DTC codes detected via a JBUS device',
    reportsMainRT_legacyChatHistory: 'Legacy Chat History Report',
    reportsMainRT_chatHistory: 'Chat History Report',
    reportsMainRT_legacyChatHistoryInfo: 'Legacy Chat history between Driver and Dispatch. Organized by Driver.',
    reportsMainRT_chatHistoryInfo: 'Chat history between Driver and Dispatch. Organized by Driver.',
    reportsMainRT_chatToFrom: 'Chat To/From',
    reportsMainRT_message: 'Message',
    reportsMainRT_stopTitle: 'Stop Title',
    reportsMainRT_stopStatus: 'Stop Status',
    reportsMainRT_inOut: 'In/Out',
    reportsMainRT_driverStatus: 'Driver Status',
    reportsMainRT_driverStatusReport: 'Legacy Driver Status Report',
    reportsMainRT_stopHistory: 'Stop History Report',
    reportsMainRT_stopHistory_legacy: 'Legacy Stop History Report',
    reportsMainRT_stopHistoryInfo: 'Date/Time, Address, Stop Title, Current Status and Trip Duration',
    reportsMainRT_fsmTimeCardReport: 'Legacy Timecard Report',
    reportsMainRT_fsmTimeCardReportInfo: 'Time Clock punches from Driver via FSM app, organized by date, includes location of phone/tablet used, total time',
    reportsMainRT_fsmTimeCardReportInfoV2: 'Time Clock punches from Driver via driver app, organized by date, includes location of phone/tablet used, total time',
    reportsMainRT_milesPerGallonReport: 'Miles Per Gallon Report',
    reportsMainRT_behaviorReport: 'Behavior Report',
    reportsMainRT_milesPerGallonReportInfo: 'Miles Per Gallon Report',
    reportsMainRT_behaviorReportInfo: 'Behavior Report',
    reportsMainRT_vehicleMaintenanceItemsReport: 'Vehicle Maintenance Items Report',
    reportsMainRT_vehicleMaintenanceItemsReportLegacy: 'Legacy Vehicle Maintenance Items Report',
    reportsMainRT_vehicleMaintenanceItemsReportInfo: 'Details Services completed for vehicles within the requested date range including the Time & Date, Service Title, Amount of Mileage, Days, Hours, Total Cost, and any notes entered when the service was completed.',
    reportsMainRT_fsmCombinedReport: 'Legacy Combined Report',
    reportsMainRT_fsmCombinedReportV2: 'Combined Report',
    reportsMainRT_fsmCombinedReportInfo: 'Driver Status changes, time clock punches, chat history, stops, current stop statuses',
    reportsMainRT_eventFilter: "Event Filter",
    reportsMainRT_serviceItemFilter: "Service Item Filter",
    reportsMainRT_formFilter: "Form Filter",
    reportsMainRT_landmarkView: "Landmark View",
    reportsMainRT_geofenceView: "Geofence View",
    reportsMainRT_timesheetReport: "Timesheet Report",
    reportsMainRT_timesheetReportInfo: "Provides the date, time, and address for when the engine first turned on for the day (First Ignition On Time) as well as the date, time, and address for when the ignition last turned off for the day (Last Ignition Off). It also provides the total time elapsed between the first engine on and the last engine off events and total distance traveled each day.",
    reportsMainRT_landmarkBasedReport: "Landmark Based Report",
    reportsMainRT_landmarkBasedReportInfo: "Shows which Units Entered and Exited each Landmark, the Duration within the Landmark and Time within the Landmark.",
    reportsMainRT_startStopIdleReport: 'Start Stop Idle Report',
    reportsMainRT_stateMileageReport: 'State Mileage Report',
    reportsMainRT_stateMileageReportInfo: 'Shows the miles accumulated per state per vehicle over date range selected, including number of state border crossings and odometer readings. Can be used to assist with IFTA reports.',
    reportsMainRT_stateMileageCrossingReport: 'State Mileage Crossing Report',
    reportsMainRT_stateMileageCrossingInfo: 'Shows the miles traveled per state per vehicle during the selected date range, including the number of state border crossings and odometer readings. Can be used to assist with IFTA reports.',
    reportsMainRT_errorDate: "The start date must be less than the end date",
    reportsMainRT_stopReport: 'Stop Report',
    reportsMainRT_stopReportInfo: 'Lists the Stops for each day with Address, Time & Date and Duration. Indicates time Idle versus time Engine Off. Use the Stop filter to only show stops lasting over a number of minutes.',
    reportsMainRT_stopSummaryReport: 'Stop Summary Report',
    reportsMainRT_stopSummaryReport_legacy: 'Legacy Stop Summary Report',
    reportsMainRT_stopSummaryReportInfo: 'Organized by Driver, Date and Stop: Total Stop Duration, Time & Date, Address, Status and Duration',
    reportsMainRT_vehServiceItemsReport: 'Vehicle Service Items Report',
    reportsMainRT_vehServiceItemsReportLegacy: 'Legacy Vehicle Service Items Report',
    reportsMainRT_vehServiceItemsReportInfo: 'Shows a breakdown by Service Item for all services completed that included that item. Shows the total cost for all services that included that maintenance item. Displays the Service Name, Time & Date, Cost, and Service Notes',
    reportsMainRT_driverFormsReport: 'Legacy Driver Forms Report',
    reportsMainRT_driverFormsReportInfo: 'Completed forms submitted by Drivers via the FSM App. Only option to view Form responses.',
    reportsMainRT_summaryReport: 'Summary Report',
    reportsMainRT_summaryReportInfo: 'Provides a sum of Engine On, Engine Off, Travel Time, Distance, Idle Time, Max Speed and Average Speed for the date range selected.',
    reportsMainRT_timeFrame: 'Timeframe',
    reportsMainRT_timeFrameLabel: 'Select',
    reportsMainRT_selectTimeFrame: 'Select a Timeframe',
    reportsMainRT_selectTimeFrameRange: 'Select a Date or Range',
    reportsMainRT_unitDriverSelection: 'Unit/Driver Selection',
    reportsMainRT_driverSelection: 'Driver Selection',
    reportsMainRT_event: 'Event',
    reportsMainRT_more: 'More',
    reportsMainRT_noTripsAvailable: 'No trips available',
    reportsMainRT_exportReportError: 'Sorry, a problem occurred during the report generation.',
    reportsMainRT_exportReportSuccess: 'Success',
    reportsMainRT_exportReportTitleStarted: 'Success',
    reportsMainRT_exportReportMessageStarted: 'The report is being generated',
    reportsMainRT_exportReportTitleSuccess: 'Success',
    reportsMainRT_exportReportTitleFailed: 'Error',
    reportsMainRT_exportReportMessageSuccess: 'The report has been generated successfully.',
    reportsMainRT_exportReportMessageFailed: 'An unexpected error occurred.',
    reportsMainRT_exportReportMessageFailedNoData: 'There is no file to download because no data was found.',
    reportsMainRT_reportGeneratedTitle: 'The report is ready',
    reportsMainRT_reportGenerated: 'The report',
    reportsMainRT_between: 'between',
    reportsMainRT_and: 'and',
    reportsMainRT_reportGeneratedConfirm: 'has been generated correctly, do you want to see it now?.',
    reportsMainRT_pinReport: 'Pin Report',
    reportsMainRT_unPinReport: 'Unpin Report',
    reportsMainRT_getReport: 'Get Report',
    reportsMainRT_updatedReport: 'The report has been updated correctly',
    reportsMainRT_addBeen: 'Favorites list has been updated successfully',
    reportsMainRT_clearAll: 'Clear All',
    reportsMainRT_updatedReportFail: 'Error updating report',
    reportsMainRT_notAvailable: 'Not Available',
    reportsMainRT_filesReportsGenerated: 'The report has been generated, select the files you want to download',
    reportsModalDownloadReports: 'Download Reports',
    reportsMainRT_notData: 'no data',
    reportsMainRT_latitudeLongitudeReport: 'Lat/Long Report',
    reportsMainRT_latitudeLongitudeReportInfo: 'Provides the Latitude and Longitude for each reading along with the Event, Time, Date and Address.',
    reportsMainRT_geofenceBasedReport: 'Geofence Based Report',
    reportsMainRT_geofenceBasedReportInfo: 'Details units that entered/exited Geofence, total duration and distance within Geofence.',
    reportsMainRT_diagnosticParametersReport: 'Diagnostic Parameters',
    reportsMainRT_diagnosticParametersReportInfo: 'Current feedback from diagnostic sensors on the vehicles, ie current battery voltage. Only available for certain plug-in units.',
    reportsMainRT_totalInGeofenceCount: 'Total In Geofence Count',
    generatedReport_timeInGeofence: 'Time In Geofence',
    generatedReport_distanceInGeofence: 'Distance In Geofence',
    reportsMainRT_latitude: 'Latitude',
    reportsMainRT_longitude: 'Longitude',
    reportsMainRT_singleLandmark: "Single Landmarks",
    reportsMainRT_tagLandmarkView: "Tag Landmarks",
    reportsMainRT_allLandmarkView: "All Landmarks",
    reportsMainRT_singleGeofence: "Single Geofence",
    reportsMainRT_tagGeofenceView: "Tag Geofences",
    reportsMainRT_allGeofenceView: "All Geofences",
    reportsMainRT_singleForm: "Single Form",
    reportsMainRT_allFormView: "All Forms",
    reportsMainRT_landmarkSummaryReport: "Landmark Summary Report",
    reportsMainRT_landmarkSummaryReportInfo: "Summarizes how many times a vehicle visited particular Landmarks, including Total Visit Duration and Engine Hours.",
    reportsMainRT_landmarkCrewReport: "Landmark Crew Report",
    reportsMainRT_landmarkCrewReportInfo: "Details any Landmarks visited by assigned Crews. Shows Time & Date, Landmark, Driver, Crew Member, Crew Total time in Landmark and Vehicle Total Time in Landmark. Organized by Unit.",
    reportsMainRT_DateChangedLocation: 'Date Changed',
    reportsMainRT_Location: 'Location',
    reportsMainRT_Address: 'Address',
    reportsMainRT_Form: 'Form',
    reportsMainRT_Question: 'Question',
    reportsMainRT_Answer: 'Answer',
    reportsMainRT_Time: 'Time',
    generatedReport_unknownDateChangedLocation: 'Unknown Date Changed Location',
    generatedReport_eventName: 'Event',
    generatedReport_batteryVoltage: 'Battery Voltage (V)',
    generatedReport_coolantTemp: 'Coolant Temp (F)',
    generatedReport_engineSpeed: 'Engine RPM',
    generatedReport_fuelPercent: 'Fuel Level (%)',
    generatedReport_fuelRemain: 'Fuel Level Remaining (gal)',
    generatedReport_fuelRate: 'Fuel Rate (gal/hr)',
    generatedReport_throttlePosition: 'Throttle Position (%)',
    generatedReport_tripFuel: 'Calculated Fuel Usage (gal)',
    generatedReport_tripOdometer: 'Trip Odometer',
    generatedReport_vehicleSpeed: 'Vehicle Speed',
    generatedReport_zone1: 'Zone 1',
    generatedReport_zone2: 'Zone 2',
    generatedReport_zone3: 'Zone 3',
    reportsMainRT_DiagnosticIndicatorsReport: 'Diagnostic Indicators Report',
    reportsMainRT_DiagnosticIndicatorsReportInfo: 'List of potential warning lights that *could* happen to vehicle (low tire pressure, check engine, etc…). Only available for certain plug-in units.',
    reportsMainRT_JBSummaryReport: 'JB Summary Report',
    reportsMainRT_JBSummaryReportInfo: 'Broken down by Vehicle, shows Eng Average Fuel Eco (mpg), Eng Total Fuel Used (gal), Eng Total Hours, Eng Total Idle Fuel (gal), Eng Total Idle Hours, Hi-Rez Total Fuel (gal), Total PTO Hours, Total Vehicle Hours.',
    reportsMainRT_EngineHoursReport: 'Engine Hours Report',
    reportsMainRT_EngineHoursReportInfo: 'For Smart One C Units only. Organized by Unit, provides Total Days, Total Hours and Average Hours for the Range selected. Time & Date, Location and Hours.',
    reportsMainRT_smartOneCUtilizationReport: 'Smart One C Utilization Report',
    reportsMainRT_smartOneCUtilizationReportInfo: 'For Smart One C units only. Organized by unit. Label, Tags, Engine Running Time, Engine Running Time (hours), Engine Running Time (%), Engine Off Time (Hours), Engine Off (%)',
    reportsMainRT_TimeDate: 'Time & Date',
    reportsMainRT_EventName: 'Event',
    reportsMainRT_absActive: 'ABS Active',
    reportsMainRT_absIndicator: 'ABS Indicator',
    reportsMainRT_airBagIndicator: 'AirBag Indicator',
    reportsMainRT_brakeIndicator: 'Brake Indicator',
    reportsMainRT_brakeSwichStatus: 'Brake Swich Status',
    reportsMainRT_coolantHotLight: 'Coolant Hot Light',
    reportsMainRT_cruiseControl: 'Cruise Control',
    reportsMainRT_dtc: 'DTC',
    reportsMainRT_ignitionStatus: 'Ignition Status',
    reportsMainRT_milStatus: 'MIL Status',
    reportsMainRT_oilPressure: 'Oil Pressure',
    reportsMainRT_pto: 'PTO Status',
    reportsMainRT_seatBelt: 'SeatBelt Fastened',
    reportsMainRT_engineAverageFuelEconomy: 'Eng Average Fuel Eco (mpg)',
    reportsMainRT_engineTotalFuelUsed: 'Eng Total Fuel Used (gal)',
    reportsMainRT_engineTotalHours: 'Eng Total Hours',
    reportsMainRT_engineTotalIdleFuel: 'Eng Total Idle Fuel (gal)',
    reportsMainRT_engineTotalIdleHours: 'Eng Total Idle Hours',
    reportsMainRT_hirezTotalFuel: 'Hi-Rez Total Fuel (gal)',
    reportsMainRT_totalPtoHours: 'Total PTO Hours',
    reportsMainRT_totalVehicleHours: 'Total Vehicle Hours',
    reportsMainRT_hours: 'Hours',
    reportsMainRT_hoursMin: 'hours',
    reportsMainRT_engineHoursReportCaution: 'This report is only available for T1000 and T5000 devices',
    reportsMainRT_smartOneCUtilizationReportCaution: 'This report is only available for T1000 and Smart One C devices',
    reportsMainRT_totalDays: 'Total Days',
    reportsMainRT_totalHours: 'Total Hours',
    reportsMainRT_averageHours: 'Average Hours',
    reportsMainRT_formStopSummaryReport: 'Legacy Form Stop Summary Report',
    reportsMainRT_formStopSummaryReportV2: 'Form Stop Summary Report ',
    reportsMainRT_formStopSummaryReportInfo: 'Date/time, Vehicle Event, Address, Duration, Form Questions and Answers',
    reportsMainRT_basic: 'Basic',
    reportsMainRT_diagnostics: 'Diagnostics',
    reportsMainRT_fsm: 'FSM',
    reportsMainRT_legacyMaintenance: 'Legacy Maintenance',
    reportsMainRT_maintenance: 'Maintenance',
    reportsMainRT_others: 'Others',
    reportsMainRT_label: 'Label',
    reportsMainRT_unitGroups: 'Unit Groups',
    reportsMainRT_tags: "Tags",
    reportsMainRT_engineRunningTime: 'Engine Running Time (hours)',
    reportsMainRT_engineRunningTimePerc: 'Engine Running Time (%)',
    reportsMainRT_engineOffTime: 'Engine Off Time (hours)',
    reportsMainRT_engineOffTimePerc: 'Engine Off Time (%)',
    reportsMainRT_fuelCardReport: 'Fuel Card Report',
    reportsMainRT_fuelCardReportInfo: 'Fuel Card Report',
    reportsMainRT_card: 'Card',
    reportsMainRT_reportOptions: 'Report Options',
    reportsMainRT_fuel: 'Fuel',
    generatedReport_mpg: 'MPG',
    generatedReport_fleet: 'Fleet',
    generatedReport_transactionId: 'Trans. ID',
    generatedReport_posted: 'Posted',
    generatedReport_quantity: 'Quantity',
    generatedReport_unitAddress: 'Unit Address',
    generatedReport_price: 'Cost',
    reportsMainRT_serviceTitle: 'Title',
    reportsMainRT_distance: 'Distance',
    reportsMainRT_days: 'Days',
    generatedReport_notes: 'Notes',
    reportsMainRT_inputReport: 'Input Report',
    reportsMainRT_inputReportInfo: 'History of Input feedback. When and where Inputs were engaged, which Inputs were engaged, total number of times engaged, and total time engaged.',
    reportsMainRT_noDataEvent: 'No data available for the selected time period.',
    reportsMainRT_enterValue: 'Enter Value',
    reportsMainRT_getNewReport: 'Get New Report',
    reportsMainRT_editReport: 'Edit Report',
    reportsAdvanceFilterOptions: 'Advanced Filter Options',
    reportsAdvanceTimeRanges1: 'Apply',
    reportsAdvanceTimeRanges2: '\'s schedules to all days',


    reportReportType_seedOr: `Speed = or > `,
    reportReportType_overPosted: `Over posted > `,
    reportReportType_minutes: `Minutes = `,
    reportReportType_eventFilter: `Event Filter = `,
    reportReportType_serviceItemFilter: `Service Item Filter = `,
    reportReportType_landmarkView: `Landmark View = `,
    reportReportType_landmark: `Landmark = `,
    reportReportType_tags: `Tags = `,
    reportReportType_geofenceView: `Geofence View = `,
    reportReportType_geofence: `Geofence = `,

    reportReportType_DeletePinned: 'Are you sure you want to delete this pinned?',


    generatedReport_event_1: 'Ignition On',
    generatedReport_event_2: 'Ignition Off',
    generatedReport_event_3: 'Travel Stop',
    generatedReport_event_4: 'Travel Start',
    generatedReport_event_5: 'Ping',
    generatedReport_event_6: 'Idle',
    generatedReport_event_7: 'Main Power Off',
    generatedReport_event_8: 'Drive',
    generatedReport_event_9: 'Hard Turn',
    generatedReport_event_10: 'Hard Brake',
    generatedReport_event_11: 'Stop',
    generatedReport_event_12: 'Rapid Acceleration',
    generatedReport_event_13: 'Main Power Disconnect',
    generatedReport_event_14: 'IDLE',
    generatedReport_event_15: 'MOVE',
    generatedReport_event_16: 'Main Power On',
    sec: 'sec',
    daysTime: 'days',
    Idle: 'idle',
    generatedReport_Mon: 'Mon',
    generatedReport_Tue: 'Tue',
    generatedReport_Wed: 'Wed',
    generatedReport_Thu: 'Thu',
    generatedReport_Fri: 'Fri',
    generatedReport_Sat: 'Sat',
    generatedReport_Sun: 'Sun',
    generatedReport_input: 'Input',
    generatedReport_count: 'Count',
    generatedReport_NoActivity: 'No Activity',
    generatedReport_totalMachine: 'Total Machine Hours',
    generatedReport_totalWorkHours: 'Total Work Hours',
    generatedReport_totalBilling: 'Total Billing Hours',
    generatedReport_totalOvertime: 'Total Overtime Hours',
    stop_history_report_v2_InRoute: "In Route",
    stop_history_report_v2_Pending: "Pending",
    stop_history_report_v2_Done: "Done",
    stop_history_report_v2_OnSite: "OnSite",
    total_distance_traveled: 'Total distance traveled',
    speed_measurement_miles: 'mi',
    speed_measurement_kilometers: 'km',
    current_as_of: 'Current as of: '
};
