const locales = {
    dashboardPanel_title: "DASHBOARD",
    dashboardPanel_mutiple_title: "DASHBOARDS",
    dashboardPanel_preview: "Preview",
    dashboardPanel_add_gadget: "Add gadget",
    dashboardPanel_edit_gadget: "Edit gadget ",
    dashboardPanel_edit_info: "Right now, you are editing your dashboard. The changes will be saved automatically.",
    dashboardPanel_edit_frequency: "Frequency Refresh",
    dashboardPanel_edit_title: "Title",
    dashboardPanel_edit_minutes: "Minutes",
    dashboardPanel_edit_hours: "Hour",
    dashboardPanel_edit_days: "Days",
    dashboardPanel_edit_report_type: "Report Type",
    dashboardPanel_edit_chart_type: "Chart Type",
    dashboardPanel_edit_groups_unit: "Tags/Units",
    dashboardPanel_edit_time_metric: "Time Metric",
    dashboardPanel_edit_IdleTime:'Idle Time',
    dashboardPanel_edit_IdleStop:'Idle Stop',
    dashboardPanel_edit_DriveTime:'Driver time',
    dashboardPanel_edit_DriverStop:'Stop Time',
    dashboardPanel_edit_Speeding:'Speeding',
    dashboardPanel_edit_Mileage:'Mileage',
    dashboardPanel_edit_EngineTime:'Engine Time',
    dashboardPanel_edit_DriverOverview:'Driving Overview',
    dashboardPanel_edit_DriverSafety:'Driver Safety',
    dashboardPanel_edit_allUnits:'All Units',
    dashboardPanel_edit_singleGroup:'Single Tag',
    dashboardPanel_edit_compareGroup:'Compare Tags',
    dashboardPanel_edit_month:'Month',
    dashboardPanel_edit_week:'Week',
    dashboardPanel_edit_quarter:'Quarter',
    dashboardPanel_edit_chart_pie:'Pie',
    dashboardPanel_edit_chart_bar:'Column',
    dashboardPanel_edit_device_offline:'Offline Devices',
    dashboardPanel_edit_chart:'Charts',
    dashboardPanel_edit_fleet:"Device Category",
    dashboardPanel_offline_thresholdMinutes:"Time threshold",
    dashboardPanel_edit_group: "Group",
    dashboardPanel_edit_tag: "Tag",
    dashboardPanel_edit_title_offline_device:'Offline Devices',
    dashboardPanel_edit_description_offline_device:'Displays a filterable list of devices that are Offline',
    dashboardPanel_edit_title_chart:'Chart',
    dashboardPanel_edit_description_chart:'Customizable pie or column charts for summarizing report data for selected units',
    dashboardPanel_edit_title_recent_alerts:'Recent Alerts',
    dashboardPanel_edit_description_recent_alerts:'Displays filtereable list of recently triggered alerts.',
    dashboardPanel_edit_view:'View',
    dashboardPanel_edit_unit_count:'Unit Count',
    dashboardPanel_edit_percentage:'As Percentage',
    dashboardPanel_edit_highest:'Highest Value',
    dashboardPanel_edit_lowest:'Lowest Value',
    dashboardPanel_edit_average:'Show Average',
    dashboardPanel_edit_confirmation_message_create_dashboard_title: "Create Dashboard",
    dashboardPanel_edit_confirmation_message_rename_dashboard_title: "Rename Dashboard",
    dashboardPanel_edit_confirmation_message_delete_dashboard_title: "Delete Dashboard",
    dashboardPanel_edit_confirmation_message_save_dashboard_title: "Edit Dashboard",
    dashboardPanel_edit_confirmation_message_create_dashboard_description: "The dashboard has been created successfully",
    dashboardPanel_edit_confirmation_message_rename_dashboard_description: "The dashboard has been renamed successfully",
    dashboardPanel_edit_confirmation_message_delete_dashboard_description: "The dashboard has been deleted successfully",
    dashboardPanel_edit_confirmation_message_save_dashboard_description: "Changes saved successfully",
    dashboardPanel_name: "Dashboard Name",
    dashboardPanel_rename: "Rename Dashboard",
    dashboardPanel_delete: "Delete Dashboard",
    dashboardPanel_title_new: "New Dashboard",
    dashboardPanel_title_edit: "Edit Dashboard",
    dashboardPanel_chart_label_Mon: "Mon",
    dashboardPanel_chart_label_Tue: "Tue",
    dashboardPanel_chart_label_Wed: "Wed",
    dashboardPanel_chart_label_Thu: "Thu",
    dashboardPanel_chart_label_Fri: "Fri",
    dashboardPanel_chart_label_Sat: "Sat",
    dashboardPanel_chart_label_Sun: "Sun",    
    dashboardPanel_chart_label_Jan: "Jan",    
    dashboardPanel_chart_label_Feb: "Feb",  
    dashboardPanel_chart_label_Mar: "Mar",
    dashboardPanel_chart_label_Apr: "Apr",
    dashboardPanel_chart_label_May: "May",
    dashboardPanel_chart_label_Jun: "Jun",
    dashboardPanel_chart_label_Jul: "Jul",
    dashboardPanel_chart_label_Aug: "Aug",
    dashboardPanel_chart_label_Sep: "Sep",
    dashboardPanel_chart_label_Oct: "Oct",
    dashboardPanel_chart_label_Nov: "Nov",
    dashboardPanel_chart_label_Dec: "Dec",
    dashboardPanel_chart_label_Week: "Week",
    dashboardPanel_date: "Date",
    dashboardPanel_nameRegister: "Name",
    dashboardPanel_events: "Events",
    dashboardPanel_to: "To",
    dashboardPanel_Week: "W",
    dashboardPanel_Average: "Average",
    dashboardPanel_delete_title: "Are you sure you want to remove this gadget?",
    dashboardPanel_delete_dashboard: "Are you sure you want to remove this Dashboard?",
    dashboardPanel_edit_show_labels:'Show unit labels',
    dashboardPanel_gadget_name: "Gadget Name",
    dashboardPanel_gadget_name_instruction: "Gadget Name",
    dashboardPanel_recent_alert_type: "Type/Label",
    dashboardPanel_recent_alert_unit: "Unit",
    dashboardPanel_recent_alert_time: "Time",
    dashboardPanel_recent_alerts_time_frame: "Timeframe",
    dashboardPanel_recent_alerts_type_label: "Select an Alert Type",
    dashboardPanel_recent_alerts_time_frame_today: "Today",
    dashboardPanel_recent_alerts_time_frame_yesterday: "Yesterday",
    dashboardPanel_recent_alerts_time_frame_last_7_days: "Last 7 days",
    dashboardPanel_recent_alerts_time_frame_last_30_days: "Last 30 days",
    dashboardPanel_recent_alerts_time_frame_custom: "Custom",
    dashboardPanel_recent_alerts_type: "Alert Type",
    dashboardPanel_recent_all_alerts_type: "- All Alerts Type -",
    dashboardPanel_recent_alerts_driver: "Driver(s)",
    dashboardPanel_recent_alerts_unit: "Unit(s)",
    dashboardPanel_recent_alerts_view_by: "View Recents Alerts By",
    dashboardPanel_recent_alerts_run_for: "Run Recents Alerts For",
    dashboardPanel_pagination_button_next: "next",
    dashboardPanel_pagination_button_previous: "prev",
    dashboardPanel_user_gadget_save: "Changes saved successfully",
    dashboardPanel_user_gadget_delete: "The record was successfully deleted",
    dashboardPanel_user_gadget_edit_title: "Edit gadget",
    dashboardPanel_user_gadget_create_title: "Create gadget",
    dashboardPanel_user_gadget_delete_title: "Delete gadget",
};

export default locales;