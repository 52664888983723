export const MapProvider = {
  Google : 1,
  Microsoft : 2,
  OSM : 3,
}

export const TypeObjectMap = {
    Default : 0,
    Marker : 1,
    Polygon : 2,
    Polyline : 3,
    Circle : 4,
    MarkerCluster: 5,
    KmlFile: 6,
    MapClick: 7,
    CreatePointer: 8,
    Routes: 9,
    Label: 10,
    Landmark: 11,
    Cluster: 12,
    Settings: 13,
    Geofence: 14,
    Rectangle: 15,
    Square: 16,
    LayerKmz: 17
}

export const TypeLayer = {
  Default: 0,
  Units: 1,
  VechileTrails: 2,
  MarkerCluster: 3,
  Pointers: 4,
  PolygonCreate: 5,
  CircleCreate: 6,
  //SquareCreate: 19,
  RouteLayer: 7,
  VehicleTrailLayer: 8,
  Landmark: 9,
  KmlGeofence: 10,
  KmlLandmark: 11,
  KmlMarker: 12,
  TemporalObjects: 13,
  Geofence: 14,
  UnitLabel: 15,
  LandmarkMarker: 16,
  UnitSelection: 17,
  MapSearch: 18,
  SquareCreate: 19,
  FSMRoute: 20,
  VechileTrailsTrailHistory: 21,
  VicinityTool: 22,
  EtaLinkMarker: 23
}

export const EnumIconSize = {
  Default: 0,
  Magnified: 1
}

export const EventColorMap = [
  // green - 00ad00
  { eventName: 'Drive', color: '#00ad00', folder: 'green' },
  { eventName: 'Motion', color: '#00ad00', folder: 'green' },
  { eventName: 'Motion Start', color: '#00ad00', folder: 'green' },
  { eventName: 'Travel Start', color: '#00ad00', folder: 'green' },
  { eventName: 'Update', color: '#00ad00', folder: 'green' },
  { eventName: 'Poll B', color: '#00ad00', folder: 'green' },
  { eventName: 'Poll C', color: '#00ad00', folder: 'green' },
  // b65345 - red
  { eventName: 'Ignition Off', color: '#b65345', folder: 'red' },
  { eventName: 'Stop', color: '#b65345', folder: 'red' },
  { eventName: 'Main Power Off', color: '#b65345', folder: 'red'},
  { eventName: 'Parked', color: '#b65345', folder: 'red' },
  // ddaea6 - light red
  { eventName: 'Travel Stop', color: '#ddaea6', folder: 'pink' },
  { eventName: 'Motion Stop', color: '#ddaea6', folder: 'pink' },
  // d7d7d6 - white
  { eventName: 'Main Power On', color: '#d7d7d6', folder: 'gray' },
  { eventName: 'Wakeup' , color: '#d7d7d6', folder: 'gray' },
  // b763bb -  purple
  { eventName: 'Geofence In' , color: '#b763bb', folder: 'purple' },
  { eventName: 'Geofence Out', color: '#b763bb', folder: 'purple' },
  { eventName: 'Hard Acceleration', color: '#b763bb', folder: 'purple' },
  { eventName: 'Rapid Acceleration', color: '#b763bb', folder: 'purple' },
  { eventName: 'Hard Brake', color: '#b763bb', folder: 'purple' },
  { eventName: 'Hard Turn', color: '#b763bb', folder: 'purple' },
  { eventName: 'Sudden Stop', color: '#b763bb', folder: 'purple' },
  { eventName: 'AutoSnapshot', color: '#b763bb', folder: 'purple' },
  // c7be00 - yellow
  { eventName: 'Idle', color: '#c7be00', folder: 'yellow' },
  { eventName: 'IDLING', color: '#c7be00', folder: 'yellow' },
  // 16aaf2 - blue
  { eventName: 'Ignition On', color: '#16aaf2', folder: 'blue' }
];

export const LandmarkCircleRadiusValues = [
  { value : '50', text: '50 FT' },
  { value : '100', text: '100 FT' },
  { value : '150', text: '150 FT' },
  { value : '200', text: '200 FT' },
  { value : '250', text: '250 FT' },
  { value : '300', text: '300 FT' },
  { value : '350', text: '350 FT' },
  { value : '400', text: '400 FT' },
  { value : '450', text: '450 FT' },
  { value : '500', text: '500 FT' },
  { value : '750', text: '750 FT' },
  { value : '1000', text: '1,000 FT' },
  { value : '1250', text: '1,250 FT' },
  { value : '1500', text: '1,500 FT' },
  { value : '1750', text: '1,750 FT' },
  { value : '2000', text: '2,000 FT' },
  { value : '2250', text: '2,250 FT' },
  { value : '2500', text: '2,500 FT' },
  { value : '2750', text: '2,750 FT' },
  { value : '3000', text: '3,000 FT' },
  { value : '3250', text: '3,250 FT' },
  { value : '3500', text: '3,500 FT' },
  { value : '3750', text: '3,750 FT' },
  { value : '4000', text: '4,000 FT' },
  { value : '4250', text: '4,250 FT' },
  { value : '4500', text: '4,500 FT' },
  { value : '4750', text: '4,750 FT' },
  { value : '5280', text: '1 Mile' },
  { value : '10560', text: '2 Miles' },
  { value : '26400', text: '5 Miles' },
  { value : '52800', text: '10 Miles' },
  { value : '79200', text: '15 Miles' },
  { value : '84480', text: '16 Miles' },
  { value : '132000', text: '25 Miles' },
  { value : '264000', text: '50 Miles' },
  { value : '528000', text: '100 Miles' },
  { value : '1056000', text: '200 Miles' },
  //{ value : '2640000', text: '500 Miles' },
  //{ value : '5544880', text: '1,050 Miles' }
]

//Saved in meter (Value)
export const GeofenceRadiusValues = [
  { value : '10', text: '32 FT'},
  { value : '161', text: '1/10 Mile'},
  { value : '402', text: '1/4 Mile'},
  { value : '805', text: '1/2 Mile'},
  { value : '1207', text: '3/4 Mile'},
  { value : '1609', text: '1 Mile'},
  { value : '3219', text: '2 Miles'},
  { value : '4828', text: '3 Miles'},
  { value : '6437', text: '4 Miles'},
  { value : '8047', text: '5 Miles'},
  { value : '16093', text: '10 Miles'},
  { value : '32187', text: '20 Miles'},
  { value : '48280', text: '30 Miles'},
  { value : '64374', text: '40 Miles'},
  { value : '80467', text: '50 Miles'},
  { value : '96561', text: '60 Miles'},
  { value : '160935', text: '100 Miles'}
]

export const ColorsToRoute = [
  { id: 0, color: "#ffff00", name: "yellow", range: 25 },
  { id: 1, color: "#37ff00", name: "green", range: 30 },
  { id: 2, color: "#03c3ff", name: "green", range: 35 },
  { id: 3, color: "#03ffba", name: "blue", range: 40 },
  { id: 4, color: "#0041ff", name: "blue", range: 45 },
  { id: 5, color: "#4700ff", name: "blue", range: 50 },
  { id: 6, color: "#d001ff", name: "magenta", range: 55 },
  { id: 7, color: "#ff00c4", name: "pink", range: 60 },
  { id: 8, color: "#ff0035", name: "red", range: 65 },
  { id: 9, color: "#ffbb00", name: "orange", range: 70 },
  { id: 10, color: "#ff7701", name: "orange", range: 75 },
  { id: 11, color: "#cacfd2", name: "gray", range: 0 }
];

export const ColorsToRoutePointsOnRoutesTracker = [
  { id: 0, color: "#d3d3d3", name: "Pending", messages: "route_status_pending" },
  { id: 1, color: "#08c6e6", name: "InRoute", messages: "route_status_in_route" },
  { id: 2, color: "#ffc402", name: "OnSite", messages: "route_status_on_site" },
  { id: 3, color: "#58d8a3", name: "Done", messages: "route_status_done" },
  { id: 4, color: "#ff991f", name: "DoneWithException", messages: "route_status_done_with_exception" },
];
