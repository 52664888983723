/*********************************************************
 * Imports
 *********************************************************/
//import { ClearAll } from '@mui/icons-material'
import React, { useRef, useState, forwardRef, useImperativeHandle, useCallback, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import pointInPolygon from 'point-in-polygon'
import { TypeObjectMap, TypeLayer, EnumIconSize } from './enums'
import { getGeocoder } from './googleGeocoder';
import Overlay from './google/Overlay';
import MarkerOverlay from './google/MarkerOverlay';
import InfoWindow from './google/InfoWindow';
import MapObject from './mapObject';
import MapRoute from './mapRoute';

import { cutNotes, convertJsonObject } from '../../../core/data/Helpers';
import _, { isArray } from 'lodash';
import { HasPermission } from "Core/security"
import { useSelector } from "react-redux";
import { getVidFleetEvent } from 'Core/data/VideoEvents';

import {
  findColor, convertSecondsToTime, createSquareBoundingboxByMilesDistance, radiusFeetToMeters,
  getEventColor, changeMarker, changePathMarker, clusterSvgString, polygonMarkerPointSvg,
  landmarkSvg, getIconGroupWithAnotherColor, validateLatLng, calcDistanceToPoints, getLatByDistance,
  getLngByDistance, createSquareBoundingboxByRadiusCircle, getRouteColorFromSpeed,
  getRouteCoordinatesFromDirectionService, IconStringMarkerRoutePoint, createIconMarkerForRoute, createIconMarkerForRouteTracker,
  returnOptimizeRoutePoints, validateTrailTimeMins, validateTrailTimeMinsCompare, convertDateToSeconds,
  validateDirectionChange, calculateDistanceTraveled  /* , validatePolygon */
} from '../../Map/utils'
import { IconMarkerVehicleTrailsPoint, IconMarkerRoutesTrackerSmall } from '../../../components/Map/iconsReact'

import { endpoints } from 'Core/defaultValues';
import { Tooltip } from 'Components';
import { MarkerClusterer, SuperClusterAlgorithm } from "@googlemaps/markerclusterer";
import { LogManagerConsole } from 'Core/logManager';
import { getSpeedValues } from './googleRoutes'
import TrafficToggle from '../components/TrafficToggle';
import WeatherToggle from '../components/WeatherToggle';
import moment from 'moment';
//import PolygonPoint from 'Components/Map/components/IconsSvg/PolygonPoint'
//import { ExceptionManager } from 'Core/logManager';
import {
  setListUnits,
  selectModuleMapSettings,
  addPointers
} from "Redux/actions";
/*********************************************************
 * Icons
 *********************************************************/

/*********************************************************
 * Global Variables
 *********************************************************/
const mapObjects = []
//const numDeltas = 60;
const zoomToEnableTrails = 13;
const trailsMinDistance = 100;
const trailsMaxDistance = 800;
let mapItem = null;
let customOverlay = null;
let kmlList = [];
let instance = null
let realStateInfoWindow = false;

let mapClickEvent = null;
let mapClusterEvent = null;
let mapClickRouteEvent = null;
let mapBoundsChangedEvent = null;
let mapClickItems = 0;
let onCallBackZoom = null;
let mapMouseUpEvent = null;
let mapMouseDownEvent = null;
const maxClusterZoom = 18;
const zoomLabelsPositions = 8;
let valueZoomNoOverlap = 0;
let lat1, lat2, lng1, lng2;
let eventMouseOverFirstPoint = null;
let eventMouseOutFirstPoint = null;
let eventMouseOverFirstMarker = null;
let eventMouseOutFirstMarker = null;
let startPointMarkerDragend = null;
let endPointMarkerDragend = null;
let eventInfoWindow = null;
let infoWindowMarker = null;
let durationRoute = 0;
const minimunSizeSquare = 0.0001;
let squareLatMin = 0, squareLngMin = 0;
let isWeatherEnabled = false;
let onEnableWeatherBounds = true;
let weatherLayersOnShow = null;
let weatherPermision = false;

/********************************************************
 * Global var to timer
 ********************************************************/
let timerLastEventCount = null
let timerRefreshWeather = null

/*********************************************************
 * Css files / kml files
 *********************************************************/
import './style.scss'
import { useIntl } from 'react-intl';

/*********************************************************
 * Author:  Walter Paz Londoño
 * Date:    30 Nov 2020
 * Module:  Realtime Maps
 * 
 * Class associated to the google map provider
 ********************************************************/
const GoogleProvider = forwardRef((props, ref) => {

  const { messages } = useIntl()

  //-------------- Ref Elements ----------------
  const divMap = useRef();
  const dispatch = useDispatch()
  instance = ref;

  //-------------- Declare the references ----------------
  //const [mapItem, setMapItem] = useState();
  const [traffic, setTraffic] = useState(null);
  const [aerisWeather, setAerisWeather] = useState(null);
  const [openInfoWindow, setOpenInfoWindow] = useState(false);
  const [infoBubbleContent, setInfoBubbleContent] = useState(null);
  const [currentMarkerModal, setCurrentMarkerModal] = useState(null);
  //Global state
  const user = useSelector((state) => state.securityRedux.user);
  const pathFile = `https://s3.amazonaws.com/${endpoints.REPOSITORY_CODE}`
  weatherPermision = props?.weatherPermision || false

  //-------------- Global Vars ----------------
  useImperativeHandle(ref, () => ({
    initMap: initMap,
    addMapObjects: addMapObjects,
    addMapObject: addMapObject,
    addMapObjectsInstancebyLayer: addMapObjectsInstancebyLayer,
    addMapObjectsWihoutInstance: addMapObjectsWihoutInstance,
    removeMapObject: removeMapObject,
    removeMapObjects: removeMapObjects,
    showMapObject: showMapObject,
    showMapObjectByLayer: showMapObjectByLayer,
    removeAll: removeAll,
    removeAllbyItemObject: removeAllbyItemObject,
    removeItemsByIdAndType: removeItemsByIdAndType,
    showLayer: showLayer,
    removeLayer: removeLayer,
    updateObject: updateObject,
    updateMarkerOfflineDevice: updateMarkerOfflineDevice,
    updateMarkerIconMap: updateMarkerIconMap,
    showMarkerCluster: showMarkerCluster,
    showNormalMarkers: showNormalMarkers,
    setSizeMarkerIcon: setSizeMarkerIcon,
    mapInstance: mapInstance,
    showMarkerModal: showMarkerModal,
    hideMarkerModal: hideMarkerModal,
    getGeocoder: getGeocoder,
    getObject: getObject,
    getObjectsByType: getObjectsByType,
    getObjectsByTypeByLayer: getObjectsByTypeByLayer,
    centerObjectOnMap: centerObjectOnMap,
    updateMapType: updateMapType,
    countObject: countObject,
    addKml: addKml,
    addKmlObject: addKmlObject,
    showVehicleTrails: showVehicleTrails,
    centerPointOnMap: centerPointOnMap,
    showStreetView: showStreetView,
    centerByFitBounds: centerByFitBounds,
    centerVehicles: centerVehicles,
    initMapClick: initMapClick,
    removeMapClick: removeMapClick,
    AddPolygonPoint: AddPolygonPoint,
    getPolygonCoordinates: getPolygonCoordinates,
    getCircleCoordinates: getCircleCoordinates,
    reduceMapClickItems: reduceMapClickItems,
    movePolygonPoint: movePolygonPoint,
    removePolygonPoint: removePolygonPoint,
    updateCirclePoint: updateCirclePoint,
    updateSquarePoint: updateSquarePoint,
    setUpdateSquareDiagonally: setUpdateSquareDiagonally,
    updateStyleToCreateObject: updateStyleToCreateObject,
    drawRoute: drawRoute,
    getPositionMarker, getPositionMarker,
    getCurrentZoom: getCurrentZoom,
    setZoom: setZoom,
    setZoomOut: setZoomOut,
    getZoom: getZoom,
    drawLandmark: drawLandmark,
    getOpenInfoWindow: getOpenInfoWindow,
    centerMapAnimated: centerMapAnimated,
    addPolylinePoint: addPolylinePoint,
    addCircleOnMapToCreate: addCircleOnMapToCreate,
    addSquareOnMapToCreate: addSquareOnMapToCreate,
    removeLabelOverlay, removeLabelOverlay,
    addLabelMarkers: addLabelMarkers,
    showOrHideLabelsOnCluster: showOrHideLabelsOnCluster,
    removeMapClusterListener: removeMapClusterListener,
    addMapObjectSettings: addMapObjectSettings,
    setEnableObjectsByGroup: setEnableObjectsByGroup,
    setEnableObjectsByType: setEnableObjectsByType,
    drawPolygon: drawPolygon,
    drawShapeObjectTempOnMap: drawShapeObjectTempOnMap,
    removeMapDraw: removeMapDraw,
    removeRouteListener: removeRouteListener,
    createTemporalLabel: createTemporalLabel,
    onSuscribeZoom: onSuscribeZoom,
    mapItem: mapItem,
    dataUnits: props.dataUnits,
    updateModalPosition: updateModalPosition,
    getDurationRoute: getDurationRoute,
    updateTypeSquareToFreeRectangle: updateTypeSquareToFreeRectangle,
    updateRouteStyles: updateRouteStyles,
    getGeometryEncoded: getGeometryEncoded,
    drawPolygonWithEncodedPath: drawPolygonWithEncodedPath,
    showTraffic: showTraffic,
    hideTraffic: hideTraffic,
    showWeather: showWeather,
    hideWeather: hideWeather,
    addMapClickRouteListener: addMapClickRouteListener,
    removeMapClickRouteListener: removeMapClickRouteListener,
    drawHistoryTrailOnMap: drawHistoryTrailOnMap,
    updateMarkersVisible: updateMarkersVisible,
    reproduceTrailEvents: reproduceTrailEvents,
    focusStopTrailMarkersAnimations, focusStopTrailMarkersAnimations,
    focusStartTrailMarkerAnimations, focusStartTrailMarkerAnimations
  }))

  /*********************************************************
   * Return the instance of the map
   *********************************************************/
  const mapInstance = () => {
    return (mapItem) ? true : false;
  }

  const onSuscribeZoom = (paramFunction) => {
    onCallBackZoom = paramFunction;
  }

  /*********************************************************
   * Add new mapObject into the realtime maps
   *********************************************************/
  const addMapObject = useCallback((mapObject, createInstance = true, evalInstance = false) => {
    switch (mapObject.type) {
      case TypeObjectMap.Marker:
        mapObject.instance = addMarker(mapObject);
        break;

      case TypeObjectMap.Polyline:
        mapObject.instance = addPolyline(mapObject, evalInstance);
        break;
      case TypeObjectMap.Polygon:
        mapObject.instance = addPolygon(mapObject);
        break;

      case TypeObjectMap.Circle:
        mapObject.instance = addCircle(mapObject, true);
        break;

      case TypeObjectMap.Rectangle:
        mapObject.instance = addRectangle(mapObject);
        break;

      case TypeObjectMap.Square:
        mapObject.instance = addSquare(mapObject);
        break;

      case TypeObjectMap.KmlFile:
        mapObject.instance = addKml(mapObject);
        break;

      case TypeObjectMap.Routes:
        mapObject.instance = addRoute();
        break;

      case TypeObjectMap.Label:
        mapObject.instance = addLabel(mapObject);
        break;

      case TypeObjectMap.Landmark:
        mapObject.instance = addImageMapType(mapObject, createInstance);
        if (mapObject.childType == TypeObjectMap.Circle) {
          mapObject.childInstance = addChildLandmarkCircle(mapObject, createInstance)
        }

        if (mapObject.childType == TypeObjectMap.Polygon) {
          mapObject.childInstance = addChildLandmarkPolygon(mapObject, createInstance)
        }
        break;

      case TypeObjectMap.Geofence:
        if (mapObject.childType == TypeObjectMap.Circle) {
          mapObject.instance = addChildLandmarkCircle(mapObject, createInstance)
        }

        if (mapObject.childType == TypeObjectMap.Polygon) {
          mapObject.instance = addChildLandmarkPolygon(mapObject, createInstance)
        }
        break;
    }

    if (mapObject.instance && createInstance) {
      mapObjects.push(mapObject)
      return mapObject //return actual object created
    }
    return null
  })

  /*********************************************************
   * Add new mapObject into the realtime maps
   *********************************************************/
  const addMapObjects = useCallback((elements) => {

    elements.forEach((item) => {
      if (validateNewObject(item) == true) {
        addMapObject(item)
      }
    })
  })

  /*********************************************************
   * Remove the object from the map and array MapObjects
   * @param {int} id of mapObject 
   * @param {TypeObjectMap} type for TypeObjectMap of mapObject 
   *********************************************************/
  const removeMapObject = useCallback((id, type) => {
    for (let i = (mapObjects.length - 1); i >= 0; i--) {
      let mapObject = mapObjects[i];
      if (mapObject.id == id && mapObject.type == type) {
        try {
          mapObject.instance.setMap(null);
          mapObject.instance = null;
          if (mapObject.childInstance) {
            mapObject.childInstance.setMap(null);
            mapObject.childInstance = null;
          }
        }
        catch (err) {
          //console.log("remov:::: " + err.description);
        }
        mapObjects.splice(i, 1);
      }
    }
  })

  /*********************************************************
   * Remove the object from the map and array MapObjects
   *********************************************************/
  const removeMapObjects = useCallback((type) => {
    for (let i = (mapObjects.length - 1); i >= 0; i--) {
      let mapObject = mapObjects[i];
      if (mapObject.type == type) {
        try {
          mapObject.instance.setMap(null);
          mapObject.instance = null;
          if (mapObject.childInstance) {
            mapObject.childInstance.setMap(null);
            mapObject.childInstance = null;
          }
        }
        catch (err) {
          //console.log("remov:::: " + err.description);
        }
        mapObjects.splice(i, 1);
      }
    }
  })

  /*********************************************************
   * Show or Hide the layer elements into the realtime maps
   *********************************************************/
  const showMapObject = useCallback((id, type, show = false) => {
    mapObjects.forEach((item) => {
      if (item.id == id && item.type == type) {
        item.instance.setMap(show ? mapItem : null)
        if (item.hasChild) {
          item.childInstance.setMap(show ? mapItem : null)
        }
      }
    })
  })

  /*********************************************************
   * Show or Hide the layer elements into the realtime maps with object and layer
   *********************************************************/
  const showMapObjectByLayer = useCallback((type, layer, show = false) => {
    mapObjects.forEach((item) => {
      if (item.type == type && layer == item?.layerName) {
        item.instance.setMap(show ? mapItem : null)
        if (item.hasChild) {
          item.childInstance.setMap(show ? mapItem : null)
        }
      }
    })
  })

  /*********************************************************
   * Counst the object from type element
   *********************************************************/
  const countObject = useCallback((type, layer) => {
    const elements = mapObjects.filter((item) => item.type == type && item.layerName == layer)
    return elements ? elements.length : 0
  })

  /*********************************************************
   * Add mapObjects into array wihtout instance
   *********************************************************/
  const addMapObjectsWihoutInstance = useCallback((elements) => {
    elements.forEach((item) => {
      if (validateNewObject(item) == true) {
        mapObjects.push(item)
      }
    })
  })

  /*********************************************************
  * Add Instance to landmarks objects
  *********************************************************/
  const addMapObjectsInstancebyLayer = useCallback((id, layer) => {
    if (!layer) return;
    //get elements to create instance.
    const elementsToInstance = mapObjects.filter(item => item.layerName == layer && !item.instance);

    if (elementsToInstance && elementsToInstance.length > 0) {
      elementsToInstance.forEach((item) => {
        addMapObject(item, false);
      })
    }
    showLandmarksGeofenceByZoom(id, mapItem.getZoom());

  })

  /*********************************************************
   * Update the icon  to the offline devices
   *********************************************************/
  const updateMarkerOfflineDevice = useCallback((id, offlineStatus) => {
    let mapObject = mapObjects.find((item) => {
      if (item.id == id && item.type == TypeObjectMap.Marker) {
        return item;
      }
    })
    if (!mapObject) {
      return
    }

    mapObject.offline = offlineStatus;
    if (offlineStatus) {
      mapObject.offline = offlineStatus;
      const styleOffline = {
        // This marker is 20 pixels wide by 32 pixels high.
        scaledSize: new google.maps.Size(40, 40), // eslint-disable-line no-undef
        // The origin for this image is (0, 0).
        origin: new google.maps.Point(0, 0), // eslint-disable-line no-undef
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new google.maps.Point(20, 20), // eslint-disable-line no-undef
      }

      const iconMarker = getIconAttributes(mapObject.color, 0, '/assets/icons/offlineimage.png', styleOffline)
      mapObject.instance.setIcon(iconMarker)

    } else {
      if (!mapObject.icon) {
        const iconMarker = getIconAttributes(mapObject.color, mapObject.heading, null, null, mapObject)
        mapObject.instance.setIcon(iconMarker)
      }else{
        //load the icon status saved into the vehicle
        const iconMarker = getIconAttributes(mapObject.color, 0, mapObject.icon, false)
        mapObject.instance.setIcon(iconMarker)
      }
    }
  })

  /*********************************************************
   * Change the icon at the marker on map
   *********************************************************/
  const updateMarkerIconMap = useCallback((id, iconPath) => {
    let mapObject = mapObjects.find((item) => {
      if (item.id == id && item.type == TypeObjectMap.Marker) {
        return item;
      }
    })
    if (!mapObject) {
      return
    }

    if (!iconPath) {
      mapObject.icon = ''
      return
    }

    const styleOffline = {
      // This marker is 20 pixels wide by 32 pixels high.
      scaledSize: new google.maps.Size(28, 28), // eslint-disable-line no-undef
      // The origin for this image is (0, 0).
      origin: new google.maps.Point(0, 0), // eslint-disable-line no-undef
      // The anchor for this image is the base of the flagpole at (0, 32).
      anchor: new google.maps.Point(14, 14), // eslint-disable-line no-undef
    }

    mapObject.icon = `/assets/icons/${iconPath}`;
    const iconMarker = getIconAttributes(mapObject.color, 0, mapObject.icon, styleOffline)
    mapObject.instance.setIcon(iconMarker)
  })

  const sameArraysTrail = (array1, array2) => {
    // Step 1: Verify longitude to the arrays
    if (array1.length !== array2.length) {
        return false;
    }

    // Step 2: Comparar las posiciones
    for (let i = 0; i < array1.length; i++) {
        const pos1 = array1[i];
        const pos2 = array2[i];

        if (pos1.lat !== pos2.lat || pos1.lng !== pos2.lng) {
            return false; // Find the diferent position
        }
    }

    // the arrays is the same
    return true;
  }

  const compareArrayTrailAndReturnPosition = (trailArray, element) => {
    trailArray.forEach((item, index) => {
      if(item.lat == element.lat && item.lng == element.lng)
        containsData = index;
    })
    return -1
  }

  function removeDupply(array) {
    const uniqueCoordinates = new Set();
    return array.filter(obj => {
        const clave = `${obj.lng}-${obj.lat}`;
        if (!uniqueCoordinates.has(clave)) {
            uniqueCoordinates.add(clave);
            return true;
        }
        return false;
    });
  }

  const reproduceTrailEvents = useCallback(()  => {

    //get the events with events in stack
    let mapElements = mapObjects.filter((item) => item?.newEvents?.length > 0 && item?.isAnimated == false)
    mapElements.forEach((mapElement) => {
      let firstEvent = mapElement.newEvents.shift();
      updateObject(firstEvent , TypeObjectMap.Marker);
    })
  });


  /**********************************************************
   * If window lose focus, stop the animation
   **********************************************************/
  const focusStopTrailMarkersAnimations = useCallback(()=> {

    let mapElementsWithArrays = mapObjects.filter((item) => item.isAnimated == true)
    mapElementsWithArrays.forEach((item) => {
      item.isAnimated = false;
      item.stopAnimation = true;
      if(item.stopAnimationFunction){
        item.stopAnimationFunction()
      }
    })
  })

  /**********************************************************
   * Update the marker to the last position of the unit
   **********************************************************/
  const focusStartTrailMarkerAnimations = useCallback(()=> {
    let firstEvent = null;
    //update the marker to last position.
    let mapElementsStopedArray = mapObjects.filter((item) => item.stopAnimation)
    mapElementsStopedArray.forEach((item) => {
      item.stopAnimation = false;
      if(item.newEvents.length > 0){

        firstEvent = item.newEvents.reduce((maxObject, currentObject) => {
          // Get the most recent data for the queue
          if (!maxObject || parseInt(currentObject.unitTime) > parseInt(maxObject.unitTime)) {
            return currentObject;
          }
          // Otherwise, keep the current maxObject
          return maxObject;
        }, null);

        firstEvent = {
          ...firstEvent,
          trail: [] //create a automatic update without the movement
        }

        //update the objects to the last position
        item.newEvents = []
        updateObject(firstEvent , TypeObjectMap.Marker);

      }else{
        firstEvent = {
          ...item,
          position: item?.endPosition || item?.position,
          trail: [] //create a automatic update without the movement
        }
        //update the objects to the last position
        updateObject(firstEvent , TypeObjectMap.Marker);
      }
    })


    //clear the stack for trails and shows more recent.
    let mapElementsTrailsArray = mapObjects.filter((item) => item?.newEvents?.length > 0)
    mapElementsTrailsArray.forEach((item) => {
      item.stopAnimation = false;
      firstEvent = item.newEvents.reduce((maxObject, currentObject) => {
        // Get the most recent data for the queue
        if (!maxObject || parseInt(currentObject.unitTime) > parseInt(maxObject.unitTime)) {
          return currentObject;
        }
        // Otherwise, keep the current maxObject
        return maxObject;
      }, null);

      item.newEvents = []

      firstEvent = {
        ...firstEvent,
        trail: [] //create a automatic update without the movement
      }

      //update the objects to the last position
      updateObject(firstEvent , TypeObjectMap.Marker);
    })

  })

  /*********************************************************
   * Update objects into the realtime maps
   *********************************************************/
  const updateObject = useCallback((mapObjectParam, type) => {
    let index = -1;
    let mapElement = mapObjects.find((item, i) => {
      if (item.id == mapObjectParam.id && item.type == type) {
        index = i;
        return item;
      }
    })

    if (!mapElement || index < 0) {
      return
    }

    //validate unitTime
    if(mapObjectParam?.unitTime && convertDateToSeconds(mapElement.unitTime || 0) > convertDateToSeconds(mapObjectParam.unitTime || 0)){
      //the new event is old
      return
    }

    mapElement.endPosition = mapObjectParam?.position;
    mapElement.trailTimestamp = convertDateToSeconds(mapElement?.trailTimestamp || 0)

    if(mapElement.isAnimated == true){

      //If the event queue is larger than 10, only keep the most recent one and delete the rest.
      if(mapElement.newEvents.length > 9){
        const objectWithGreatestUnitTime = item.newEvents.reduce((maxObject, currentObject) => {
          // Get the most recent data for the queue
          if (!maxObject || parseInt(currentObject.unitTime) > parseInt(maxObject.unitTime)) {
            return parseInt(currentObject);
          }
          // Otherwise, keep the current maxObject
          return parseInt(maxObject);
        }, null);
        mapElement.newEvents = []
        mapElement.newEvents.push(objectWithGreatestUnitTime)
      }else{
        mapElement.newEvents.push(mapObjectParam)

        //order new events by unitTime
        mapElement.newEvents.sort((a, b) => parseInt(a?.unitTime || 0) - parseInt(b?.unitTime || 0));

      }
      //return the object animation in next step
      return
    }
    mapElement.stopAnimation = false;

    let noMovement = false, isTrail = false, newUnitTime = 0, firstEventReproduce = true;
    if(
      (mapElement.eventName == 'Ignition Off' && mapObjectParam.eventName == 'Ignition Off') || 
      (mapElement.eventName == 'Ignition Off' && mapObjectParam.eventName == 'Ignition On'))
      {
        noMovement = true;

        //2. Update the most recent date into the date atribute
        mapElement.trailTimestamp =  mapElement.trailTimestamp > convertDateToSeconds(mapObjectParam?.timestamp) ?  mapElement.trailTimestamp:  convertDateToSeconds(mapObjectParam?.timestamp)
    }

    //if diferent the time of unit for 3 mins not animate.
    newUnitTime = mapObjectParam.unitTime ? convertDateToSeconds(mapObjectParam.unitTime) : 0;

    if(!validateTrailTimeMins(newUnitTime, 3)){
      noMovement = true;
    }
    
    //start the array for trails new objects
    let newTrailOnObject = []

    //check the trail attribute is comming
    if(Array.isArray(mapObjectParam?.trail) && mapObjectParam?.trail?.length > 0){
      isTrail = true
      let newTrails = Array.isArray(mapObjectParam.trail) ? [...mapObjectParam.trail] : [];

      // Evaluate if the time of the main event differs significantly from the one provided in the trails.
      // Only the most recent position should be displayed.

      //add the last event to the list of reproduction
      if(newUnitTime > 0 && validateTrailTimeMins(newUnitTime, 3)){

        
        const maxTimestampTrailComes = newTrails.reduce((prev, current) => {
          return parseInt(prev.timestamp) > parseInt(current.timestamp) ? prev : current;
        });
        //if the most current trail date is more than 1 minute different from the event date it will not play.

        if(validateTrailTimeMinsCompare(newUnitTime, parseInt(maxTimestampTrailComes?.timestamp || 0), 1)){
          newTrails.unshift({
            isPlayed: false,
            timestamp: newUnitTime,
            lat: mapObjectParam.position.lat,
            lon: mapObjectParam.position.lng,
            lng: mapObjectParam.position.lng,
          })
        }else{
          //2. Update the most recent date into the date atribute
          mapElement.trailTimestamp =  mapElement.trailTimestamp > convertDateToSeconds(mapObjectParam?.timestamp) ? mapElement.trailTimestamp : convertDateToSeconds(mapObjectParam?.timestamp)

          //no reproduce trail
          noMovement = true;
        }
      }else{
        //2. Update the most recent date into the date atribute
        mapElement.trailTimestamp =  mapElement.trailTimestamp > convertDateToSeconds(mapObjectParam?.timestamp) ? mapElement.trailTimestamp : convertDateToSeconds(mapObjectParam?.timestamp)

        //no reproduce trail
        noMovement = true;
      }

      newTrails = newTrails.map(item => {
        return {
          ...item,
          timestamp: parseInt(convertDateToSeconds(item.timestamp)),
        };
      });

      //1. Get the last date 
      const maxTimestampObject = newTrails.reduce((prev, current) => {
        return parseInt(prev.timestamp) > parseInt(current.timestamp) ? prev : current;
      });

      //1.1 Eval is the date is max to the actual data
      if(convertDateToSeconds(maxTimestampObject?.timestamp) > convertDateToSeconds(mapElement?.trailTimestamp)){

        //1.2 Get the new positions relative with the date
        let filterTrails = newTrails.filter((item) => convertDateToSeconds(item.timestamp) > mapElement.trailTimestamp)

        //comented duply remove
        //filterTrails = removeDupply(filterTrails)

        //1.3 Sort the array asc to add the new 
        filterTrails.sort((a, b) => convertDateToSeconds(a.timestamp) - convertDateToSeconds(b.timestamp));

        //1.4 Add new objects to trail array
        //add the mark in the contents to be reproduced
        filterTrails.forEach((item, indexTrail) => {
          let secondsToReproduce = 0
          let currentTimestampForUnit = 0
          if(indexTrail == 0){

            currentTimestampForUnit = mapElement?.trailTimestamp
            if(currentTimestampForUnit == 0 && filterTrails.length > 1){
              secondsToReproduce = getDifferenceInSeconds(filterTrails[0].timestamp, filterTrails[1].timestamp)
            }else if(currentTimestampForUnit == 0){
              currentTimestampForUnit = item?.timestamp
              secondsToReproduce = getDifferenceInSeconds(currentTimestampForUnit, item?.timestamp)
            }else {
              secondsToReproduce = getDifferenceInSeconds(currentTimestampForUnit, item?.timestamp)
            }
          }else {
            secondsToReproduce = getDifferenceInSeconds(item.timestamp, newTrailOnObject[0].timestamp)
          }

          secondsToReproduce = secondsToReproduce < 1 ? 1 : secondsToReproduce

          //if the diference time to the last event is mayor to 30 seconds, set the start position to start trails.
          if(secondsToReproduce > 30 && indexTrail == 0){
            firstEventReproduce = false;
          }

          if(indexTrail == 0 && filterTrails.length > 1){

            const itemToCompare = filterTrails[1]
            const compareSeconds = getDifferenceInSeconds(convertDateToSeconds(mapObjectParam.unitTime), itemToCompare?.timestamp)

            //validate distance 
            const maxDistanceTraveled = compareSeconds > 1 ? calculateDistanceTraveled(180, compareSeconds) : 1000; //1000 metters to 140 km per hour for speed units (every 30 seconds to come data form the unit)

            if(compareSeconds > 60) noMovement = true;

            //if speed is mayor o equal to 120km per hour for TRUCKS
            //value of distance is in Metters
            const distanceValidationInMetters = calcDistanceToPoints(mapObjectParam.position.lat, mapObjectParam.position.lng, parseFloat(itemToCompare?.lat), parseFloat(itemToCompare?.lng || 0) || parseFloat(itemToCompare?.lon || 0));

            if(distanceValidationInMetters < maxDistanceTraveled){
              // made the validation when have error in trails
              // the trails come same in all rows
              if(isArray(mapElement?.trail) && mapElement.trail.length > 0 && filterTrails.length > 0 ){
                if(JSON.stringify(mapElement.trail) == JSON.stringify(filterTrails)){
                  //not move because the trail 
                  noMovement = true;
                }
              }
            }else{
              noMovement = true;
            }
          }


          newTrailOnObject.unshift({
            ...item,
            lng: item.lon,
            isPlayed: false, 
            secondsToReproduce: secondsToReproduce > 30 ? 0 : (secondsToReproduce - 1) <= 0 ? 0 : (secondsToReproduce - 1)
          })
        })

        //max trail queue is 20 items
        if (newTrailOnObject.length > 20) {
          newTrailOnObject.splice(20, newTrailOnObject.length - 20);
        }


        //2. Update the most recent date into the date atribute
        mapElement.trailTimestamp = parseInt(maxTimestampObject?.timestamp || 0)
      }else{
        //No moved when the old data comes to the new array on trails
        noMovement = true;
      }
    }

    //Remove the array the isPlayed is true, only reproduce the new elements to trails
    newTrailOnObject = newTrailOnObject.filter((item) => !item.isPlayed)

    //update the element reference
    let cloneMapElement = { ...mapElement, firstEventReproduce: firstEventReproduce }
    cloneMapElement.trail = newTrailOnObject;
    cloneMapElement.originalTrail = newTrailOnObject;
    //Update the aray of objects
    mapObjects[index] = { ...mapElement, ...mapObjectParam, trail: newTrailOnObject };

    //Pint new position into the realtime maps
    switch (type) {
      case TypeObjectMap.Marker:
        
      
        if(isTrail && cloneMapElement.trail.length > 0 && !noMovement){
          updateMoveMarkerByTrail(cloneMapElement, mapObjectParam, index)
        }else if(isTrail && noMovement && mapElement.isAnimated == false){
          updateMoveMarker(mapElement, mapObjectParam);
        }else if(!isTrail) {
          updateMoveMarker(mapElement, mapObjectParam);
        }

        const newLabel = mapElement.label != mapObjectParam.label ? mapObjectParam.label : '';

        const objectCluster = getObject(TypeObjectMap.MarkerCluster, 'unitCluster');
        const isCluster = objectCluster && objectCluster.instance

        //is prevent overlap Labels is active.
        const objectNoOverlapSettings = getObject(TypeObjectMap.Settings, 'noOverlapSettings');
        if (objectNoOverlapSettings && objectNoOverlapSettings.enable && mapItem.getZoom() > zoomLabelsPositions
          && mapElement.position.lat >= lat1 && mapElement.position.lat <= lat2
          && mapElement.position.lng >= lng1 && mapElement.position.lng <= lng2) {


          if (updateLabelsAndAreas(mapElement, mapObjectParam, index)) {
            const {
              newPositionLabel,
              labelPositionSpace,
              labelGroupArea,
              labelGroup
            } = evaluateLabelsPosition(mapElement, mapItem.getZoom(), true);

            mapElement.labelPositionSpace = labelPositionSpace;
            mapElement.labelGroupArea = labelGroupArea;
            mapElement.labelGroup = labelGroup;

            updateMarkerLabel(mapElement, '', newPositionLabel, true, newLabel || mapElement.label);

            const objectCluster = getObject(TypeObjectMap.MarkerCluster, 'unitCluster');
            const isCluster = objectCluster && objectCluster.instance

            if (!isCluster) {
              removeMapObject(`temporalLabel_poly_${mapElement.id}`, TypeObjectMap.Polyline);
              addMapObject(new MapObject().buildPolyLine({
                id: `temporalLabel_poly_${mapElement.id}`,
                coordinates: [mapObjectParam.position, newPositionLabel],
                layerName: TypeLayer.UnitLabel,
                heading: 0,
                color: '#B0B0B0'
              }), true, true);
            }
          }
        } else {
          //normal label update
          updateMarkerLabel(mapObjectParam, newLabel);
        }

        //mapObjects[index] = { ...mapElement, ...mapObjectParam };
        break;

      case TypeObjectMap.Polygon:

        break;
    }
  })

  /*********************************************************
   * Eval to unit enter or left the area.
  *********************************************************/
  const updateLabelsAndAreas = (mapElement, mapObjectParam, index) => {

    //is the new unit in a group?
    let arrayLabelsStack = null;
    if (mapElement.labelGroup && mapElement.labelGroupArea) {

      //verify that you are in the same area 
      //do not move label - continue in the group
      if (mapElement.labelGroupArea
        && ((mapObjectParam.position.lat >= mapElement.labelGroupArea.pointLatFirst)
          && (mapObjectParam.position.lat <= mapElement.labelGroupArea.pointLatSecond)
          && (mapObjectParam.position.lng >= mapElement.labelGroupArea.pointLngFirst)
          && (mapObjectParam.position.lng <= mapElement.labelGroupArea.pointLngSecond))) {
        return false;
      } else {
        //The unit was previously in a group but left that area or group.
        //UPDATING!! the units that are in the group from which the unit came from
        //check the group still has the number of units needed to form a group
        labelUpdateUnitsOnGroup(mapElement, mapObjectParam)


        //evaluate whether the unit can be in another group.
        let otherLabelsStack = mapObjects.filter((item) =>
          item.labelGroup
          && item.labelGroupArea
          && ((mapObjectParam.position.lat >= item.labelGroupArea.pointLatFirst)
            && (mapObjectParam.position.lat <= item.labelGroupArea.pointLatSecond)
            && (mapObjectParam.position.lng >= item.labelGroupArea.pointLngFirst)
            && (mapObjectParam.position.lng <= item.labelGroupArea.pointLngSecond)))

        //If the unit is in another group
        if (otherLabelsStack && otherLabelsStack.length > 0) {

          labelEvalUnitIntoAnotherGroup(otherLabelsStack, mapElement, mapObjectParam, index);
          return false;

        } else {

          //IMPORTANT: You are NOT in any group, but you can form a new group.
          //with other units that are NOT in a group
          arrayLabelsStack = mapObjects.filter((item) =>
            item.type == TypeObjectMap.Marker
            && (item.instance && item.instance.getMap())
            && item.layerName == TypeLayer.Units
            && (item.position
              && item.position.lat >= lat1 && item.position.lat <= lat2
              && item.position.lng >= lng1 && item.position.lng <= lng2));

          //get the units into the cuadrant
          arrayLabelsStack = arrayLabelsStack.filter((item) =>
            item.position
            && !item.labelGroup
            && mapElement.labelGroupArea
            && ((item.position.lat >= mapElement.labelGroupArea.pointLatFirst)
              && (item.position.lat <= mapElement.labelGroupArea.pointLatSecond)
              && (item.position.lng >= mapElement.labelGroupArea.pointLngFirst)
              && (item.position.lng <= mapElement.labelGroupArea.pointLngSecond)))

          if (arrayLabelsStack && arrayLabelsStack.length > 5) {

            labelCreateGroupUnitsWithoutGroup(mapElement, mapObjectParam, arrayLabelsStack, index);
            return false

          } else {
            //Will NOT form any group, remains alone
            //indicate that the unit is no longer in a group     
            //Create label
            labelExitFromGroupToCreateLabel(mapElement, mapObjectParam, index)
            return true
          }
        }
      }
    } else {
      //The unit is not in any group, a new space must be found for it.
      //Find if the unit is entering a new group

      let otherLabelsStack = mapObjects.filter((item) =>
        item.labelGroup
        && item.labelGroupArea
        && ((mapObjectParam.position.lat >= item.labelGroupArea.pointLatFirst)
          && (mapObjectParam.position.lat <= item.labelGroupArea.pointLatSecond)
          && (mapObjectParam.position.lng >= item.labelGroupArea.pointLngFirst)
          && (mapObjectParam.position.lng <= item.labelGroupArea.pointLngSecond)))

      //find in another group
      if (otherLabelsStack && otherLabelsStack.length > 0) {

        labelWithoutGroupEnterInGroup(mapElement, mapObjectParam, otherLabelsStack, index)
        return false;
      }

      //TO SEE IF THE UNIT CAN FORM A NEW GROUP
      arrayLabelsStack = mapObjects.filter((item) =>
        item.type == TypeObjectMap.Marker
        && (item.instance && item.instance.getMap())
        && item.layerName == TypeLayer.Units
        && (item.position
          && item.position.lat >= lat1 && item.position.lat <= lat2
          && item.position.lng >= lng1 && item.position.lng <= lng2));

      //get the units into the cuadrant
      arrayLabelsStack = arrayLabelsStack.filter((item) =>
        item.position
        && !item.labelGroup
        && mapElement.labelGroupArea
        && ((item.position.lat >= mapElement.labelGroupArea.pointLatFirst)
          && (item.position.lat <= mapElement.labelGroupArea.pointLatSecond)
          && (item.position.lng >= mapElement.labelGroupArea.pointLngFirst)
          && (item.position.lng <= mapElement.labelGroupArea.pointLngSecond)))

      if (arrayLabelsStack && arrayLabelsStack.length > 5) {

        labelCreateGroupUnitsWithoutGroup(mapElement, mapObjectParam, arrayLabelsStack, index);
        return false
      }

      //THE UNIT IS ALONE UPDATE THE LABEL
      return true
    }
    //IMPORTANT!:
    //Make logic to avoid duplicity of polygons
  }
  

  /*********************************************************
   * When the unit enter in a group, is necesary to remove the old label
   * @param {Upd} mapElement 
   * @param {*} mapObjectParam 
   *********************************************************/
  const labelWithoutGroupEnterInGroup = (mapElement, mapObjectParam, otherLabelsStack, paramIndex) => {

    //obtain the units of that group
    const unitsOnGroup = []
    otherLabelsStack[0].labelGroup.forEach((unitId) => {
      unitsOnGroup.push(getObject(TypeObjectMap.Marker, unitId.toString()))
    })
    //add the new unit to the group
    unitsOnGroup.push(getObject(TypeObjectMap.Marker, mapObjectParam.id))
    //obtain the main object of the group associated with the group label
    let mainObjectUnitGroup = getObject(TypeObjectMap.Marker, unitsOnGroup[0].labelGroupIdMain)

    //SEARCH! - why it arrives empty!!!
    if (!mainObjectUnitGroup) {
      mainObjectUnitGroup = getObject(TypeObjectMap.Marker, unitsOnGroup[1].labelGroupIdMain)
      if (!mainObjectUnitGroup) return
    }

    //all objects are updated
    const valueLabelGroup = unitsOnGroup.map((group) => group.id)
    let label = '';
    unitsOnGroup.forEach((unit, index) => {
      if (index <= 4) {
        label = `${label}${unit.label} </br>`;

      }
      //add the new unit to the group
      unit.labelGroup = valueLabelGroup;
      unit.labelGroupArea = mainObjectUnitGroup.labelGroupArea;
      unit.labelGroupIdMain = mainObjectUnitGroup.labelGroupIdMain;
    });


    let mainObjectLabel = getObject(TypeObjectMap.Label, `label_${mainObjectUnitGroup.labelGroupIdMain}`)
    //update elements into the info bubble
    if (mainObjectLabel) updateMarkerLabel(mainObjectLabel, '', mainObjectLabel.position, true, label);

    //remove label
    removeMapObject(`label_${mapElement.id}`, TypeObjectMap.Label);
    //remove polyline
    removeMapObject(`temporalLabel_poly_${mapElement.id}`, TypeObjectMap.Polyline);

    //update mapObjects with new values
    mapObjects[paramIndex] = { ...mapElement, ...mapObjectParam };
  }

  /*********************************************************
   * Create a label when unit exit from area
   * @param {Upd} mapElement 
   * @param {*} mapObjectParam 
   *********************************************************/
  const labelExitFromGroupToCreateLabel = (mapElement, mapObjectParam, index) => {
    mapElement.labelGroup = null;
    mapElement.labelGroupArea = null;
    mapElement.labelGroupIdMain = null;
    //update mapObjects with new values
    mapObjects[index] = { ...mapElement, ...mapObjectParam };

    //remove the previus object
    removeMapObject(`label_${mapObjectParam.id}`, TypeObjectMap.Label);
    //create the independent label for this new unit.
    const objectLabel = new MapObject().buildLabel({
      id: `label_${mapObjectParam.id}`,
      label: cutNotes(mapElement.label, 30),
      position: { lat: mapElement.position.lat, lng: mapElement.position.lng },
      layerName: TypeLayer.UnitLabel
    })
    //add the object into the map
    addMapObject(objectLabel);
  }

  /*********************************************************
   * Update the units into the labels Group on map,
   * is important to update the area.
   * @param {Upd} mapElement 
   * @param {*} mapObjectParam 
   *********************************************************/
  const labelUpdateUnitsOnGroup = (mapElement, mapObjectParam) => {
    let arrayLabelsStack = mapObjects.filter((item) =>
      item.type == TypeObjectMap.Marker
      && (item.instance && item.instance.getMap())
      && item.layerName == TypeLayer.Units
      && (item.position
        && item.position.lat >= lat1 && item.position.lat <= lat2
        && item.position.lng >= lng1 && item.position.lng <= lng2));

    //get the units into the cuadrant
    arrayLabelsStack = arrayLabelsStack.filter((item) =>
      item.id.toString() != mapObjectParam.id.toString()
      && item.position
      && item.labelGroup
      && mapElement.labelGroupArea
      && item.labelGroupIdMain == item.labelGroupIdMain
      && ((item.position.lat >= mapElement.labelGroupArea.pointLatFirst)
        && (item.position.lat <= mapElement.labelGroupArea.pointLatSecond)
        && (item.position.lng >= mapElement.labelGroupArea.pointLngFirst)
        && (item.position.lng <= mapElement.labelGroupArea.pointLngSecond)))

    if (arrayLabelsStack && arrayLabelsStack.length > 5) {

      //update the all objects
      const valueLabelGroup = arrayLabelsStack.map((group) => group.id)
      let label = '';
      arrayLabelsStack.forEach((unit, index) => {
        if (unit.id != mapObjectParam.id) {
          unit.labelGroup = valueLabelGroup;
          //Get the label and update the group and unit
          if (index <= 4) {
            label = `${label}${unit.label} </br>`;
          }
        }
      });

      let mainObjectMarker = getObject(TypeObjectMap.Marker, mapElement.labelGroupIdMain)
      if (mainObjectMarker) mainObjectMarker.labelGroup = valueLabelGroup;
      let mainObjectLabel = getObject(TypeObjectMap.Label, `label_${mapElement.labelGroupIdMain}`)
      if (mainObjectLabel) {
        mainObjectLabel.labelGroup = valueLabelGroup;
        //update elements into the info bubble
        updateMarkerLabel(mainObjectLabel, '', mainObjectLabel.position, true, label);
      }

      //UPDATE THE CIRCLE AREA!! - MAYBE IS MORE SMALL
      if (mainObjectMarker)
        labelDrawGroupAreaOnMap(mainObjectMarker, arrayLabelsStack)
    }
  }

  /*********************************************************
   * when the unit is in another group
   * @param {*} otherLabelsStack 
   * @param {*} mapElement 
   * @param {*} mapObjectParam 
   * @returns 
   *********************************************************/
  const labelEvalUnitIntoAnotherGroup = (otherLabelsStack, mapElement, mapObjectParam, indexParam) => {
    let mainObjectUnitGroup = getObject(TypeObjectMap.Marker, otherLabelsStack[0].labelGroupIdMain)

    if (!mainObjectUnitGroup || (mainObjectUnitGroup && !mainObjectUnitGroup.labelGroup)) return false;

    //obtain the units of that group
    const unitsOnGroup = []
    mainObjectUnitGroup.labelGroup.forEach((unitId) => {
      unitsOnGroup.push(getObject(TypeObjectMap.Marker, unitId.toString()))
    })
    //add the new unit to the group
    unitsOnGroup.push(getObject(TypeObjectMap.Marker, mapObjectParam.id))
    //obtain the main object of the group associated with the group label

    //Update all objects
    const valueLabelGroup = unitsOnGroup.map((group) => group.id)
    let label = '';
    unitsOnGroup.forEach((unit, index) => {
      if (index <= 4) {
        label = `${label}${unit.label} </br>`;

      }
      //add new unit to the group
      unit.labelGroup = valueLabelGroup;
      unit.labelPosition = mainObjectUnitGroup.labelPosition
      unit.labelGroupArea = mainObjectUnitGroup.labelGroupArea;
      unit.labelGroupIdMain = mainObjectUnitGroup.labelGroupIdMain;
    });

    //update mapObjects with new values
    mapObjects[indexParam] = { ...mapElement, ...mapObjectParam };

    const mainObjectLabel = getObject(TypeObjectMap.Label, `label_${mainObjectUnitGroup.labelGroupIdMain}`)
    //update elements into the info bubble
    if (mainObjectLabel) updateMarkerLabel(mainObjectLabel, '', mainObjectLabel.position, true, label);

    //Redraw area....
    //UPDATE THE CIRCLE AREA!! - MAYBE IS MORE SMALL
    labelDrawGroupAreaOnMap(mainObjectUnitGroup, unitsOnGroup);
  }

  /*********************************************************
   * Create the group labels for units without group
   * @param {*} otherLabelsStack 
   * @param {*} mapElement 
   * @param {*} mapObjectParam 
   * @returns 
   *********************************************************/
  const labelCreateGroupUnitsWithoutGroup = (mapElement, mapObjectParam, arrayLabelsStack, paramIndex) => {
    mapObjects[paramIndex] = { ...mapElement, ...mapObjectParam };
    let markerToAdd = getObject(TypeObjectMap.Marker, mapElement.id)
    arrayLabelsStack.unshift(markerToAdd);

    //get first element to center object.
    let label = ''

    //delete the old label object check this...

    const objectOverlayObject = getObject(TypeObjectMap.Label, `label_${markerToAdd.id}`);
    //eval no update to labels
    markerToAdd.labelPosition = false;
    markerToAdd.labelPositionSpace = null;
    markerToAdd.labelGroupArea = null;
    markerToAdd.labelGroupIdMain = null;

    const {
      newPositionLabel,
      labelPositionSpace,
      groupElements,
      labelGroupArea,
      labelGroupIdMain
    } = evaluateLabelsPosition(mapObjects[paramIndex], mapItem.getZoom());

    markerToAdd = getObject(TypeObjectMap.Marker, labelGroupIdMain)

    markerToAdd.labelPositionSpace = labelPositionSpace;
    markerToAdd.labelGroupArea = labelGroupArea;
    markerToAdd.labelGroupIdMain = labelGroupIdMain;

    if (objectOverlayObject) objectOverlayObject.labelPositionSpace = labelPositionSpace;

    let groupsArray = []
    if (groupElements.arrayElements) {
      groupsArray = groupElements.elements
      const valueLabelGroup = groupsArray.map((group) => group.id)
      mapObject.labelGroup = valueLabelGroup;

      groupsArray.forEach((unit, index) => {
        unit.labelGroup = valueLabelGroup;
        unit.labelPositionSpace = labelPositionSpace;
        unit.labelGroupArea = labelGroupArea;
        unit.labelGroupIdMain = labelGroupIdMain;

        if (index <= 4) {
          label = `${label}${unit.label} </br>`;
        }

        //show all elements
        const showLabel = unit.id == markerToAdd.id
        const objectOverlay = getObject(TypeObjectMap.Label, `label_${unit.id}`);
        if (objectOverlay) {
          objectOverlay.labelGroup = valueLabelGroup;
          objectOverlay.labelPosition = true;
          objectOverlay.labelPositionSpace = labelPositionSpace;
          objectOverlay.labelGroupArea = labelGroupArea;
          objectOverlay.labelGroupIdMain = labelGroupIdMain;
          objectOverlay.instance.onDisplayObject(showLabel, mapItem);
        }

        if (!showLabel) {
          removeMapObject(`temporalLabel_poly_${unit.id}`, TypeObjectMap.Polyline);
        }
      });

      //update elements
      updateMarkerLabel(markerToAdd, '', newPositionLabel, true, label);
    }
    mapObjects[paramIndex] = { ...mapElement, ...markerToAdd, ...mapObjectParam };
  }

  /*********************************************************
   * update the marker position into the realtime maps
   * @param {JSON} mapObjectParam 
   * @param {JSON} mapElement 
   *********************************************************/
  const updateMoveMarker = (mapElement, mapObjectParam) => {
    const color = validateEventColor(mapObjectParam)
    if (!mapElement.icon) {
      mapElement.instance.setIcon(getIconAttributes('#' + color, mapObjectParam.heading, null, null, mapObjectParam));
    }else{      
      const iconMarker = getIconAttributes(mapElement.color, 0, mapElement.icon, false)
      mapElement.instance.setIcon(iconMarker)
    }
    const position = new google.maps.LatLng( // eslint-disable-line no-undef
      parseFloat(mapObjectParam.position.lat),
      parseFloat(mapObjectParam.position.lng));
    mapElement.instance.setPosition(position)

    if(props?.followUnit && mapObjectParam.id == props?.centerUnitId){
      centerMapAnimated(mapObjectParam.position.lat, mapObjectParam.position.lng);
    }
  }

  /*********************************************************
   * update the marker position into the realtime maps
   * @param {JSON} mapObjectParam 
   * @param {JSON} mapElement 
   *********************************************************/
  const updateMoveMarkerByTrail = (cloneMapElement, newMapElementParam, index) => {    
    if(cloneMapElement?.trail && Array.isArray(cloneMapElement.trail) && cloneMapElement.trail?.length > 0){
      if(newMapElementParam.eventName == 'Drive' && cloneMapElement.eventName != newMapElementParam.eventName){
        const color = validateEventColor(newMapElementParam)
        //update the name of event to show the correct marker
        cloneMapElement.eventName = newMapElementParam.eventName;
        if (!cloneMapElement.icon) {
          cloneMapElement.instance.setIcon(getIconAttributes('#' + color, cloneMapElement.heading, null, null, newMapElementParam));
        }else{      
          const iconMarker = getIconAttributes(cloneMapElement.color, 0, cloneMapElement.icon, false)
          cloneMapElement.instance.setIcon(iconMarker)
        }
      }


      var bounds = mapItem.getBounds();

      // Get the coordinates for bounds on map
      if (bounds && bounds.getSouthWest() && bounds.getNorthEast()) {
        lat1 = bounds.getSouthWest().lat(), lat2 = bounds.getNorthEast().lat();
        lng1 = bounds.getSouthWest().lng(), lng2 = bounds.getNorthEast().lng();
      }

      //Validate the bounds into the last position on array
      const lastLat = parseFloat(cloneMapElement?.trail[0].lat)
      const lastLng = parseFloat(cloneMapElement?.trail[0]?.lon || cloneMapElement?.trail[0]?.lng)

      //validate the bounds into the first position on array
      const firstLat = parseFloat(cloneMapElement?.trail[cloneMapElement?.trail.length - 1].lat)
      const firstLng = parseFloat(cloneMapElement?.trail[cloneMapElement?.trail.length - 1]?.lon || cloneMapElement?.trail[cloneMapElement?.trail.length - 1]?.lng)

      let isValidPosition = false
      if ((firstLat >= lat1 && firstLat <= lat2
        && firstLng >= lng1 && firstLng <= lng2) ||
        (lastLat >= lat1 && lastLat <= lat2
          && lastLng >= lng1 && lastLng <= lng2)
        ){
          isValidPosition = true;
      }

      const isCenterUnit = props?.centerUnitId?.toString() == cloneMapElement?.id?.toString() || false
      
      let mapElement = mapObjects.find((item) => {
        if (item.id == cloneMapElement.id && item.type == TypeObjectMap.Marker) {
          return item;
        }
      })

      if(mapItem.getZoom() >= zoomToEnableTrails && isValidPosition || isCenterUnit){

        const lat = cloneMapElement?.trail[cloneMapElement?.trail.length - 1].lat
        const lng = cloneMapElement?.trail[cloneMapElement?.trail.length - 1].lon || cloneMapElement?.trail[cloneMapElement?.trail.length - 1].lng
        
        const newPositionLatLng = new google.maps.LatLng( // eslint-disable-line no-undef
        parseFloat(lat),
        parseFloat(lng));

        const distanceOnMetters = google.maps.geometry.spherical.computeDistanceBetween(cloneMapElement.instance.getPosition(), newPositionLatLng);

        //Validate the distance diferent to 50 metters -- last validation 4500
        if(distanceOnMetters > trailsMaxDistance){
          cloneMapElement.instance.setPosition(newPositionLatLng) 
          mapElement.position = {lat: lat, lng: lng}
          if(props.followUnit && cloneMapElement.id == props.centerUnitId){
            centerMapAnimated(lat, lng);
          }
        }else if(distanceOnMetters > trailsMinDistance) {
          const newDirection = calcDirection(cloneMapElement.instance.getPosition().lat(), cloneMapElement.instance.getPosition().lng(),
          newPositionLatLng.lat(), newPositionLatLng.lng())

          //if have change of direction - not animate onside the streets
          if(validateDirectionChange(cloneMapElement.heading, newDirection)){
            cloneMapElement.instance.setPosition(newPositionLatLng) 
            mapElement.position = {lat: lat, lng: lng}
            if(props.followUnit && cloneMapElement.id == props.centerUnitId){
              centerMapAnimated(lat, lng);
            }
          }
        }else if(cloneMapElement?.firstEventReproduce){
          cloneMapElement.instance.setPosition(newPositionLatLng) 
          mapElement.position = {lat: lat, lng: lng}
          if(props.followUnit && cloneMapElement.id == props.centerUnitId){
            centerMapAnimated(lat, lng);
          }
        }

        if(cloneMapElement.isAnimated == false){
          
          //update the flag for animation in original object on marker
          if(mapElement){
            mapElement.isAnimated = true;
          }
          moveMarkerAnimation(cloneMapElement, getMoreElementsInTrails.bind(null, cloneMapElement))

        }

      } else if(mapElement.isAnimated == false) {
        
        
        const color = validateEventColor(newMapElementParam)
        if (!mapElement.icon) {
          mapElement.instance.setIcon(getIconAttributes('#' + color, newMapElementParam.heading, null, null, newMapElementParam));
        }else{      
          const iconMarker = getIconAttributes(mapElement.color, 0, mapElement.icon, false)
          mapElement.instance.setIcon(iconMarker)
        }
        

        //set the position to the marker at the normal for without the animations.
        const lat = parseFloat(cloneMapElement?.trail[0].lat)
        const lng = parseFloat(cloneMapElement?.trail[0].lon || cloneMapElement?.trail[0].lng)

        if(!isNaN(lat) && !isNaN(lng)){
          const position = new google.maps.LatLng( // eslint-disable-line no-undef
          parseFloat(lat),
          parseFloat(lng));
          mapElement.instance.setPosition(position)
          mapElement.position = {lat: lat, lng: lng}
        }
        if(props.followUnit && cloneMapElement.id == props.centerUnitId){
          centerMapAnimated(lat, lng);
        }
         
        if(getOpenInfoWindow() && cloneMapElement.id == props.centerUnitId){
          //Refresh data at the info window
          updateModalPosition({
            id: cloneMapElement.id,
            position: { 
              lat: parseFloat(lat), 
              lng: parseFloat(lng) 
            } 
          })
        }
      } 

    }
  }

  const getMoreElementsInTrails = (cloneMapElement) => {
    let mapElement = mapObjects.find((item) => {
      if (item.id == cloneMapElement.id && item.type == TypeObjectMap.Marker) {
        return item;
      }
    })

    const color = validateEventColor(mapElement)
    if (!mapElement.icon) {
      mapElement.instance.setIcon(getIconAttributes('#' + color, mapElement.heading, null, null, mapElement));
    }else{      
      const iconMarker = getIconAttributes(mapElement.color, 0, mapElement.icon, false)
      mapElement.instance.setIcon(iconMarker)
    }

    //update the status of animation to false, to start to reproduce again
    mapElement.isAnimated = false;
  }



  const moveMarkerAnimation = (mapElement, callbackAnimation) => {
    
    let mapElementRef = mapObjects.find((item) => {
      if (item.id == mapElement.id && item.type == TypeObjectMap.Marker) {
        return item;
      }
    })

    mapElement.trail = mapElement.trail.filter((item) => !item?.isPlayed)

    if (mapElement.trail.length > 0) {
      
        const newPosition = mapElement.trail[mapElement.trail.length - 1];

        const lat = newPosition.lat
        const lng = newPosition?.lng || newPosition?.lon
        const newPositionLatLng = new google.maps.LatLng(lat, lng);

        mapElement.position = {lat: lat, lng: lng};
        
        const duration =  newPosition.secondsToReproduce
        
        // Make a new calc with the distance to old and new position
        //const distance = google.maps.geometry.spherical.computeDistanceBetween(mapElement.instance.getPosition(), newPositionLatLng);
        
        // make a calc of duration depens of the distance to the new position
        //const duration = Math.max(distance / 100, 1); 
        mapElement.trail[mapElement.trail.length - 1].isPlayed = true;

        //Only with the new position is diferent with the new make a animation
        //else continue with the new element of the array
        if(mapElement.instance.getPosition().lat() != newPositionLatLng.lat() && mapElement.instance.getPosition().lng() != newPositionLatLng.lng()){
          const direction = calcDirection(mapElement.instance.getPosition().lat(), mapElement.instance.getPosition().lng(),
          newPositionLatLng.lat(), newPositionLatLng.lng())

          const distance = google.maps.geometry.spherical.computeDistanceBetween(mapElement.instance.getPosition(), newPositionLatLng);

          //if distance is mayor for 10 metters
          if(distance > 5){
            const color = validateEventColor(mapElement)
            if (!mapElement.icon) {
              mapElement.instance.setIcon(getIconAttributes('#' + color, direction, null, null, mapElement));
            }
          }
        
          // Marker animation
          const stopAnimationFunction = animateMarker(mapElement, newPositionLatLng, duration, function() {
            moveMarkerAnimation(mapElement, callbackAnimation);
          });

          mapElementRef.stopAnimationFunction = stopAnimationFunction
        }else{
          //Continew with the new element of the array
          //reproduce the new element on array
          moveMarkerAnimation(mapElement, callbackAnimation);
          
        }

    }else{
      
      callbackAnimation(mapElement)

    }
    
}

//this method always return the diferences of the dates in seconds.
function getDifferenceInSeconds(timestamp1, timestamp2) {

  // Check if the timestamps are in milliseconds or seconds
  if (timestamp1 > 1000000000000) {
    // If both timestamps are greater than 1000000000000, then assume they are in milliseconds
    timestamp1 = Math.floor(timestamp1 / 1000);
  } 

  // Check if the timestamps are in milliseconds or seconds
  if (timestamp2 > 1000000000000) {
    // If both timestamps are greater than 1000000000000, then assume they are in milliseconds
    timestamp2 = Math.floor(timestamp2 / 1000);
  } 

  // Ensure the larger timestamp is placed first
  if (timestamp1 < timestamp2) {
    var temp = timestamp1;
    timestamp1 = timestamp2;
    timestamp2 = temp;
  }
  
  // Calculate the difference in seconds
  var differenceInSeconds = Math.abs(timestamp1 - timestamp2);
  return differenceInSeconds;
}

function calcDirection(paramLat1, paramLon1, paramLat2, paramLon2) {
  const toRadians = (deg) => deg * Math.PI / 180;

  // Convert degrees to radians
  const lat1 = toRadians(paramLat1);
  const lon1 = toRadians(paramLon1);
  const lat2 = toRadians(paramLat2);
  const lon2 = toRadians(paramLon2);

  // Normalize longitudes - new evaluation
  const dLon = (lon2 - lon1 + Math.PI) % (2 * Math.PI) - Math.PI;

  // Calculate direction using atan2
  const y = Math.sin(dLon) * Math.cos(lat2);
  const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
  let angleRad = Math.atan2(y, x);

  // Convert radians to degrees
  let angleDeg = angleRad * 180 / Math.PI;

  // Normalize angle to range [0, 360)
  angleDeg = (angleDeg + 360) % 360;

  //if (angleDeg < 0) {
  //  angleDeg += 360;
  //} 

  return angleDeg;
}



function animateMarker(mapElement, targetPosition, duration, callback) {
    const marker = mapElement.instance;
    const idElement = mapElement.id;
    const startPosition = marker.getPosition();
    const startTime = new Date().getTime();
    let animationCancelled = false; // Variable para controlar la animación
    let animationFrameId;

    function animate() {
        if (animationCancelled) return; // Si la animación fue cancelada, salimos de la función

        const currentTime = new Date().getTime();
        const elapsed = (currentTime - startTime) / 1000; // segundos

        if (elapsed < duration) {
            const fraction = elapsed / duration;
            const newPosition = google.maps.geometry.spherical.interpolate(startPosition, targetPosition, fraction);
            marker.setPosition(newPosition);
            
            updateModalPositionTrails({
              id: idElement,
              position: { 
                lat: parseFloat(newPosition.lat()), 
                lng: parseFloat(newPosition.lng()) 
              } 
            })
            

            animationFrameId = requestAnimationFrame(animate);

            //update label position
            const newLabel = mapElement?.label || '';
            updateMarkerLabel(mapElement, newLabel);
        } else {
            marker.setPosition(targetPosition);
            if(callback) callback();
        }
    }

    animationFrameId = requestAnimationFrame(animate);

    function stopAnimation() {
      animationCancelled = true;
      cancelAnimationFrame(animationFrameId); // Stops the animation
    }

    return stopAnimation;
  }


  /*********************************************************
   * update the name of marker in realtime maps
   * @param {JSON} mapObject 
   * @param {string} label 
   *********************************************************/
  const updateMarkerLabel = (mapObject, label = '', newPosition = null, isLabelPosition = false, labelNames = '') => {
    let googlePosition = null
    if (isLabelPosition) {
      googlePosition = new google.maps.LatLng(newPosition.lat, newPosition.lng)
    } else if(mapObject?.instance) {
      googlePosition = mapObject.instance.getPosition()
    } else {
      googlePosition = new google.maps.LatLng(mapObject.position.lat, mapObject.position.lng)
    }

    const objectMarkerOverlay = getObject(TypeObjectMap.Label, `label_${mapObject.id}`);
    if (objectMarkerOverlay && objectMarkerOverlay.instance != null) {
      objectMarkerOverlay.instance.onMove(googlePosition, isLabelPosition);
      //update the start position
      objectMarkerOverlay.startPosition = mapObject.position;
      objectMarkerOverlay.labelPosition = isLabelPosition;

      if (isLabelPosition) {
        objectMarkerOverlay.position = newPosition
      } else {
        objectMarkerOverlay.position = mapObject.position
      }

      if (labelNames != '') {
        if (mapObject.labelGroup)
          objectMarkerOverlay.instance.onChangeLabel(labelNames, mapObject.labelGroup, onCallbackClickLabel); //mapObject.label
        else
          objectMarkerOverlay.instance.onChangeLabel(labelNames); //mapObject.label

      } else if (label != '') {
        objectMarkerOverlay.instance.onChangeLabel(label); //mapObject.label
      }
    }
  }

  /*********************************************************
 * Show the units into the right panel area
 *********************************************************/
  const onCallbackClickLabel = (unitSelection) => {
    dispatch(selectModuleMapSettings(15));
    dispatch(setListUnits([], false));
    setTimeout(() => {
      dispatch(setListUnits(unitSelection.map((item) => +item), false));
    }, 500);
  };

  /*********************************************************
   * Remove the All objects
   *********************************************************/
  const removeAll = useCallback(() => {
    for (let i = (mapObjects.length - 1); i >= 0; i--) {
      let mapObject = mapObjects[i];
      try {
        if (mapObject.instance) {
          mapObject.instance.setMap(null);
          mapObject.instance = null;
        }
        if (mapObject.childInstance) {
          mapObject.childInstance.setMap(null);
          mapObject.childInstance = null;
        }
        mapObjects.splice(i, 1);
      }
      catch (err) {
        //console.log("remov:::: " + err.description);
      }
    }
  })

  /*********************************************************
   * Remove the all items by TypeObjectMap
   *********************************************************/
  const removeAllbyItemObject = useCallback((type = TypeObjectMap.Marker) => {
    for (let i = (mapObjects.length - 1); i >= 0; i--) {
      let mapObject = mapObjects[i];
      try {
        if (mapObject.type == type) {
          mapObject.instance.setMap(null);
          mapObject.instance = null;
          if (mapObject.childInstance) {
            mapObject.childInstance.setMap(null);
            mapObject.childInstance = null;
          }
          mapObjects.splice(i, 1);
        }
      }
      catch (err) {
        //console.log("remov:::: " + err.description);
      }
    }
  })

  /*********************************************************
   * Remove the all items by Type object and id
   *********************************************************/
  const removeItemsByIdAndType = useCallback((arrayIds, type = TypeObjectMap.Marker) => {
    for (let i = (mapObjects.length - 1); i >= 0; i--) {
      let mapObject = mapObjects[i];
      try {
        if (mapObject.type == type && arrayIds.indexOf(mapObject.id + '') > -1) {
          mapObject.instance.setMap(null);
          mapObject.instance = null;
          if (mapObject.childInstance) {
            mapObject.childInstance.setMap(null);
            mapObject.childInstance = null;
          }
          mapObjects.splice(i, 1);
        }
      }
      catch (err) {
        //console.log("remov:::: " + err.description);
      }
    }
  })

  /*********************************************************
   * Show or Hide the layer elements into the realtime maps
   *********************************************************/
  const showLayer = useCallback((layerName, show = false) => {
    mapObjects.forEach((item) => {
      if (item.layerName == layerName && item.instance) {
        item.instance.setMap(show && item.enable ? mapItem : null)
        if (item.hasChild && item.childInstance) {
          item.childInstance.setMap(show && item.enable ? mapItem : null)
        }
      }
    })
  })

  /*********************************************************
   * Show or Hide the layer elements into the realtime maps
  *********************************************************/
  const showLayerMainOrChild = useCallback((layerName, show = false, main = true) => {
    mapObjects.forEach((item) => {
      if (item.layerName == layerName) {
        if (main && item.instance) {
          item.instance.setMap(show && item.enable ? mapItem : null)
        } else {
          if (item.hasChild && item.childInstance) {
            item.childInstance.setMap(show && item.enable ? mapItem : null)
          }
        }
      }
    })
  })

  /*********************************************************
   * Remove the layer object
   *********************************************************/
  const removeLayer = useCallback((layerName) => {
    for (let i = (mapObjects.length - 1); i >= 0; i--) {
      let mapObject = mapObjects[i];
      if (mapObject.layerName == layerName) {
        try {
          mapObject.instance.setMap(null);
          mapObject.instance = null;
          if (mapObject.childInstance) {
            mapObject.childInstance.setMap(null);
            mapObject.childInstance = null;
          }
        }
        catch (err) {
          //ExceptionManager(err, 'Map/providers/googleProvider', 'removeLayer()');
        }
        mapObjects.splice(i, 1);
      }
    }
  })

  /******************************************************
   * Validate is layer is KML element
   ******************************************************/
  const validateTypeLayerKml = (item) => {
    if (item.isMarker) {
      return TypeLayer.KmlMarker
    }
    return item.type == "geofence" ? TypeLayer.KmlGeofence : TypeLayer.KmlLandmark
  }

  /******************************************************
   * Add the kml objects into the realtime maps
   ******************************************************/
  const addKmlObject = useCallback((kmlListParam) => {
    //remove the old items into the map
    if (kmlList && kmlList.length > 0) {
      kmlList.forEach((item) => {
        const typeLayer = validateTypeLayerKml(item)
        showKmlObject(item.id, false, '', typeLayer)
      })
    }

    kmlList = [...kmlListParam]
    if (mapItem) {
      showsKmlObjectsByZoom(mapItem.getZoom());
    }
  })

  /******************************************************
   * Show the street view function
   ******************************************************/
  const showStreetView = useCallback((container, latLng, callback) => {
    if (container && latLng && latLng.lat && latLng.lng) {
      let position;
      if (typeof latLng.lat === 'function' && typeof latLng.lng === 'function') {
        position = latLng;
      } else {
        position = new google.maps.LatLng(parseFloat(latLng.lat), parseFloat(latLng.lng)); // eslint-disable-line no-undef
      }

      container.innerHTML = "";

      const streetViewService = new google.maps.StreetViewService(); // eslint-disable-line no-undef
      const STREETVIEW_MAX_DISTANCE = 200;
      streetViewService.getPanorama({ location: position, radius: STREETVIEW_MAX_DISTANCE }, function (data, status) {
        if (status === google.maps.StreetViewStatus.OK) { // eslint-disable-line no-undef
          const location = data?.location?.latLng;
          new google.maps.StreetViewPanorama( // eslint-disable-line no-undef
            container,
            {
              position: location,
              pov: {
                heading: 0,
                pitch: 0,
              },
            }
          );
        } else {
          if (callback) callback(google.maps.StreetViewStatus, status); // eslint-disable-line no-undef
        }
      });
    }
  });

  /******************************************************
   * Restart elements on map
   ******************************************************/
  const restartMap = () => {
    removeAll();
    removeMapClusterListener();
    removeMapBoundsChangedListener();
    removeMapClickRouteListener();
    mapItem = null;
    customOverlay = null;
    kmlList = [];
    instance = null
    realStateInfoWindow = false;
    mapClickEvent = null;
    mapClusterEvent = null;
    mapBoundsChangedEvent = null;
    onCallBackZoom = null;

    if(timerRefreshWeather) clearInterval(timerRefreshWeather);
    timerRefreshWeather = null;

    hideTraffic();
    hideWeather();
  }

  /******************************************************
   * Start the map load into initMap
   ******************************************************/
  const initMap = () => {

    restartMap();

    const styles = {
      default: [],
      show_poi: [
        {
          featureType: "poi.business",
          stylers: [{ visibility: "on" }],
        },
        {
          featureType: "transit",
          elementType: "labels.icon",
          stylers: [{ visibility: "on" }],
        },
      ],
    };

    const mapItemElement = new google.maps.Map(divMap.current, { // eslint-disable-line no-undef
      center: { lat: 36.046405, lng: -77.0345380 },
      zoom: 4,
      minZoom: 3,
      mapTypeControl: false,
      streetViewControl: (!props.showEtaLink && !props.generatePrint) || false,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM // eslint-disable-line no-undef
      },
      controlSize: 40,
      zoomControl: false, //custom this control initZoomControl()
      mapTypeId: google.maps.MapTypeId.ROADMAP, // eslint-disable-line no-undef
      rotateControl: false,
      scaleControl: true,
      fullscreenControl: (!props.showEtaLink && !props.generatePrint) || false,
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM // eslint-disable-line no-undef
      },
      panControl: false,
      //gestureHandling: 'cooperative',
      //draggableCursor: 'pointer'

    });

    //To Enabling and Disabling 45° Imagery / Globe View on google maps
    //https://developers.google.com/maps/documentation/javascript/maptypes#Enabling45DegreeImagery
    mapItemElement.setTilt(0);

    mapItemElement.setOptions({ styles: styles['show_poi'] });

    //setMapItem(mapItemElement)
    mapItem = mapItemElement;

    //Add the input search button   
    initButtonOnMap("input_SearchMap", google.maps.ControlPosition.LEFT_TOP, mapItem); // eslint-disable-line no-undef

    if (!props.generatePrint && !props.isMobile) {
      //Add zoom buttons
      initZoomControl(mapItem); //comment this button
    }

    //Add the button to center vehicles
    initButtonOnMap("center_vehicles", google.maps.ControlPosition.RIGHT_BOTTOM, mapItem); // eslint-disable-line no-undef

    //Add layer button
    initButtonOnMap("layer-idcontrol", google.maps.ControlPosition.RIGHT_BOTTOM, mapItem); // eslint-disable-line no-undef 

    //Add the Map Settings
    initButtonOnMap("mapSettings-idcontrol", google.maps.ControlPosition.RIGHT_BOTTOM, mapItem); // eslint-disable-line no-undef

    //Add the legend button
    initButtonOnMap("legends-idcontrol", google.maps.ControlPosition.LEFT_TOP, mapItem); // eslint-disable-line no-undef

    //Add traffic button
    initButtonOnMap("traffic-idcontrol", google.maps.ControlPosition.LEFT_TOP, mapItem); // eslint-disable-line no-undef

    //Add weather button
    initButtonOnMap("weather-idcontrol", google.maps.ControlPosition.LEFT_TOP, mapItem); // eslint-disable-line no-undef

    //Add the speed override list
    initButtonOnMap("speed-override-idcontrol", google.maps.ControlPosition.LEFT_TOP, mapItem); // eslint-disable-line no-undef

    //Add the speed override list
    initButtonOnMap("route-info-idcontrol", google.maps.ControlPosition.LEFT_TOP, mapItem); // eslint-disable-line no-undef

    //Add the pagination control
    initButtonOnMap("input_Pagination", google.maps.ControlPosition.BOTTOM_CENTER, mapItem); // eslint-disable-line no-undef

    //Add the pagination control
    initButtonOnMap("draw_LayerMap", google.maps.ControlPosition.RIGHT_BOTTOM, mapItem); // eslint-disable-line no-undef

    //Add button to draw polygon
    initButtonOnMap("draw-idcontrol", google.maps.ControlPosition.RIGHT_BOTTOM, mapItem); // eslint-disable-line no-undef

    //Add the pannel eta Link in top
    initButtonOnMap("div_etaLink", google.maps.ControlPosition.LEFT_TOP, mapItem); // eslint-disable-line no-undef
    //is requered in bot enable this replace for this line
    //initButtonOnMap("div_etaLink", props.isMobile ? google.maps.ControlPosition.BOTTOM_LEFT : google.maps.ControlPosition.LEFT_TOP, mapItem); // eslint-disable-line no-undef

    //initWeatherLayer(mapItem);

    scrollWeelZoom(mapItem);

    //start the event for MapBounds
    addMapBoundsChangedListener()
    console.log('google map versión', google.maps.version)


    return mapItem;
  }


  /*********************************************************
  * Call the save preferences and restart the timer
  *********************************************************/
  const onEndBoundsChanged = () => {
    clearInterval(timerLastEventCount)
    timerLastEventCount = null

    //end refresh map for weather
    if(timerRefreshWeather) clearInterval(timerRefreshWeather);
    timerRefreshWeather = null;

    const bounds = mapItem.getBounds();

    //Agregar el clima
    if (isWeatherEnabled && weatherPermision) {
      //vars to enable weather
      showWeather();
      onEnableWeatherBounds = true;

      //every 6 mins the weather is updated
      timerRefreshWeather = setInterval(() => { 
        showWeather() 
      }, 360000)
    }else if(isWeatherEnabled && !weatherPermision){
      hideWeather()
    }

    if (bounds && bounds.getSouthWest() && bounds.getNorthEast()) {
      lat1 = bounds.getSouthWest().lat(), lat2 = bounds.getNorthEast().lat();
      lng1 = bounds.getSouthWest().lng(), lng2 = bounds.getNorthEast().lng();
    }

    LogManagerConsole("Map", "onEndBoundsChanged")
    props.onBoundsChanged && props.onBoundsChanged(props.dataUnits, bounds)

    let boundsChanged = getObject(TypeObjectMap.Settings, 'noOverlapSettings')

    if (!boundsChanged) return;

    //validate is cluster off
    const objectCluster = getObject(TypeObjectMap.MarkerCluster, 'unitCluster');
    if (objectCluster && objectCluster.instance) {
      return
    }

    const objectNoOverlapSettings = getObject(TypeObjectMap.Settings, 'noOverlapSettings');
    if (objectNoOverlapSettings && !objectNoOverlapSettings.enable) {
      return
    }

    const objectShowLabelSettings = getObject(TypeObjectMap.Settings, 'unitLabelSettings');
    if (objectShowLabelSettings && !objectShowLabelSettings.enable) {
      return
    }

    if (mapItem.getZoom() > zoomLabelsPositions) {
      //check this line to create areas.
      //activeLabelsOnMap();
      createNewPositionsToLabels();
    } else {
      valueZoomNoOverlap = mapItem.getZoom(); //update value from value zoom
      restarLabelsToDefault()
    }
  }

  /*********************************************************
  * Restart the labels no overlap
  *********************************************************/
  const restarLabelsToDefault = () => {
    //all to normal value
    const getLabelLines = mapObjects.filter((element) => String(element?.id || "").includes('temporalLabel_poly_') == true);

    const getCircles = mapObjects.filter((element) => String(element?.id || "").includes('circleCluster_') == true);
    getCircles.forEach((item) => {
      removeMapObject(item.id, item.type);
    })

    if (getLabelLines && getLabelLines.length > 0) {
      getLabelLines.forEach((item) => {
        removeMapObject(item.id, item.type);
      })

      const getMapLabels = mapObjects.filter((element) => String(element?.id || "").includes('label_') && element.labelPosition && element.instance);
      getMapLabels.forEach((element) => {
        //update defaul position label
        element.position = element.startPosition;
        element.labelPosition = false;
        element.labelGroup = null;
        element.labelPositionSpace = null;
        element.labelGroupArea = null;
        element.labelGroupIdMain = null;

        const unit = getObject(TypeObjectMap.Marker, element.id.toString().replace('label_', ''))
        unit.labelPosition = false;
        unit.labelGroup = null;
        unit.labelPositionSpace = null;
        unit.labelGroupArea = null;
        unit.labelGroupIdMain = null;

        const googlePosition = new google.maps.LatLng(element.position.lat, element.position.lng);
        element.instance.onMove(googlePosition);
      })
      addLabelMarkers(TypeLayer.UnitLabel, true, false)
    }
  }

  /* eslint-disable no-unused-vars */
  /******************************************************
   * Load the init Zoom Buttons into the UI
   ******************************************************/
  const initZoomControl = (map) => {

    document.getElementById("zoomIn-idcontrol").onclick = function () {
      map.setZoom(map.getZoom() + 1);
    };

    document.getElementById("zoomOut-idcontrol").onclick = function () {
      map.setZoom(map.getZoom() - 1);
    };

    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push( // eslint-disable-line no-undef
      document.querySelector(".zoomButtonsIn")
    );

    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push( // eslint-disable-line no-undef
      document.querySelector(".zoomButtonsOut")
    );
  }

  /******************************************************
   * Hide or show kml files from mouse wheel zooming 
  ******************************************************/
  const scrollWeelZoom = (map) => {
    //zoom 0 - 22
    map.addListener('zoom_changed', function () {  // eslint-disable-line no-undef 
      const zoomMap = map.getZoom();
      if (onCallBackZoom)
        onCallBackZoom(zoomMap)
      //showsKmlObjectsByZoom(zoomMap);

      //show landmarks icons
      showLandmarksGeofenceByZoom('landmarkSettings', zoomMap);
      showLandmarksGeofenceByZoom('geofenceSettings', zoomMap);

      
      
    });
  }

  /******************************************************
   * Show the elements to label actives on map
  ******************************************************/
  const activeLabelsOnMap = () => {
    const objectNoOverlapSettings = getObject(TypeObjectMap.Settings, 'noOverlapSettings');
    if (objectNoOverlapSettings && objectNoOverlapSettings.enable) {

      //removeMapObjects(TypeObjectMap.Polyline);
      //temporalLabel_poly_
      const mapObjectsLines = mapObjects.filter((item) => item.type == TypeObjectMap.Polyline && item.layerName == TypeLayer.UnitLabel)
      mapObjectsLines.forEach((mapObjectToDelete) => {
        removeMapObject(mapObjectToDelete.id, TypeObjectMap.Polyline);
      })

      const objectUnitCluster = getObject(TypeObjectMap.Settings, 'unitCluster');
      if (objectUnitCluster && objectUnitCluster.enable) {
        const mapObjectsLabels = mapObjects.filter((item) => item.type == TypeObjectMap.Label && item.layerName == TypeLayer.UnitLabel)
        mapObjectsLabels.forEach((mapObjectToDelete) => {
          removeMapObject(mapObjectToDelete.id, TypeObjectMap.Label);
        })
      }
    }
  }

  /******************************************************
   * Zoom out 
  ******************************************************/
  const setZoomOut = (numberOfPoints = 1) => {
    if (mapItem)
      mapItem.setZoom(mapItem.getZoom() - numberOfPoints);
  }

  /******************************************************
   * Zoom out 
  ******************************************************/
  const setZoom = (defaulZoom = 4) => {
      mapItem.setZoom(defaulZoom);
  }

  /******************************************************
   * Return the default value on zoom on map
  ******************************************************/
  const getZoom = () => {
    return mapItem.getZoom();
  }

  /******************************************************
   * Hide or show kml files from mouse wheel zooming 
  ******************************************************/
  const showsKmlObjectsByZoom = (zoomMap) => {
    if (kmlList && kmlList.length > 0) {
      kmlList.forEach(element => {
        const typeLayer = validateTypeLayerKml(element)
        showKmlObject(
          element.id,
          (zoomMap >= element.zoom),
          element.path,
          typeLayer
        )
      });
    }
  }

  /******************************************************
   * Load Button or options into the realtime maps
   ******************************************************/
  const initButtonOnMap = (idControl, position, map) => {
    if (map) {
      map.controls[position].push(
        document.getElementById(idControl)
      );
    }
  }

  /* eslint-enable no-unused-vars */

  /******************************************************
   * Create a new kml object into the realtime maps
   ******************************************************/
  const showKmlObject = (key, show, url, layerType) => {
    if (show) {
      const element = getObject(TypeObjectMap.KmlFile, key)

      if (!element) {
        const mapObject = {
          id: key,
          url: url,
          layerName: layerType,
          type: TypeObjectMap.KmlFile
        }
        addMapObject(mapObject)
      }
    } else {
      removeMapObject(key, TypeObjectMap.KmlFile)
    }
  }

  /******************************************************
   * Show the traffic elements into the realtime maps
   ******************************************************/
  const showTraffic = () => {
    if (traffic == null) {
      const trafficLayer = new google.maps.TrafficLayer(); // eslint-disable-line no-undef
      if (mapItem) {
        trafficLayer.setMap(mapItem);
        setTraffic(trafficLayer);
      } else {
        let trafficTimer = setTimeout(() => {
          trafficLayer.setMap(mapItem);
          setTraffic(trafficLayer);
          trafficTimer = null;
        }, 1500);
      }
    }
  }

  const hideTraffic = () => {
    traffic?.setMap(null);
    setTraffic(null);
  }

  const getRoundedDate = (minutes, d = new Date()) => {

    let ms = 1000 * 60 * minutes; // convert minutes to ms
    let roundedDate = new Date(Math.floor(d.getTime() / ms) * ms);

    return roundedDate
  }

  const showWeather = (paramLayers = null) => {
    mapItem?.overlayMapTypes.removeAt(0);
    mapItem?.overlayMapTypes.push(null);

    if(paramLayers != null && weatherLayersOnShow != paramLayers) weatherLayersOnShow = paramLayers;

    let radar = new google.maps.ImageMapType({
      getTileUrl: function (coord, zoom) {
        //let layers = 'satellite,radar';
        let layers = paramLayers != null ? paramLayers : weatherLayersOnShow;

        //let returnUri = [`${endpoints.MAP_WEATHER_URL}/maps/2.0/radar/`, zoom, '/', coord.x, '/', coord.y, `?appid=${endpoints.OPEN_WEATHER_KEY}`, `&tm=${moment(getRoundedDate(10)).add(-20, 'minutes').unix()}`].join('');
        
        if(!weatherPermision) return

        if(layers == "") return

        let returnUri = endpoints.MAP_WEATHER_URL + "/" + endpoints.MAP_WEATHER_ID + "_" + endpoints.OPEN_WEATHER_KEY + "/"+layers+"/" + zoom + "/" + coord.x + "/" + coord.y +'/current.png';

        if(!onEnableWeatherBounds) return


        /* if (zoom == 7) {
          console.log("coords = 7", `${coord.x}_${coord.y}`)
          let values = sessionStorage.getItem("weaterLayer")
          values = values ? JSON.parse(values) : []
          values.push({ key: `${coord.x}_${coord.y}`, value: returnUri })
          sessionStorage.setItem("weaterLayer", JSON.stringify(values))
        }
        else if (zoom < 7) {
          sessionStorage.setItem("weaterLayer", "[]")
        }

        if (zoom > 7) {
          console.log("coords <7", `${coord.x}_${coord.y}`)
          let values = sessionStorage.getItem("weaterLayer")
          values = values ? JSON.parse(values) : []
          let lastZoom7 = values.find(x => x.key == `${coord.x}_${coord.y}`)
          return lastZoom7?.value
        } */

        

        if (zoom >= 4 && zoom <= 13) {
          return returnUri   
        }
        //return returnUri

      },
      tileSize: new google.maps.Size(256, 256),
      maxZoom: 14,
      minZoom: 4
    });

    if (mapItem) {
      mapItem?.setOptions({ minZoom: 0, maxZoom: 22 });
      mapItem?.overlayMapTypes.setAt(0, radar);
      isWeatherEnabled = true;
    } else {
      let weatherTimer = setTimeout(() => {
        mapItem?.setOptions({ minZoom: 0, maxZoom: 22 });
        mapItem?.overlayMapTypes.setAt(0, radar);
        isWeatherEnabled = true;
        weatherTimer = null;
      }, 1500);
    }
  }

  // const showWeather = () => {
  //   mapItem?.overlayMapTypes.removeAt(0);
  //   mapItem?.overlayMapTypes.push(null);

  //   let radar = new google.maps.ImageMapType({
  //     getTileUrl: function (coord, zoom) {
  //       //return [`${endpoints.MAP_WEATHER_URL}/maps/2.0/weather/CL/`, zoom, '/', coord.x, '/', coord.y, `?appid=${endpoints.OPEN_WEATHER_KEY}`, '&palette=0:d3d2d2;10:c8c7c7;20:bdbcbc;30:b1b0b0;40:a6a5a5;50:9b9a9a;60:908f8f;70:858383;80:7a7878;90:6f6d6d;100:636262;200:585757', '&opacity=0.4'].join('');
  //       //return [`${endpoints.MAP_WEATHER_URL}/maps/2.0/weather/PR0/`, zoom, '/', coord.x, '/', coord.y, `?appid=${endpoints.OPEN_WEATHER_KEY}`,'&opacity=0.4'].join('');
  //       //https://maps.openweathermap.org/maps/2.0/radar/{z}/{x}/{y}?appid={API key}&tm={date}

  //       return [`${endpoints.MAP_WEATHER_URL}/maps/2.0/weather/1h/PR0/`, zoom, '/', coord.x, '/', coord.y, `?appid=${endpoints.OPEN_WEATHER_KEY}`, '&opacity=0.4'].join('');
  //     },
  //     tileSize: new google.maps.Size(256, 256),
  //     maxZoom: 9,
  //     minZoom: 0
  //   });

  //   if (mapItem) {
  //     mapItem?.setOptions({ minZoom: 4, maxZoom: 13 });
  //     mapItem?.overlayMapTypes.setAt(0, radar);
  //     isWeatherEnabled = true;
  //   } else {
  //     let weatherTimer = setTimeout(() => {
  //       mapItem?.setOptions({ minZoom: 4, maxZoom: 13 });
  //       mapItem?.overlayMapTypes.setAt(0, radar);
  //       isWeatherEnabled = true;
  //       weatherTimer = null;
  //     }, 1500);
  //   }
  // }

  const hideWeather = () => {
    mapItem?.setOptions({ minZoom: 0, maxZoom: 22 });
    mapItem?.overlayMapTypes.removeAt(0);
    isWeatherEnabled = false;
  }

  /*******************************************************
   * Validate eventName to get color from the unit
   * @param {Object JSON} item 
   *******************************************************/
  const validateEventColor = (item) => {
    let eventName = '';
    if (item['eventName']) {
      eventName = item['eventName'].toString();
    }

    if (item['event'] && eventName == '') {
      eventName = item['event'].toString();
    }

    return getEventColor(eventName);
  }

  /******************************************************
   * Add the marker into google maps to google.maps.Marker
   * @param {JSON} mapObject 
   ******************************************************/
  const addMarker = (mapObject) => {
    if (!validateNewObject(mapObject)) {
      return
    }
    let color = ''
    if (mapObject.color === '#FFFFFF' || !mapObject.color) {
      color = validateEventColor(mapObject)
      mapObject.color = color;
    } else {
      color = mapObject.color
    }

    let iconMarker = '', iconMarkerMagnified = '';
    switch (mapObject.layerName) {
      case TypeLayer.Units:
        iconMarker = getIconAttributes(color, mapObject.heading, null, null, mapObject)
        break;
      case TypeLayer.VehicleTrailLayer:
        iconMarker = getIconAttributes(color, mapObject.heading)
        break;
      case TypeLayer.VechileTrails:
        let url = null;
        let imageStyle = {};
        if (mapObject?.eventCode && mapObject?.eventCode?.includes("CameraEvent")) {
          let event = getVidFleetEvent(mapObject?.eventCode);
          url = event.icon;
          imageStyle = {
            anchor: new google.maps.Point(15, 10)  // eslint-disable-line no-undef
          };
        }
        iconMarker = getIconAttributes(color, mapObject.heading, url, imageStyle, mapObject)
        break;

      case TypeLayer.PolygonCreate:
        iconMarker = getIconForPolygonCreate(color);
        break;

      case TypeLayer.SquareCreate:
      case TypeLayer.CircleCreate:
        if (mapObject?.url)
          iconMarker = getIconAttributes(
            null,
            90,
            null,
            { //styleImage
              scale: 1,
              size: new google.maps.Size(10, 10),
              anchor: new google.maps.Point(10, 10),
              fillColor: '#000000',
              fillOpacity: 1,
              strokeColor: '#000000', //'#6f6f6f',
              sstrokeWeight: 0,
              rotation: 45,
            },
            null,
            'M21 11L21 3L13 3L16.29 6.29L6.29 16.29L3 13L3 21L11 21L7.71 17.71L17.71 7.71z'
          )
        break;

      case TypeLayer.FSMRoute:
            
            if(!mapObject?.isRouteTracker){
              iconMarker = createIconMarkerForRoute(mapObject)
            }else{
              //show icons for routeTracker
              iconMarker = createIconMarkerForRouteTracker(mapObject)
            }


            iconMarkerMagnified = {
              ...iconMarker,
              scaledSize: new google.maps.Size(48, 48) // Tamaño del icono aumentado
            };

            mapObject.defaultIconIncrease = iconMarkerMagnified;

        break;


    }

    let marker = null;

    if(!iconMarker && mapObject?.icon){
      iconMarker = mapObject?.icon
    }

    mapObject.defaultIcon = iconMarker;
    
    marker = new google.maps.Marker({ // eslint-disable-line no-undef
      id: mapObject.id,
      position: mapObject.position,
      map: mapItem,
      draggable: mapObject.draggable,
      //animation: google.maps.Animation.DROP, // eslint-disable-line no-undef
      icon: iconMarker,
      title: mapObject?.label ? mapObject?.label.toString() : '',
      zIndex: mapObject.zIndex || 10,
      optimized: mapObject?.optimized,
      visible: !mapObject.hideMarker,
      crossOnDrag: typeof mapObject?.crossOnDrag == "boolean" ? mapObject?.crossOnDrag : true
    });

    //Drag and drop event
    if (mapObject.draggable) {
      const dragEvent = marker.addListener('drag', function (event) {
        if (event?.paramMarker?.instance) {
          event.paramMarker.instance.setPosition(event.newPosition)
        } else {
          if (props.onDragMarkerEvent) {
            const findMapObject = mapObjects.find(item => item.id == mapObject.id)
            return props.onDragMarkerEvent(event, findMapObject)
          }
        }
      });

      //add dragsend events to marker for polygon
      if (mapObject?.saveDragEvent) {


        const dragStartEvent = marker.addListener('dragstart', onDragStartSquareEvent);
        const dragEndEvent = marker.addListener('dragend', onDragEndSquareEvent);

        mapObject.dragEndEvent = dragEndEvent
        mapObject.dragEvent = dragEvent
        mapObject.dragStartEvent = dragStartEvent
      }



      marker.addListener('rightclick', function () {
        if (props.onMarkerRightClick) {
          const findMapObject = mapObjects.find(item => item.id == mapObject.id)
          props.onMarkerRightClick(findMapObject)
        }
      });
    } else if (mapObject.layerName != TypeLayer.VehicleTrailLayer && mapObject.layerName != TypeLayer.VechileTrailsTrailHistory && mapObject.layerName != TypeLayer.Pointers) {
      
      marker.addListener('click', () => {
        if (mapObject?.onClick) {
          mapObject?.onClick(mapObject);
        } else if(props?.isEditRoutesModule && mapObject.layerName == TypeLayer.FSMRoute && props?.onRouteMarkerClick){
          const findMapObject = mapObjects.find(item => item.id == mapObject.id)
          props.onRouteMarkerClick({
            mapObject: findMapObject, 
            instance: instance
          })
          //first show the default icon for all markers
          showNormalMarkers()
          //magnified the icon after the selection
          marker.setIcon(iconMarkerMagnified);
          
        } else if ( props.modalPopupEvent) {
          const findMapObject = mapObjects.find(item => item.id == mapObject.id)
          props.modalPopupEvent(findMapObject, null, instance)

          //if vehicle trails markers show the element on table vehicle trail (focus)
          if(mapObject.layerName == TypeLayer.VechileTrails){
            props.onClickMarkerEvent(mapObject.id)
          }

        }
      })
    }

    //show label position
    if ((mapObject.label && mapObject.showLabel) && mapObject.layerName == TypeLayer.Pointers) {
      const infowindow = new google.maps.InfoWindow({
        content: `<div id="content">${mapObject.label ? '<p>' + mapObject.label + '</p>' : ''}
                            <p>${mapObject.position.lat}, ${mapObject.position.lng}</p></div>`
      });

      infowindow.open({
        anchor: marker,
        mapItem,
        shouldFocus: false,
      });

      marker.addListener('click', () => {
        infowindow.open({
          anchor: marker,
          mapItem,
          shouldFocus: false,
        });
      })
    }

    return marker
  }

  /**********************************************************************
   * Show style to normal style markers in map
   * @param {Object} event 
   **********************************************************************/
  const showNormalMarkers = (layer = TypeLayer.FSMRoute) => {
    const findMarkersToUpdateStyle = mapObjects.filter(item => item.type == TypeObjectMap.Marker && item.layerName == layer);
    if(findMarkersToUpdateStyle){
      findMarkersToUpdateStyle.forEach((item) => {
        item.instance.setIcon(item.defaultIcon);
      })
    }
  }

  /**********************************************************************
   * Show style to normal style markers in map
   * @param {Object} event 
   **********************************************************************/
  const setSizeMarkerIcon = (id = '', layer = TypeLayer.FSMRoute, iconSize = EnumIconSize.Default) => {
    if(layer == TypeLayer.FSMRoute){

      const findMarkersToUpdateStyle = mapObjects.find(item => item.type == TypeObjectMap.Marker && item.layerName == layer && item.id == id);
      if(findMarkersToUpdateStyle){
        switch(iconSize){
            case EnumIconSize.Default:
              findMarkersToUpdateStyle.instance.setIcon(findMarkersToUpdateStyle.defaultIcon);
              break;

            case EnumIconSize.Magnified:
              findMarkersToUpdateStyle.instance.setIcon(findMarkersToUpdateStyle.defaultIconIncrease);
              break;
        }
      }

    }else if(layer == TypeLayer.Units){
      //to change the icon when the user selected a unit on map
      const findMarkersToUpdateStyle = mapObjects.find(item => item.type == TypeObjectMap.Marker && item.layerName == layer && item.id == id);
      
      const color = validateEventColor(findMarkersToUpdateStyle)
        if (!findMarkersToUpdateStyle.icon) {
          findMarkersToUpdateStyle.instance.setIcon(getIconAttributes('#' + color, findMarkersToUpdateStyle.heading, null, null, findMarkersToUpdateStyle));
        }else{      
          const iconMarker = getIconAttributes(findMarkersToUpdateStyle.color, 0, findMarkersToUpdateStyle.icon, false)
          findMarkersToUpdateStyle.instance.setIcon(iconMarker)
        }
    }
    
  }


  /**********************************************************************
   * Event asociated to square object, perform the calc to minimun value to square.
   * @param {Object} event 
   **********************************************************************/
  function onDragStartSquareEvent(event) {
    const findSquareObjectToUpdate = mapObjects.find(item => item.type == TypeObjectMap.Square)
    if (findSquareObjectToUpdate) {
      const { north, south, east, west } = findSquareObjectToUpdate.instance.getBounds().toJSON()
      if (north >= 0) {
        squareLatMin = north - minimunSizeSquare
        //squareLatMinSing = '>'
      } else if (north < 0) {
        squareLatMin = north - minimunSizeSquare
        //squareLatMinSing = '>'
      }

      if (west <= 0) {
        squareLngMin = west + minimunSizeSquare
        //squareLngMinSing = '<'
      } else if (west > 0) {
        squareLngMin = west + minimunSizeSquare
        //squareLngMinSing = '<'
      }
    }
  }

  /**********************************************************************
   * Event asociated to square object, update the parameters to draw square.
   * @param {Object} event 
   **********************************************************************/
  function onDragEndSquareEvent(event) {
    const findSquareObjectToUpdate = mapObjects.find(item => item.type == TypeObjectMap.Square)
    if (findSquareObjectToUpdate) {
      findSquareObjectToUpdate.minBoundsRectangle = findSquareObjectToUpdate.instance.getBounds().toJSON()

      if (!findSquareObjectToUpdate.freeRectangle) {
        updateSquareSizeToDragEvents(findSquareObjectToUpdate, event, true)
      }
    }
  }

  const updateSquareSizeToDragEvents = (findSquareObjectToUpdate, event, isDragEnd = false) => {
    //drag and drop the marker              
    const { lat, lng } = event.latLng.toJSON();

    //create square for last position / return distance in kilometers
    let radioSquare = calcDistanceToPoints(findSquareObjectToUpdate.position.lat, findSquareObjectToUpdate.position.lng,
      lat, lng);

    if (findSquareObjectToUpdate.maxSize > 0 && radioSquare > findSquareObjectToUpdate.maxSize) {

      const coordinatesFromCenter = createSquareBoundingboxByMilesDistance(findSquareObjectToUpdate.position.lat,
        findSquareObjectToUpdate.position.lng,
        findSquareObjectToUpdate.maxSize);

      const boundsToSquare = {
        north: coordinatesFromCenter[0].latitude,
        south: coordinatesFromCenter[1].latitude,
        east: coordinatesFromCenter[0].longitude,
        west: coordinatesFromCenter[2].longitude,
      };
      findSquareObjectToUpdate.boundsRectangle = boundsToSquare;

      //update the square size
      findSquareObjectToUpdate.instance.setBounds(boundsToSquare)

      //calc the point to marker
      const newLngOnMarkerDrag = getLngByDistance(findSquareObjectToUpdate.position.lat,
        findSquareObjectToUpdate.position.lng,
        findSquareObjectToUpdate.maxSize)
      const position = { lat: findSquareObjectToUpdate.position.lat, lng: newLngOnMarkerDrag }
      const findMarkerSquareObject = mapObjects.find(item => item.type == TypeObjectMap.Marker &&
        item.layerName == TypeLayer.SquareCreate)
      if (findMarkerSquareObject) {
        findMarkerSquareObject.instance.setPosition(position)
        findMarkerSquareObject.position = findMarkerSquareObject
      }

      radioSquare = findSquareObjectToUpdate.maxSize //convert to metters
      findSquareObjectToUpdate && findSquareObjectToUpdate?.onUpdateList && findSquareObjectToUpdate?.onUpdateList(findSquareObjectToUpdate.maxSize)
      return

    }
    //radioSquare value is in metters
    const coordinatesFromCenter = createSquareBoundingboxByMilesDistance(findSquareObjectToUpdate.position.lat,
      findSquareObjectToUpdate.position.lng,
      radioSquare);

    const boundsToSquare = {
      north: coordinatesFromCenter[0].latitude,
      south: coordinatesFromCenter[1].latitude,
      east: coordinatesFromCenter[0].longitude,
      west: coordinatesFromCenter[2].longitude,
    };
    findSquareObjectToUpdate.boundsRectangle = boundsToSquare;

    //update the square size
    findSquareObjectToUpdate.instance.setBounds(boundsToSquare)

    const coordinates = convertBoundsToCoordinates(boundsToSquare)
    findSquareObjectToUpdate.coordinates = coordinates;
    findSquareObjectToUpdate.position = { lat: findSquareObjectToUpdate.position.lat, lng: findSquareObjectToUpdate.position.lng }

    //const position = { lat: boundsToSquare.south, lng: boundsToSquare.east }
    if (isDragEnd) {
      const findMarkerSquareObject = mapObjects.find(item => item.type == TypeObjectMap.Marker &&
        item.layerName == TypeLayer.SquareCreate)
      //update marker position into objects
      if (findMarkerSquareObject) {
        //calc the point to marker
        const newLngOnMarkerDrag = getLngByDistance(findSquareObjectToUpdate.position.lat,
          findSquareObjectToUpdate.position.lng,
          radioSquare) //in metters
        const position = { lat: findSquareObjectToUpdate.position.lat, lng: newLngOnMarkerDrag }
        findMarkerSquareObject.instance.setPosition(position)
        findMarkerSquareObject.position = findMarkerSquareObject
      }

      //center the element on map
      centerByFitBounds(coordinates);
    }

    //if have event to update list
    findSquareObjectToUpdate && findSquareObjectToUpdate?.onUpdateList && findSquareObjectToUpdate?.onUpdateList(radioSquare)

  }

  /******************************************************
   * Update the size for the square and update the marker position
   * @param {double} diagonally on the square on metters
   ******************************************************/
  const setUpdateSquareDiagonally = (diagonallyOnMetters) => {

    //find the square object in map
    const findSquareObjectToUpdate = mapObjects.find(item => item.type == TypeObjectMap.Square)
    if (!findSquareObjectToUpdate) return;
    const squareCoordinates = createSquareBoundingboxByMilesDistance(findSquareObjectToUpdate.position.lat, findSquareObjectToUpdate.position.lng, diagonallyOnMetters); //convert metters to feet
    const boundsToSquare = {
      north: squareCoordinates[0].latitude,
      south: squareCoordinates[1].latitude,
      east: squareCoordinates[0].longitude,
      west: squareCoordinates[2].longitude
    };

    findSquareObjectToUpdate.boundsRectangle = boundsToSquare;

    //update the square size
    findSquareObjectToUpdate.instance.setBounds(boundsToSquare);
    const coordinates = convertBoundsToCoordinates(boundsToSquare);
    findSquareObjectToUpdate.coordinates = coordinates;

    //const position = { lat: boundsToSquare.south, lng: boundsToSquare.east };
    const findMarkerSquareObject = mapObjects.find(item => item.type == TypeObjectMap.Marker && item.layerName == TypeLayer.SquareCreate);
    //update marker position into objects
    if (findMarkerSquareObject) {
      const centerSquare = getCenterFromBounds(boundsToSquare)
      const position = { lat: centerSquare.lat, lng: boundsToSquare.east }
      findMarkerSquareObject.instance.setPosition(position)
      findMarkerSquareObject.position = position
    }

    centerByFitBounds(coordinates);

  }

  /******************************************************
   * Add the marker into google maps to google.maps.Marker
   * @param {JSON} mapObject 
   ******************************************************/
  const addKml = (mapObject) => {

    const kmlLayer = new google.maps.KmlLayer(mapObject.url, { // eslint-disable-line no-undef
      suppressInfoWindows: true,
      preserveViewport: true,
      map: mapItem,
      pane: "markerLayer",
      zIndex: mapObject.layerName == TypeLayer.KmlMarker ? 1 : 0,
      //clickable: false
    });

    //Add the event click on the kmls objects.
    kmlLayer.addListener('click', function (event) {
      const { featureData } = event
      if (featureData && featureData.name && props.modalPopupEvent && !realStateInfoWindow) {
        const obj = {
          id: featureData.name,
          position: event.latLng,
          icon: {
            rotation: 0
          },
          layerName: mapObject.layerName == TypeLayer.KmlGeofence ? TypeLayer.Geofence : TypeLayer.Landmark
        }
        props.modalPopupEvent(obj, obj);
      }
    });

    return kmlLayer;
  }

  /******************************************************
   * Create the new object route into the realtime map
  ******************************************************/
  const addRoute = () => {
    try {
      const panelContainer = document.getElementById('directionsPanel');
      const directionsRenderer = new google.maps.DirectionsRenderer(); // eslint-disable-line no-undef
      directionsRenderer.setMap(mapItem);
      if (panelContainer) {
        panelContainer.innerHTML = "";
        directionsRenderer.setPanel(panelContainer);
      }

      return directionsRenderer;
    } catch (ex) {
      // console.log('load the routes', ex);
    }
    return null
  }

  /******************************************************
   * Show the modal popup at the marker into the realtime maps
   * @param {mapObject} marker object
   * @param {string} content react component
   ******************************************************/
  const showMarkerModal = (marker, mapObject, content, position, currentZoom) => {
    const newMapObject = { id: mapObject?.id?.toString(), layerName: mapObject?.layerName };
    const newCurrentMarkerModal = { id: currentMarkerModal?.id?.toString(), layerName: currentMarkerModal?.layerName };

    if (!_.isEqual(newMapObject, newCurrentMarkerModal) || !openInfoWindow) {
      setCurrentMarkerModal(mapObject);

      if (customOverlay) {
        customOverlay.setMap(null);
        customOverlay = null;
        setOpenInfoWindow(false);
        realStateInfoWindow = false;
        mapItem && mapItem.setOptions({ draggable: true });
      }

      customOverlay = new Overlay(marker, mapObject?.id || 0, () => {
        setOpenInfoWindow(true);
        realStateInfoWindow = true;
      });

      customOverlay.setMap(mapItem);
      setInfoBubbleContent(content);

      if (position) centerPointOnMap(position?.lat, position?.lng, currentZoom, -190);
    }
  }

  /******************************************************
   * Update the units Modal into the view
   ******************************************************/
  const updateModalPosition = (element) => {
    if (customOverlay && mapItem) {
      const googlePosition = new google.maps.LatLng(element.position.lat, element.position.lng)
      customOverlay.onMove(googlePosition);
    }
  }

  const updateModalPositionTrails = (element) => {
    if (customOverlay && mapItem && element?.id == customOverlay?.id) {
      const googlePosition = new google.maps.LatLng(element.position.lat, element.position.lng)
      customOverlay.onMove(googlePosition);
    }
  }

  /******************************************************
   * Add the label overflow objects
   ******************************************************/
  const addLabel = useCallback((mapObject) => {
    if (!mapItem)
      return
    if (!validateNewObject(mapObject)) {
      return
    }

    let googlePosition = null;

    googlePosition = new google.maps.LatLng(mapObject.position.lat, mapObject.position.lng) // eslint-disable-line no-undef

    const bubbleAnchor = document.createElement("div");
    bubbleAnchor.innerHTML = mapObject?.label;
    bubbleAnchor.setAttribute("id", `label_${mapObject.id}`);


    const customOverlayLabels = new MarkerOverlay(googlePosition, bubbleAnchor);

    customOverlayLabels.setMap(mapItem);
    return customOverlayLabels;
  });

  /******************************************************
   * Get the new position from the labels for Clusters
   ******************************************************/
  const createNewPositionsToLabelsCluster = (listUnitsInCluster = [], centerClusterPoss = null) => {

    if (!listUnitsInCluster || listUnitsInCluster && listUnitsInCluster.length <= 1 || !centerClusterPoss) return

    let startZoneLat = 0, startZoneLng = 0;
    let zoneLat = 0.000009, zoneLng = 0.00007, counterPos = 0;
    const zoomValue = mapItem.getZoom();

    let arrayLabelsLatLng = mapObjects.filter((item) =>
      item.type == TypeObjectMap.Label
      && item.layerName == TypeLayer.UnitLabel)
      .map((labelElement) => ({
        label: labelElement.label,
        labelGroup: labelElement.labelGroup,
        lat: labelElement.position.lat,
        lng: labelElement.position.lng,
        labelPositionSpace: labelElement.labelPositionSpace
      }));

    let percentZoomLat = 0, percentZoomLng = 0;
    if (zoomValue >= 3 && zoomValue < 22) {
      const { latZoom, lngZoom } = valuesToZoom(zoomValue)
      percentZoomLat = latZoom
      percentZoomLng = lngZoom

      zoneLat = zoneLat + percentZoomLat
      zoneLng = zoneLng + percentZoomLng
    }

    //get the first element at the list
    const mapObject = listUnitsInCluster[0];
    if (!mapObject) return

    let { newPositionLabel, labelPositionSpace } = getNewPositionLabel(arrayLabelsLatLng, centerClusterPoss, { position: centerClusterPoss }, counterPos, zoneLat, zoneLng, startZoneLat, startZoneLng, true);

    let label = '';
    const valueLabelGroup = listUnitsInCluster.map((unit) => unit.id)

    listUnitsInCluster.forEach((unit, index) => {
      unit.labelGroup = valueLabelGroup;
      unit.labelPositionSpace = labelPositionSpace;

      if (index <= 4) {
        label = `${label}${unit.label} </br>`;
      }

      const showLabel = unit.id == mapObject.id;

      if (!showLabel) {
        removeMapObject(`temporalLabel_poly_${unit.id}`, TypeObjectMap.Polyline);
      }

      //show all elements            
      const objectOverlay = getObject(TypeObjectMap.Label, `label_${unit.id}`);
      if (objectOverlay) {
        objectOverlay.labelGroup = valueLabelGroup;
        objectOverlay.labelPosition = true;
        objectOverlay.labelPositionSpace = labelPositionSpace;
        objectOverlay.instance.onDisplayObject(showLabel, mapItem);
      }
      unit.labelPosition = true;
    });

    const objectLabelCluster = new MapObject().buildLabel({
      id: `label_${mapObject.id}`,
      label: label,
      position: centerClusterPoss,
      layerName: TypeLayer.UnitLabel
    })
    //add the object into the map
    addMapObject(objectLabelCluster);

    addMapObject(new MapObject().buildPolyLine({
      id: `temporalLabel_poly_${mapObject.id}`,
      coordinates: [centerClusterPoss, newPositionLabel],
      layerName: TypeLayer.UnitLabel,
      heading: 0,
      color: '#B0B0B0'
    }), true, true);

    if (listUnitsInCluster.length > 5) {
      updateMarkerLabel(mapObject, '', newPositionLabel, true, label);
    }

  }

  /******************************************************
   * Get the new position from the labels
   ******************************************************/
  const createNewPositionsToLabels = (restart = false) => {

    if (!mapObjects || (mapObjects && mapObjects.length <= 0)) return;

    if (mapItem.getZoom() > zoomLabelsPositions) {

      //Restart elements out the range
      let getLabelElements = mapObjects.filter((item) => String(item?.id || "").includes('label_')
        && item.instance
        && item.labelPosition
        && item.position
        && !(item.position.lat >= lat1 && item.position.lat <= lat2
          && item.position.lng >= lng1 && item.position.lng <= lng2));

      getLabelElements.forEach((element) => {
        //update defaul position label
        element.position = element.startPosition;
        element.labelPosition = false;
        element.labelGroup = null;
        element.labelPositionSpace = null;

        //Remove circle and lines to modals
        const idObjectMap = element.id.replace('label_', '')
        removeMapObject(`temporalLabel_poly_${idObjectMap}`, TypeObjectMap.Polyline);
        removeMapObject(`circleCluster_${idObjectMap}`, TypeObjectMap.Circle);

        //update default unit
        const objectmarker = getObject(TypeObjectMap.Marker, idObjectMap);
        objectmarker.labelPosition = false;
        objectmarker.labelGroup = null;
        objectmarker.labelPositionSpace = null;

        const googlePosition = new google.maps.LatLng(element.position.lat, element.position.lng)
        element.instance.onMove(googlePosition);
      })


      //get all vehicles into the range
      let getMapMarkers = mapObjects.filter((item) => item.type == TypeObjectMap.Marker
        && (item.instance && item.instance.getMap())
        && item.layerName == TypeLayer.Units
        && item.position
        && item.position.lat >= lat1 && item.position.lat <= lat2
        && item.position.lng >= lng1 && item.position.lng <= lng2
      );

      //update elements to no labels
      if (valueZoomNoOverlap != mapItem.getZoom() || restart) {
        //restart elements to reorganize by zoom
        getMapMarkers = getMapMarkers.map((mapObject) => {
          mapObject.labelPosition = false;
          mapObject.labelGroup = null;
          mapObject.labelPositionSpace = null;
          mapObject.labelGroupArea = null;
          mapObject.labelGroupIdMain = null;
          const objectOverlayObject = getObject(TypeObjectMap.Label, `label_${mapObject.id}`);
          if (objectOverlayObject) {
            objectOverlayObject.labelPosition = false;
            objectOverlayObject.labelGroup = null;
            objectOverlayObject.labelPositionSpace = null;
            objectOverlayObject.labelGroupArea = null;
            objectOverlayObject.labelGroupIdMain = null;
          }

          removeMapObject(`temporalLabel_poly_${mapObject.id}`, TypeObjectMap.Polyline);
          removeMapObject(`circleCluster_${mapObject.id}`, TypeObjectMap.Circle);

          return mapObject;
        })
      }
      valueZoomNoOverlap = mapItem.getZoom();

      getMapMarkers.forEach((mapObject) => {
        let label = ''
        const startPosition = { lat: mapObject.position.lat, lng: mapObject.position.lng };
        const objectOverlayObject = getObject(TypeObjectMap.Label, `label_${mapObject.id}`);
        //eval no update to labels
        if (!mapObject.labelGroup && (!objectOverlayObject || (objectOverlayObject && !objectOverlayObject.labelPosition))) {
          const {
            newPositionLabel,
            labelPositionSpace,
            groupElements,
            labelGroupArea,
            labelGroupIdMain
          } = evaluateLabelsPosition(mapObject, mapItem.getZoom());
          mapObject.labelPositionSpace = labelPositionSpace;
          mapObject.labelGroupArea = labelGroupArea;
          mapObject.labelGroupIdMain = labelGroupIdMain;
          if (objectOverlayObject) objectOverlayObject.labelPositionSpace = labelPositionSpace;

          let groupsArray = []
          if (groupElements.arrayElements) {
            groupsArray = groupElements.elements
            const valueLabelGroup = groupsArray.map((group) => group.id)
            mapObject.labelGroup = valueLabelGroup;

            groupsArray.forEach((unit, index) => {
              unit.labelGroup = valueLabelGroup;
              unit.labelPositionSpace = labelPositionSpace;
              unit.labelGroupArea = labelGroupArea;
              unit.labelGroupIdMain = labelGroupIdMain;

              if (index <= 4) {
                label = `${label}${unit.label} </br>`;
              }

              //show all elements
              const showLabel = unit.id == mapObject.id
              const objectOverlay = getObject(TypeObjectMap.Label, `label_${unit.id}`);
              if (objectOverlay) {
                objectOverlay.labelGroup = valueLabelGroup;
                objectOverlay.labelPosition = true;
                objectOverlay.labelPositionSpace = labelPositionSpace;
                objectOverlay.labelGroupArea = labelGroupArea;
                objectOverlay.labelGroupIdMain = labelGroupIdMain;
                objectOverlay.instance.onDisplayObject(showLabel, mapItem);
              }

              if (!showLabel) {
                /* objectOverlay.labelPosition = true;
                objectOverlay.position = newPositionLabel */
                removeMapObject(`temporalLabel_poly_${unit.id}`, TypeObjectMap.Polyline);
              }

              //showMapObject(`label_${unit.id}`,  TypeObjectMap.Label, showLabel);
            });

            //update elements
            updateMarkerLabel(mapObject, '', newPositionLabel, true, label);
          } else {

            const objectOverlay = getObject(TypeObjectMap.Label, `label_${mapObject.id}`);
            if (objectOverlay) {
              objectOverlay.instance.onDisplayObject(true, mapItem);
              objectOverlay.labelPosition = true;
              objectOverlay.labelPositionSpace = labelPositionSpace;
              objectOverlay.labelGroupArea = labelGroupArea;
              objectOverlay.labelGroupIdMain = labelGroupIdMain;
            }

            //update elements
            updateMarkerLabel(mapObject, '', newPositionLabel, true, mapObject.label);
          }
          //{ position: newPositionLabel, groupElements: { arrayElements: true, elements: arrayUnits} }

          //update positionLabel
          //updateMarkerLabel(mapObject, '', newPositionLabel, true);

          addMapObject(new MapObject().buildPolyLine({
            id: `temporalLabel_poly_${mapObject.id}`,
            coordinates: [startPosition, newPositionLabel],
            layerName: TypeLayer.UnitLabel,
            heading: 0,
            color: '#B0B0B0'
          }), true, true);
        }

        if (objectOverlayObject) objectOverlayObject.labelPosition = true;
      });

    } else {
      //return all to normal position
      const getMapLabels = mapObjects.filter((element) => String(element?.id || "").includes('label_') && element.labelPosition && element.instance);
      getMapLabels.forEach((element) => {
        //update defaul position label
        element.position = element.startPosition
        element.labelPosition = false
        element.labelGroup = null
        element.labelGroupArea = null
        element.labelGroupIdMain = null
        element.labelPositionSpace = null;

        const googlePosition = new google.maps.LatLng(element.position.lat, element.position.lng)
        element.instance.onMove(googlePosition);
      })
    }

  }

  /******************************************************
   * Values to zoom to reorder the labels into view
   ******************************************************/
  const valuesToZoom = (zoomValue) => {
    let multiplier = 1;
    switch (zoomValue) {
      case 21: multiplier = 1; break;
      case 20: multiplier = 3; break;
      case 19: multiplier = 7; break;
      case 18: multiplier = 14; break;
      case 17: multiplier = 30; break;
      case 16: multiplier = 62; break;
      case 15: multiplier = 124; break;
      case 14: multiplier = 248; break;
      case 13: multiplier = 496; break;
      case 12: multiplier = 992; break;
      case 11: multiplier = 1984; break;
      case 10: multiplier = 3968; break;
      case 9: multiplier = 7936; break;
      case 8: multiplier = 15872; break;
      case 7: multiplier = 31744; break;
      case 6: multiplier = 63488; break;
      case 5: multiplier = 126976; break;
      case 4: multiplier = 253952; break;
      case 3: multiplier = 507904; break;
    }
    return { latZoom: (0.000009 * multiplier), lngZoom: (0.00007 * multiplier) }
  }

  /******************************************************
  * Logic to get new position from labels
  ******************************************************/
  const evaluateLabelsPosition = (mapObject, zoomValue, isRealtime = false) => {

    const radiusGroup = 4;

    /* console.log('arrays', 
    mapObjects.filter((item) => item.type == TypeObjectMap.Label && item.layerName == TypeLayer.UnitLabel)
    .map((labelElement) => labelElement.labelGroup)) */

    let startZoneLat = 0, startZoneLng = 0;
    let zoneLat = 0.000009, zoneLng = 0.00007, counterPos = 0;

    let arrayLabelsLatLng = mapObjects.filter((item) =>
      item.type == TypeObjectMap.Label
      && item.layerName == TypeLayer.UnitLabel
      /* && !item.labelGroup */
      && (item.position
        && item.position.lat >= lat1 && item.position.lat <= lat2
        && item.position.lng >= lng1 && item.position.lng <= lng2))
      .map((labelElement) => ({
        id: labelElement.id,
        label: labelElement.label,
        labelGroup: labelElement.labelGroup,
        lat: labelElement.position.lat,
        lng: labelElement.position.lng,
        labelPositionSpace: labelElement.labelPositionSpace
      }));

    if (arrayLabelsLatLng && arrayLabelsLatLng.length <= 1) {
      const newPositionLabel = getPositionObject(mapObject.position, 2, zoneLat, zoneLng, startZoneLat, startZoneLng);

      let zoneLatFactorLong = zoneLat;

      let pointLatFirst = newPositionLabel.lat - (zoneLatFactorLong / 2);
      let pointLatSecond = newPositionLabel.lat + (zoneLat / 2);
      let pointLngFirst = newPositionLabel.lng;
      let pointLngSecond = newPositionLabel.lng + zoneLng;

      let labelPositionSpace = { pointLatFirst, pointLatSecond, pointLngFirst, pointLngSecond };

      return {
        newPositionLabel,
        labelPositionSpace,
        groupElements: { arrayElements: false, elements: [mapObject] }
      };
    }

    //zoom 22 - 3
    let percentZoomLat = 0, percentZoomLng = 0;
    if (zoomValue >= 3 && zoomValue < 22) {
      const { latZoom, lngZoom } = valuesToZoom(zoomValue)
      percentZoomLat = latZoom
      percentZoomLng = lngZoom

      zoneLat = zoneLat + percentZoomLat
      zoneLng = zoneLng + percentZoomLng
    }
    //create landmark group
    let labelGroupArea = {
      pointLatFirst: mapObject.position.lat - (zoneLat * radiusGroup),
      pointLatSecond: mapObject.position.lat + (zoneLat * radiusGroup),
      pointLngFirst: mapObject.position.lng - (zoneLat * radiusGroup),
      pointLngSecond: mapObject.position.lng + (zoneLat * radiusGroup)
    }
    if (isRealtime && mapObject.labelGroupArea) {
      labelGroupArea = mapObject.labelGroupArea
    }

    let arrayLabelsStack = mapObjects.filter((item) =>
      item.type == TypeObjectMap.Marker
      && (item.instance && item.instance.getMap())
      && item.layerName == TypeLayer.Units
      && (item.position
        && item.position.lat >= lat1 && item.position.lat <= lat2
        && item.position.lng >= lng1 && item.position.lng <= lng2));

    //get the units into the cuadrant
    arrayLabelsStack = arrayLabelsStack.filter((item) =>
      item.position
      && !item.labelGroup
      && ((item.position.lat >= labelGroupArea.pointLatFirst)
        && (item.position.lat <= labelGroupArea.pointLatSecond)
        && (item.position.lng >= labelGroupArea.pointLngFirst)
        && (item.position.lng <= labelGroupArea.pointLngSecond)))

    const isGroup = arrayLabelsStack && arrayLabelsStack.length > 5;

    // ************************************************************************
    //add units to arrayObjects.
    arrayLabelsLatLng = [...arrayLabelsLatLng, ...mapObjects.filter((item) =>
      item.type == TypeObjectMap.Marker
      && item.layerName == TypeLayer.Units
      /* && !item.labelGroup */
      && (item.position
        && item.position.lat >= lat1 && item.position.lat <= lat2
        && item.position.lng >= lng1 && item.position.lng <= lng2))
      .map((labelElement) => ({
        id: labelElement.id,
        label: labelElement.label,
        labelGroup: null,
        lat: labelElement.position.lat,
        lng: labelElement.position.lng,
        labelPositionSpace: {
          pointLatFirst: labelElement.position.lat - (zoneLat / 2),
          pointLatSecond: labelElement.position.lat + (zoneLat / 2),
          pointLngFirst: labelElement.position.lng - (zoneLat / 2),
          pointLngSecond: labelElement.position.lng + (zoneLat / 2)
        }
      }
      ))];

    let { newPositionLabel, labelPositionSpace } = getNewPositionLabel(arrayLabelsLatLng, mapObject.position, mapObject, counterPos, zoneLat, zoneLng, startZoneLat, startZoneLng, isGroup)

    if (isGroup && !isRealtime) {
      const { arrayUnits } = labelDrawGroupAreaOnMap(mapObject, arrayLabelsStack, zoneLat)

      //return arrayElements
      return {
        newPositionLabel,
        labelPositionSpace: labelPositionSpace,
        groupElements: { arrayElements: true, elements: arrayUnits },
        clusterPosition: { lat: mapObject.position.lat - (zoneLat * radiusGroup), lng: mapObject.position.lng + (zoneLat * radiusGroup) },
        labelGroupArea: labelGroupArea,
        labelGroupIdMain: mapObject.labelGroupIdMain || mapObject.id
      };
    }

    return {
      newPositionLabel,
      labelPositionSpace: labelPositionSpace,
      groupElements: { arrayElements: false, elements: [mapObject] },
      labelGroupArea: null,
      labelGroupIdMain: null
    };
  }

  /******************************************************
   * Draw the circles into the map, validate the area and draw the elements.
   * @param {*} mapObject 
   * @param {*} arrayLabelsStack 
   * @param {*} zoneLat 
   * @returns 
   ******************************************************/
  const labelDrawGroupAreaOnMap = (mapObjectMain, arrayLabelsStack, zoneLat = null) => {

    if (zoneLat == null) {
      const zoomValue = mapItem.getZoom();
      zoneLat = 0.000009;
      if (zoomValue >= 3 && zoomValue < 22) {
        const { latZoom } = valuesToZoom(zoomValue)
        zoneLat = latZoom
      }
    }

    const arrayUnits = []
    //get units elements    
    let distanceOnMetters = calcDistanceToPoints(mapObjectMain.position.lat, mapObjectMain.position.lng, mapObjectMain.position.lat + zoneLat, mapObjectMain.position.lng + zoneLat);
    //convert this part in metters
    let convertToKilometers = distanceOnMetters * 1000;
    let maxValue = {
      position: { lat: mapObjectMain.position.lat + zoneLat, lng: mapObjectMain.position.lng + zoneLat },
      radius: convertToKilometers
    }

    arrayLabelsStack.forEach((item) => {
      distanceOnMetters = calcDistanceToPoints(mapObjectMain.position.lat, mapObjectMain.position.lng, item.position.lat, item.position.lng);
      convertToKilometers = distanceOnMetters * 1000;
      if (maxValue.radius < convertToKilometers) {
        maxValue = {
          position: { lat: item.position.lat, lng: item.position.lng },
          radius: convertToKilometers
        }
      }
      arrayUnits.push(getObject(TypeObjectMap.Marker, item.id.toString().replace('label_', '')));
    })

    //Create a temporal circle with to show group
    const tLat1 = mapObjectMain.position.lat;
    const tLng1 = mapObjectMain.position.lng;
    const tLat2 = maxValue.position.lat;
    const tLng2 = maxValue.position.lng;

    const degress = Math.acos(Math.sin(tLat1 * Math.PI / 180) * Math.sin(tLat2 * Math.PI / 180) + Math.cos(tLat1 * Math.PI / 180) * Math.cos(tLat2 * Math.PI / 180) * Math.cos(tLng2 * Math.PI / 180 - tLng1 * Math.PI / 180)) * 6371000;

    //remove old object
    removeMapObject(`circleCluster_${mapObjectMain.labelGroupIdMain || ''}`, TypeObjectMap.Circle);
    removeMapObject(`circleCluster_${mapObjectMain.id}`, TypeObjectMap.Circle);

    addMapObject(new MapObject().buildCircle({
      id: `circleCluster_${mapObjectMain.labelGroupIdMain || mapObjectMain.id}`, //Ref to move elements in marker event
      position: mapObjectMain.position,
      layerName: TypeLayer.MarkerCluster,
      label: '',
      heading: 0,
      color: mapObjectMain.color || '#0098dc',
      eventName: 'no event',
      radius: /* maxValue.radius, */ degress,
      draggable: false,
      fillOpacity: 0.01
    }));

    return { arrayUnits: arrayUnits }
  }

  /******************************************************
   * Desing to temporal labels and order in map
   ******************************************************/
  const createTemporalLabel = useCallback((zoomValue) => {

    let newPosition = null;
    let startZoneLat = 0, startZoneLng = 0;
    let zoneLat = 0.000009, zoneLng = 0.00007, counterPos = 0;

    //zoom 22 - 3
    let percentZoomLat = 0, percentZoomLng = 0;
    if (zoomValue >= 14 && zoomValue < 22) {
      const { latZoom, lngZoom } = valuesToZoom(zoomValue)
      percentZoomLat = latZoom
      percentZoomLng = lngZoom

      zoneLat = zoneLat + percentZoomLat
      zoneLng = zoneLng + percentZoomLng
    }

    //consolidate labels in cluster elements
    const getClusterToDelete = mapObjects.filter((element) => element && element.id && element?.id?.includes('temporalLabel'));


    if (getClusterToDelete && getClusterToDelete.length > 0) {
      getClusterToDelete.forEach((itemToDelete) => {
        removeMapObject(itemToDelete.id, itemToDelete.type);
      })
    }

    let startPosition = { lat: 3.897376, lng: -76.293598 };

    const mapObjectPointStart = new MapObject().buildMarker({
      id: 'centerpoint01', //load the id from element
      position: startPosition,
      layerName: TypeLayer.TemporalObjects,
      label: 'Center Point',
      heading: 0,
      color: '000',
      eventName: 'Center Point',
      draggable: false
    });


    //add the object into the map
    addMapObject(mapObjectPointStart);

    for (let i = 0; i < 60; i++) {
      newPosition = getPositionObject(startPosition, counterPos, zoneLat, zoneLng, startZoneLat, startZoneLng);
      counterPos = counterPos + 1;

      const objectLabel = new MapObject().buildLabel({
        id: `temporalLabel_${i}`,
        label: `Ingreso nombre largo de AA - ${i}`,
        startPosition: startPosition,
        position: newPosition,
        layerName: TypeLayer.UnitLabel
      })

      //add the object into the map
      addMapObject(objectLabel);

      if (counterPos == 14) {
        counterPos = 0;
        startZoneLat = startZoneLat + zoneLat;
        startZoneLng = startZoneLng + zoneLng;
      }
    }

  })

  /******************************************************
  * Recursive function to get new label name in view
  ******************************************************/
  const getNewPositionLabel = (arrayLabelsLatLng, newPosition, mapObject, counterPos = 0, zoneLat = 0.000009, zoneLng = 0.00007, startZoneLat = 0, startZoneLng = 0, isGroup = false) => {

    let responsePosition = newPosition
    if (counterPos == 0 && startZoneLat == 0 && startZoneLng == 0) {
      responsePosition = getPositionObject(mapObject.position, counterPos, zoneLat, zoneLng, startZoneLat, startZoneLng)
      counterPos = counterPos + 1;
    }

    let zoneLatFactorLong = isGroup ? (zoneLat * 5) : zoneLat;
    let zoneLngFactorLong = isGroup ? (zoneLng / 2) : 0;

    let pointLatFirst = responsePosition.lat - (zoneLatFactorLong / 2);
    let pointLatSecond = responsePosition.lat + (zoneLat / 2);
    let pointLngFirst = responsePosition.lng - zoneLngFactorLong;
    let pointLngSecond = responsePosition.lng + zoneLng;

    let labelPositionSpace = {
      pointLatFirst: pointLatFirst,
      pointLatSecond: pointLatSecond,
      pointLngFirst: pointLngFirst,
      pointLngSecond: pointLngSecond
    };

    if (arrayLabelsLatLng && arrayLabelsLatLng.length > 0) {
      //realizar los calculos sobre el boundary
      const itemFind = arrayLabelsLatLng.find((item) =>
        item.labelPositionSpace
        &&
        ((item.labelPositionSpace.pointLatFirst <= responsePosition.lat &&
          item.labelPositionSpace.pointLatSecond > responsePosition.lat &&
          item.labelPositionSpace.pointLngFirst <= responsePosition.lng &&
          item.labelPositionSpace.pointLngSecond > responsePosition.lng)
          ||
          (item.labelPositionSpace.pointLatFirst <= pointLatFirst &&
            item.labelPositionSpace.pointLatSecond > pointLatFirst &&
            item.labelPositionSpace.pointLngFirst <= pointLngFirst &&
            item.labelPositionSpace.pointLngSecond > pointLngFirst)
          ||
          (item.labelPositionSpace.pointLatFirst <= pointLatFirst &&
            item.labelPositionSpace.pointLatSecond > pointLatFirst &&
            item.labelPositionSpace.pointLngFirst <= pointLngSecond &&
            item.labelPositionSpace.pointLngSecond > pointLngSecond)
          ||
          (item.labelPositionSpace.pointLatFirst <= pointLatSecond &&
            item.labelPositionSpace.pointLatSecond > pointLatSecond &&
            item.labelPositionSpace.pointLngFirst <= pointLngFirst &&
            item.labelPositionSpace.pointLngSecond > pointLngFirst)
          ||
          (item.labelPositionSpace.pointLatFirst <= pointLatSecond &&
            item.labelPositionSpace.pointLatSecond > pointLatSecond &&
            item.labelPositionSpace.pointLngFirst <= pointLngSecond &&
            item.labelPositionSpace.pointLngSecond > pointLngSecond))
      );

      if (itemFind) {


        const newPositionTemp = getPositionObject(mapObject.position, counterPos, zoneLat, zoneLng, startZoneLat, startZoneLng)
        counterPos = counterPos + 1;

        if (counterPos == 14) {
          counterPos = 0;
          startZoneLat = startZoneLat + zoneLat;
          startZoneLng = startZoneLng + zoneLng;
        }

        const { newPositionLabel: paramResponsePosition, labelPositionSpace: paramLabelPositionSpace } = getNewPositionLabel(arrayLabelsLatLng, newPositionTemp, mapObject, counterPos, zoneLat, zoneLng, startZoneLat, startZoneLng, isGroup);

        responsePosition = paramResponsePosition
        labelPositionSpace = paramLabelPositionSpace
        return { newPositionLabel: responsePosition, labelPositionSpace };
      }
    }
    return { newPositionLabel: responsePosition, labelPositionSpace };
  }


  /******************************************************
   * Statics positions in map
   ******************************************************/
  const getPositionObject = (position = { lat: 0, lng: 0 }, numberPosition = 0, zoneLat, zoneLng, startZoneLat, startZoneLng) => {
    let newPosition = null
    switch (numberPosition) {
      case 0:
        newPosition = { lat: startZoneLat + position.lat, lng: startZoneLng + position.lng + (zoneLng / 6) };
        break;

      case 1:
        newPosition = { lat: startZoneLat + position.lat + zoneLat, lng: startZoneLng + position.lng + (zoneLng / 6) };
        break;

      case 2:
        newPosition = { lat: startZoneLat + position.lat + (zoneLat * 2), lng: startZoneLng + position.lng + (zoneLng / 7) };
        break;

      case 3:
        newPosition = { lat: startZoneLat + position.lat - zoneLat, lng: startZoneLng + position.lng + (zoneLng / 6) };
        break;

      case 4:
        newPosition = { lat: startZoneLat + position.lat - (zoneLat * 2), lng: startZoneLng + position.lng + (zoneLng / 7) };
        break;

      case 5:
        newPosition = { lat: startZoneLat + position.lat - (zoneLat * 3), lng: startZoneLng + position.lng };
        break;

      case 6:
        newPosition = { lat: startZoneLat + position.lat - (zoneLat * 4), lng: startZoneLng + position.lng - (zoneLng / 8) };
        break;

      case 7:
        newPosition = { lat: startZoneLat + position.lat - (zoneLat * 5), lng: startZoneLng + position.lng - (zoneLng / 4) };
        break;

      case 8:
        newPosition = { lat: - startZoneLat + position.lat - (zoneLat * 2), lng: - startZoneLng + position.lng - zoneLng };
        break;

      case 9:
        newPosition = { lat: - startZoneLat + position.lat - zoneLat, lng: - startZoneLng + position.lng - zoneLng };
        break;

      case 10:
        newPosition = { lat: - startZoneLat + position.lat, lng: - startZoneLng + position.lng - zoneLng };
        break;

      case 11:
        newPosition = { lat: - startZoneLat + position.lat + zoneLat, lng: - startZoneLng + position.lng - zoneLng };
        break;

      case 12:
        newPosition = { lat: - startZoneLat + position.lat + (zoneLat * 2), lng: - startZoneLng + position.lng - zoneLng };
        break;

      case 13:
        newPosition = { lat: - startZoneLat + position.lat + (zoneLat * 3), lng: - startZoneLng + position.lng - zoneLng + (zoneLng / 8) };
        break;

    }
    return newPosition;
  }

  /******************************************************
  * Add marker from landmarks on map
  ******************************************************/
  const addImageMapType = (mapObject, validateDobleObject = true) => {

    if (!mapItem)
      return
    if (validateDobleObject && !validateNewObject(mapObject)) {
      return
    }

    let iconUrl = ''

    if (mapObject.url) {
      iconUrl = `${pathFile}/${mapObject.url}`
    } else {
      const colorFlag = findColor(mapObject.color.replace("#", ""));
      iconUrl = `${pathFile}/kmls/flagicon/${colorFlag}.png`
      iconUrl = {
        url: iconUrl,
        //x-y
        origin: new google.maps.Point(0, 0), // eslint-disable-line no-undef
        anchor: new google.maps.Point(6, 24)
      }
    }

    const position = new google.maps.LatLng(mapObject.position.lat, mapObject.position.lng);
    const marker = new google.maps.Marker({ // eslint-disable-line no-undef
      id: mapObject.id,
      position: position,
      map: mapItem,
      icon: iconUrl,
      optimized: true
    });

    marker.addListener('click', () => {
      const obj = {
        id: mapObject.id.toString().replace("landmark_", ""),
        position: position,
        icon: {
          rotation: 0
        },
        layerName: TypeLayer.Landmark
      }
      props.modalPopupEvent(obj, obj);
    })
    return marker;
  }

  /******************************************************
  * Add Circle on landmarks marker
  ******************************************************/
  const addChildLandmarkCircle = (mapObject, validateDobleObject = true) => {
    if (!mapItem)
      return
    if (validateDobleObject && !validateNewObject(mapObject)) {
      return
    }
    const mapObjectChild = mapObject
    const position = new google.maps.LatLng(mapObject.position.lat, mapObject.position.lng);
    mapObjectChild.position = position

    const circle = addCircle(mapObjectChild, true)

    circle.addListener('click', () => {
      const obj = {
        id: mapObject.id.toString().replace("landmark_", "").replace("geofence_", ""),
        position: position,
        icon: {
          rotation: 0
        },
        layerName: mapObject.type == TypeObjectMap.Landmark ? TypeLayer.Landmark : TypeLayer.Geofence
      }
      if (!realStateInfoWindow)
        props.modalPopupEvent(obj, obj);
    })

    return circle
  }

  /******************************************************
  * Add Polygon on landmarks marker
  ******************************************************/
  const addChildLandmarkPolygon = (mapObject, validateDobleObject = true) => {
    if (!mapItem)
      return
    if (validateDobleObject && !validateNewObject(mapObject)) {
      return
    }
    const mapObjectChild = mapObject
    const position = new google.maps.LatLng(mapObject.position.lat, mapObject.position.lng);
    mapObjectChild.position = position

    const polygon = addPolygon(mapObjectChild)

    polygon.addListener('click', () => {
      const obj = {
        id: mapObject.id.toString().replace("landmark_", "").replace("geofence_", ""),
        position: position,
        icon: {
          rotation: 0
        },
        layerName: mapObject.type == TypeObjectMap.Landmark ? TypeLayer.Landmark : TypeLayer.Geofence
      }
      if (!realStateInfoWindow)
        props.modalPopupEvent(obj, obj);
    })

    return polygon
  }

  /******************************************************
  * Add the settings values into mapObjects
  * Used by:
  * 1. Polygon on landmarks marker
  * 2. Prevent Overlap Labels
  ******************************************************/
  const addMapObjectSettings = (active, markerZoom = 0, radiusZoom = 0, id = 0, event = null, otherSettings = null) => {

    let objectSettings = mapObjects.find((item) => item.id == id);
    let changeState = true
    if (objectSettings) {
      changeState = objectSettings.enable != active
      objectSettings.enable = active;
      objectSettings.value = markerZoom;
      objectSettings.valueChild = radiusZoom;
      if (otherSettings) {
        for (let item in otherSettings) {
          objectSettings[item] = otherSettings[item]
        }
      }
      if (markerZoom > 0)
        showLandmarksGeofenceByZoom(id, mapItem.getZoom());
    } else {
      objectSettings = new MapObject().buildSettings({
        id: id,
        enable: active,
        value: markerZoom,
        valueChild: radiusZoom,
        showLabel: false,
        onCallbackEvent: event
      });

      if (otherSettings) {
        for (let item in otherSettings) {
          objectSettings[item] = otherSettings[item]
        }
      }

      mapObjects.push(objectSettings);
    }

    //create instance for boundsChangedEvents
    if (id == 'noOverlapSettings' && changeState) {
      if (active) {
        startingOverlapSettings()
      }
      else {
        restarLabelsToDefault()
      }
    }
  }

  /******************************************************
  * Vars to starting overlap in maps
  ******************************************************/
  const startingOverlapSettings = () => {
    addLabelMarkers(TypeLayer.UnitLabel, true, false)
    restarLabelsToDefault()
    createNewPositionsToLabels(true)
  }

  /******************************************************
  * Show or hide landmarks by zoom objects
  ******************************************************/
  const showLandmarksGeofenceByZoom = (id, zoomMap) => {
    const settingsObject = mapObjects.find((item) => item.id == id);

    if (!settingsObject) return

    let layer = TypeLayer.LandmarkMarker;
    if (settingsObject.id == "geofenceSettings") {
      layer = TypeLayer.Geofence
    }

    if (!settingsObject.enable) {
      showLayer(layer, false);
      return
    }

    //show layer information
    let showMain = (zoomMap >= settingsObject.value)
    let showChild = (zoomMap >= settingsObject.valueChild)

    if ((showMain || showChild) && !settingsObject.showLabel && settingsObject.onCallbackEvent) {
      settingsObject.showLabel = true
      //show first label landmark //dispatch(loadObjectsLandmarksOnMap(true)) || dispatch(loadObjectsGeofenceOnMap(true))
      dispatch(settingsObject.onCallbackEvent(true))
    }

    showLayerMainOrChild(layer, showMain, true)
    showLayerMainOrChild(layer, showChild, false)
  }

  /******************************************************
   * Add the label name from markers
   ******************************************************/
  const addLabelMarkers = useCallback((layer = TypeLayer.UnitLabel, show = true, evalOverlap = true) => {

    const objectNoOverlapSettings = getObject(TypeObjectMap.Settings, 'noOverlapSettings');
    if (evalOverlap && layer == TypeLayer.UnitLabel && mapItem.getZoom() > zoomLabelsPositions && objectNoOverlapSettings && objectNoOverlapSettings.enable && getCurrentZoom() < maxClusterZoom) {
      return
    }

    const objectShowLabelSettings = getObject(TypeObjectMap.Settings, 'unitLabelSettings');
    if (mapItem.getZoom() > zoomLabelsPositions && objectShowLabelSettings && objectShowLabelSettings.enable) {
      show = true
    }

    if (!show) {
      showLayer(layer, show)
      return
    }

    if (layer == TypeLayer.UnitLabel) {
      removeLayer(layer)
    }

    if (mapObjects && mapObjects.length > 0) {
      let mapMarkers = mapObjects.filter((item) => item.type == TypeObjectMap.Marker && (item.instance && item.instance.getMap()))
      if (layer != TypeLayer.UnitLabel) {
        mapMarkers = mapMarkers.filter((item) => item.layerName == layer);
      } else {
        mapMarkers = mapMarkers.filter((item) => item.layerName == TypeLayer.Units);

        //add listener to cluster object
        addMapClusterListener()
      }

      if (mapMarkers && mapMarkers.length > 0) {
        mapMarkers.map((item) => {
          if (item.instance != null && !item?.hideMarker) {
            const objectLabel = new MapObject().buildLabel({
              id: `label_${item.id}`,
              label: cutNotes(item.label, 30),
              position: item.position,
              startPosition: item.position,
              layerName: layer
            })

            //add the object into the map
            addMapObject(objectLabel);
          }else if(layer == TypeLayer.VechileTrails && item?.hideMarker){
            removeMapObject(`label_${item.id}`, TypeObjectMap.Label)
          }
        })
      }
    }
  })

  /******************************************************
   * Show Labels when cluster is active on map
   ******************************************************/
  const showOrHideLabelsOnCluster = useCallback((cluster = null) => {
    let objectCluster = cluster
    if (!cluster) {
      objectCluster = getObject(TypeObjectMap.MarkerCluster, 'unitCluster');
      if (objectCluster) {
        objectCluster = objectCluster.instance
      }
    }

    if (!objectCluster) return

    addLabelMarkers();

    //no cluster activo 
    if (getCurrentZoom() > maxClusterZoom) return;

    const clusters = objectCluster?.clusters || [];

    const getLabelLines = mapObjects.filter((element) => String(element?.id || "").includes('temporalLabel_poly_') == true);
    getLabelLines.forEach((item) => {
      removeMapObject(item.id, item.type);
    })

    for (let i = 0; i < clusters.length; i++) {
      //let markersOnCluster = objectCluster.getMarkers(); 
      const markersOnCluster = clusters[i]?.markers || [];

      for (var j = 0; j < markersOnCluster.length; j++) {
        const item = markersOnCluster[j];
        if (markersOnCluster.length > 1) {
          removeMapObject(`label_${item.id}`, TypeObjectMap.Label);
        } else {
          removeMapObject(`label_${item.id}`, TypeObjectMap.Label);
          const objectLabel = new MapObject().buildLabel({
            id: `label_${item.id}`,
            label: cutNotes(item.title, 30),
            position: { lat: item.position.lat(), lng: item.position.lng() },
            layerName: TypeLayer.UnitLabel
          })
          //add the object into the map
          addMapObject(objectLabel);
        }
      }

    }

    const objectNoOverlapSettings = getObject(TypeObjectMap.Settings, 'noOverlapSettings');
    if (!objectNoOverlapSettings || (objectNoOverlapSettings && !objectNoOverlapSettings.enable)) return

    const labelMarkerCluster = clusters.filter((clusterElement) => clusterElement?.markers?.length > 0)

    const zoomValue = getCurrentZoom();

    labelMarkerCluster.forEach((elementCluster) => {
      const unitsOnCluster = [];
      elementCluster?.markers?.forEach((clusterMarker) => {
        unitsOnCluster.push(getObject(TypeObjectMap.Marker, clusterMarker.id));
      });
      if (unitsOnCluster.length > 1) {
        let zoneLat = 0;
        if (zoomValue >= 3 && zoomValue < 22) {
          const { latZoom } = valuesToZoom(zoomValue)
          zoneLat = elementCluster.position.lat() + latZoom
        }
        const centerClusterPoss = { lat: zoneLat, lng: elementCluster.position.lng() };
        createNewPositionsToLabelsCluster(unitsOnCluster, centerClusterPoss)
      }
    })

  })

  /******************************************************
   * Add the label overflow objects
   ******************************************************/
  const removeLabelOverlay = useCallback((layerName) => {

    for (let i = (mapObjects.length - 1); i >= 0; i--) {
      let mapObject = mapObjects[i];
      if (mapObject.layerName == layerName) {
        try {
          mapObject.instance.setMap(null);
        }
        catch (err) {
          console.log('error ***', err.toString())
        }

      }
    }
  })

  /******************************************************
   * Hide the modal popup at the marker into the realtime maps
   ******************************************************/
  const hideMarkerModal = useCallback(() => {
    setOpenInfoWindow(false);
    if (customOverlay) customOverlay.setMap(null);
    mapItem && mapItem.setOptions({ draggable: true });

    if (window.openInfoBubble) {
      clearTimeout(window.openInfoBubble)
    }

    window.openInfoBubble = setTimeout(() => {
      realStateInfoWindow = false;
      window.openInfoBubble = null;
    }, 500);
  })

  /******************************************************
   * Get the info modal show or hide on map
   ******************************************************/
  const getOpenInfoWindow = useCallback(() => {
    return realStateInfoWindow
  })

  /******************************************************
   * Validate if the marker exist into the array elements.
   * @param {JSON} mapObject 
   ******************************************************/
  const existsObject = (mapObject) => {
    return mapObjects.find((item) => item.type == mapObject.type && item.id == mapObject.id && !mapObject.instance);
  }

  /******************************************************
   * Add the Polyline objecto into google maps to google.maps.Polyline
   * @param {JSON} mapObject 
   ******************************************************/
  const addPolyline = (mapObject, evalInstance = false) => {
    if (evalInstance && existsObject(mapObject)) {
      return
    }
    
    //to create line into route
    var dashedLine = {
      //path: 'M 0,-1 0,1',
      path: 'M -2,0 2,0 0,-4 z',
      strokeOpacity: 0.8,
      scale: 3,
      //fillColor: mapObject.color,
      fillOpacity: 0.8,
    };
    
    const polyline = new google.maps.Polyline({ // eslint-disable-line no-undef
      path: mapObject.coordinates,
      geodesic: true,
      strokeColor: mapObject.color,
      strokeOpacity: mapObject?.fillOpacity || 1.0,
      strokeWeight: mapObject?.strokeWeight || 2,
      map: mapItem,
      layerName: mapObject.layerName || TypeLayer.Default,
      clickable: false,
      icons: [{
        icon: dashedLine,
        offset: '0',
        repeat: '200px' // Define la distancia entre cada segmento punteado
      }],
    })

    return polyline
  }

  /******************************************************
   * Add the point to polyline object
   ******************************************************/
  const addPolylinePoint = useCallback((id, latLng, isArray = false) => {
    const polyline = mapObjects.find(item => item.id == id && item.type == TypeObjectMap.Polyline)

    if (polyline) {
      const path = polyline.instance.getPath();
      let objLatLng = null
      if(!isArray){
        objLatLng = new google.maps.LatLng(latLng.lat, latLng.lng) // eslint-disable-line no-undef  
        path.push(objLatLng);
      }else if(latLng && latLng.length > 0 && isArray){
        latLng.forEach((element)=> {
          path.push(new google.maps.LatLng(element.lat, element.lng))
        });
      }
      
      
    }
  })

  /******************************************************
   * Add the Polygon objecto into google maps to google.maps.Polygon
   * @param {JSON} mapObject 
   ******************************************************/
  const addPolygon = (mapObject) => {
    let polygon = null
    if (mapObject.coordinates && mapObject.coordinates.length > 0) {
      let fillOpacity = mapObject?.fillOpacity || 0.35
      let strokeWeight = mapObject?.strokeWeight || 0.5

      polygon = new google.maps.Polygon({ // eslint-disable-line no-undef          
        geodesic: false,
        strokeColor: mapObject.strokeColor || mapObject.color,
        strokeOpacity: 1.0,
        strokeWeight: strokeWeight,
        fillColor: mapObject.color,
        fillOpacity: fillOpacity,
        paths: mapObject.coordinates,
        map: mapItem,
        zIndex: 6,
        optimized: true
      })
    } else {
      polygon = new google.maps.Polygon({ // eslint-disable-line no-undef          
        geodesic: false,
        strokeColor: mapObject.strokeColor || mapObject.color,
        strokeOpacity: 1.0,
        strokeWeight: 2,
        fillColor: mapObject.color,
        fillOpacity: 0.35,
        map: mapItem,
        zIndex: 6,
        optimized: true
      })
    }

    return polygon
  }

  /******************************************************
   * Add the Circle object into google maps to google.maps.Circle
   * @param {JSON} mapObject 
   ******************************************************/
  const addCircle = (mapObject, drawColor = false, opacity) => {
    const circle = new google.maps.Circle({ // eslint-disable-line no-undef          
      geodesic: false,
      strokeColor: mapObject.color,
      strokeOpacity: 0.6,
      strokeWeight: 0.5,
      fillColor: drawColor ? mapObject?.color : '#000',
      fillOpacity: mapObject?.fillOpacity > 0 ? mapObject?.fillOpacity : (drawColor ? 0.3 : 0.15),
      center: mapObject.position,
      radius: mapObject.radius,
      map: mapItem,
      zIndex: 6,
      optimized: true
    })

    return circle
  }

  /******************************************************
   * Add the Rectangle object into google maps to google.maps.Rectangle
   * @param {JSON} mapObject 
   ******************************************************/
  const addRectangle = (mapObject, drawColor = false, opacity) => {
    let rectangle = new google.maps.Rectangle();
    rectangle.setOptions({ // eslint-disable-line no-undef          
      //geodesic: false,
      strokeColor: mapObject.color,
      strokeOpacity: 0.6,
      strokeWeight: 0.5,
      fillColor: drawColor ? mapObject.color : '#000',
      fillOpacity: mapObject?.fillOpacity > 0 ? mapObject?.fillOpacity : (drawColor ? 0.3 : 0.15),
      bounds: mapObject.boundsRectangle,
      map: mapItem,
      zIndex: 6,
      //optimized: true,
      draggable: mapObject.draggable || false
    })

    return rectangle
  }

  /******************************************************
   * Add the Square object into google maps to google.maps.Rectangle
   * @param {JSON} mapObject 
   ******************************************************/
  const addSquare = (mapObject, drawColor = false, opacity) => {

    const square = new google.maps.Rectangle({ // eslint-disable-line no-undef          
      geodesic: false,
      strokeColor: mapObject.color,
      strokeOpacity: 0.6,
      strokeWeight: 0.5,
      fillColor: drawColor ? mapObject.color : '#000',
      fillOpacity: mapObject?.fillOpacity > 0 ? mapObject?.fillOpacity : (drawColor ? 0.3 : 0.15),
      bounds: mapObject.boundsRectangle,
      map: mapItem,
      zIndex: 6,
      optimized: true,
      draggable: mapObject.draggable || false
    })

    square.addListener('drag', function (event) {
      const findSquareObject = mapObjects.find(item => item.type == TypeObjectMap.Square)
      const findMarkerSquareObject = mapObjects.find(item => item.type == TypeObjectMap.Marker && item.layerName == TypeLayer.SquareCreate)
      const { north, south, east, west } = findSquareObject.instance.getBounds().toJSON();
      findSquareObject.boundsRectangle = findSquareObject.instance.getBounds().toJSON();


      if (findMarkerSquareObject) {
        const centerSquare = getCenterFromBounds(findSquareObject.instance.getBounds().toJSON())

        const position = { lat: centerSquare.lat, lng: east }
        findMarkerSquareObject.instance.setPosition(position)
        findMarkerSquareObject.position = position
      }
    });

    square.addListener('dragend', function (event) {
      const findSquareObjectToUpdate = mapObjects.find(item => item.type == TypeObjectMap.Square)
      findSquareObjectToUpdate.minBoundsRectangle = findSquareObjectToUpdate.instance.getBounds().toJSON()

      //update the center because the square is moved
      const centerSquare = getCenterFromBounds(findSquareObjectToUpdate.instance.getBounds().toJSON())
      findSquareObjectToUpdate.position = centerSquare;

      //update coordinates for square object
      const coordinates = convertBoundsToCoordinates(findSquareObjectToUpdate.instance.getBounds().toJSON())
      findSquareObjectToUpdate.coordinates = coordinates;
    });

    return square
  }

  const updateTypeSquareToFreeRectangle = useCallback((valueFreeRectangle = false) => {
    const findSquareObjectToUpdate = mapObjects.find(item => item.type == TypeObjectMap.Square)
    if (findSquareObjectToUpdate) {
      findSquareObjectToUpdate.freeRectangle = valueFreeRectangle
    }
  })

  const convertBoundsToCoordinates = (objectBounds) => {
    const coordinates = [];
    const { north, south, east, west } = objectBounds;

    coordinates.push({ latitude: north, longitude: west });
    coordinates.push({ latitude: north, longitude: east });
    coordinates.push({ latitude: south, longitude: east });
    coordinates.push({ latitude: south, longitude: west });
    coordinates.push(coordinates[0])

    return coordinates;
  }

  const getCenterFromBounds = (objectBounds) => {

    let coordinates = convertBoundsToCoordinates(objectBounds)
    const LatLngList = [];
    coordinates.forEach((element) => {
      LatLngList.push(new google.maps.LatLng(element.latitude, // eslint-disable-line no-undef
        element.longitude));
    })
    //Close the polygon object
    LatLngList.push(LatLngList[0])
    let latlngbounds = new google.maps.LatLngBounds(); // eslint-disable-line no-undef
    LatLngList.forEach((latLng) => {
      latlngbounds.extend(latLng);
    });

    let center = latlngbounds.getCenter();
    return { lat: center.lat(), lng: center.lng() };

  }

  /******************************************************
   * Add the Polygon objecto into google maps to google.maps.Polygon
   * @param {string} id of mapObject 
   ******************************************************/
  const AddPolygonPoint = useCallback((mouseEvent, id, markerObject) => {

    const mapObjectPolygon = mapObjects.find((item) => item.id == id);
    if (mapObjectPolygon) {

      const path = mapObjectPolygon.instance.getPath();

      //Make the validation of polygon -- Remove this two lines
      path.push(mouseEvent.latLng);
      //Create marker icon
      addMapObject(markerObject);

      /* const validationResult = validatePolygon(tempPath.Kb)
      if(validationResult.status){
        //Update new position polygon
        path.push(mouseEvent.latLng);
        //Create marker icon
        addMapObject(markerObject);
      } */
    }
  })

  /******************************************************
   * Create a new landmark on realtime maps
   * @param {*} position lat / lng position to the circle
   * @param {*} idFromTo name at the object
   * @param {*} onUpdateList events to return data on the UI
   * @param {*} objectOnMap 
   ******************************************************/
  const addCircleOnMapToCreate = (position, onUpdateList, idMarker, idCircle, radius, maxSize = 0, newMarkerStyle = null, zIndex = 10, noCenter = false) => {

    let radiusToMetters = radius;
    //create a main marker into the UI
    const mapObject = new MapObject().buildMarker({
      id: idMarker,
      position,
      layerName: TypeLayer.CircleCreate,
      label: '',
      heading: 0,
      color: '000',
      eventName: 'no event',
      draggable: true,
      maxSize: maxSize || 0,
      zIndex: zIndex
    });
    addMapObject(mapObject);

    if(newMarkerStyle && mapObject.instance)
      mapObject.instance.setIcon(newMarkerStyle)

    //Create the Circle Object related to the marker
    const circle = new MapObject().buildCircle({
      id: idCircle, //Ref to move elements in marker event
      position,
      layerName: TypeLayer.CircleCreate,
      label: '',
      heading: 0,
      color: '#0098dc',
      eventName: 'no event',
      draggable: true
    })
    addMapObject(circle)

    const newLngOnMarkerDrag = getLngByDistance(position.lat, position.lng, radius)

    //create a main marker into the UI
    let markerObjectToDragCircle = new MapObject().buildMarker({
      id: `${idMarker}_drag`,
      position: { lat: position.lat, lng: newLngOnMarkerDrag },
      layerName: TypeLayer.CircleCreate,
      label: '',
      heading: 0,
      color: '000',
      eventName: 'no event',
      draggable: false,
      onUpdateList: onUpdateList,
      url: '/assets/icons/markersize.png',
      crossOnDrag: false,
      maxSize: maxSize || 0
    });

    markerObjectToDragCircle = addMapObject(markerObjectToDragCircle);

    if(markerObjectToDragCircle?.instance){
      markerObjectToDragCircle.instance.setDraggable(true);
    }else{
      //no instance return value
      return
    }
      

    //create event to drag
    markerObjectToDragCircle?.instance.addListener('drag', function (event) {
      const findCircleMarker = mapObjects.find(item => item.type == TypeObjectMap.Marker && item.layerName == TypeLayer.CircleCreate && !item.onUpdateList)
      const findCircleObjectToUpdate = mapObjects.find(item => item.type == TypeObjectMap.Marker && item.layerName == TypeLayer.CircleCreate && item.onUpdateList)
      //get distance from center point and new marker
      const { lat, lng } = event.latLng.toJSON();

      radiusToMetters = calcDistanceToPoints(findCircleMarker.instance.getPosition().lat(),
        findCircleMarker.instance.getPosition().lng(),
        lat,
        lng);

      updateCirclePoint(idCircle, null, radiusToMetters);
      //if have event to update list
      findCircleObjectToUpdate && findCircleObjectToUpdate?.onUpdateList && findCircleObjectToUpdate?.onUpdateList(radiusToMetters)
    });

    markerObjectToDragCircle?.instance?.addListener('dragend', function (event) {
      const findCircleMarker = mapObjects.find(item => item.type == TypeObjectMap.Marker && item.layerName == TypeLayer.CircleCreate && !item.onUpdateList)
      const findCircleMarkerMove = mapObjects.find(item => item.type == TypeObjectMap.Marker
        && item.layerName == TypeLayer.CircleCreate && item.onUpdateList)
      const { lat, lng } = event.latLng.toJSON();

      radiusToMetters = calcDistanceToPoints(findCircleMarker.instance.getPosition().lat(),
        findCircleMarker.instance.getPosition().lng(),
        lat,
        lng);
      //eval distance
      if (findCircleMarker.maxSize > 0 && radiusToMetters > findCircleMarker.maxSize) {
        //calc the point to marker
        const newLngOnMarkerDrag = getLngByDistance(findCircleMarker.instance.getPosition().lat(),
          findCircleMarker.instance.getPosition().lng(),
          findCircleMarker.maxSize)
        const position = { lat: findCircleMarker.instance.getPosition().lat(), lng: newLngOnMarkerDrag }
        findCircleMarkerMove.position = position
        findCircleMarkerMove.instance.setPosition(position)

        updateCirclePoint(idCircle, null, findCircleMarker.maxSize);

        findCircleMarkerMove && findCircleMarkerMove?.onUpdateList && findCircleMarkerMove?.onUpdateList(findCircleMarker.maxSize)

        radiusToMetters = findCircleMarker.maxSize;

      } else {
        updateCirclePoint(idCircle, null, radiusToMetters);
      }

      const coordinates = createSquareBoundingboxByMilesDistance(
        findCircleMarker.instance.getPosition().lat(),
        findCircleMarker.instance.getPosition().lng(),
        radiusToMetters);

      //center the element on map
      if(!noCenter){
        centerByFitBounds(coordinates);
      }
    })

    if(!noCenter){
      //create a marker to expand or colapse circle object
      centerObjectOnMap(TypeObjectMap.Marker, idMarker);
    }
    //dispatch(pointersCreated(true));
    updateCirclePoint(idCircle, null, radiusToMetters);

    //Update the color at the objects depends the settings selecion
    updateStyleToCreateObject();
  }

  /******************************************************
   * Perform the create new square object into the realtime maps
   * @param {Object} latLng 
   * @param {String} idFromTo 
   * @param {String} radiusObject 
   * @param {Float} radius 
   * @param {Float} defaulRadius 
   * @param {Boolean} freeRectangle 
   * @param {event} onUpdateList update the object radius in UI
   * @param {Int} maxSize
   ******************************************************/
  const addSquareOnMapToCreate = (latLng, idFromTo, radiusObject, radius, freeRectangle, onUpdateList, maxSize = 0) => {
    const toRadiusInMetter = radius
    const squareCoordinates = createSquareBoundingboxByMilesDistance(latLng.lat, latLng.lng, toRadiusInMetter);

    const bounds = {
      north: squareCoordinates[0].latitude,
      south: squareCoordinates[1].latitude,
      east: squareCoordinates[0].longitude,
      west: squareCoordinates[2].longitude,
    };

    //Create the Square Object
    const square = new MapObject().buildSquare({
      id: radiusObject, //Ref to move elements in marker event,
      position: { lat: latLng.lat, lng: latLng.lng },
      boundsRectangle: bounds,
      layerName: TypeLayer.SquareCreate,
      label: '',
      heading: 0,
      color: '#0098dc',
      eventName: 'no event',
      draggable: true,
      minBoundsRectangle: bounds,
      freeRectangle: freeRectangle,
      coordinates: squareCoordinates,
      onUpdateList: onUpdateList,
      maxSize: maxSize
    })
    addMapObject(square)
    centerPointOnMap(latLng.lat, latLng.lng, 18);

    const position = { lat: latLng.lat, lng: bounds.east }

    const mapObject = new MapObject().buildMarker({
      id: idFromTo,
      position,
      layerName: TypeLayer.SquareCreate,
      label: '',
      heading: 0,
      color: '000',
      eventName: 'no event',
      draggable: true,
      saveDragEvent: true,
      //url: '/assets/icons/markersize.png'
      url: `${pathFile}/icons/maps/zoom_out_map/253858.png`,
      maxSize: maxSize
    });
    addMapObject(mapObject);

    //center the element on map
    centerByFitBounds(squareCoordinates);

    //Update the color at the objects depends the settings selecion
    updateStyleToCreateObject();

  }

  /******************************************************
   * Add the Circle object into google maps to google.maps.Circle
   * @param {string} id of mapObject 
   ******************************************************/
  const updateCirclePoint = useCallback((id, mouseEvent = null, radius = 0, centerOnMap = false) => {
    const mapObjectCircle = mapObjects.find((item) => item.id == id && item.type == TypeObjectMap.Circle);
    if (mapObjectCircle) {
      //update Position
      if (mouseEvent) {
        mapObjectCircle.instance.setCenter(mouseEvent.latLng.toJSON());
      }
      //update radius
      if (radius > 0) {
        mapObjectCircle.instance.setRadius(parseInt(radius))
      }
    }
    //update the marker positon
    const mapMarkerCircle = mapObjects.find((item) =>
      item.type == TypeObjectMap.Marker &&
      item.layerName == TypeLayer.CircleCreate &&
      item.onUpdateList);

    if (mapMarkerCircle) {

      const mapMarkerCircleCenter = mapObjects.find((item) =>
        item.type == TypeObjectMap.Marker &&
        item.layerName == TypeLayer.CircleCreate &&
        !item.onUpdateList)

      if(!mapMarkerCircleCenter?.instance) return
      //calc the point to marker
      const newLngOnMarkerDrag = getLngByDistance(mapMarkerCircleCenter.instance.getPosition().lat(),
        mapMarkerCircleCenter.instance.getPosition().lng(),
        mapObjectCircle.instance.getRadius())
      const position = { lat: mapMarkerCircleCenter.instance.getPosition().lat(), lng: newLngOnMarkerDrag }
      mapMarkerCircle.instance.setPosition(position)

      if (centerOnMap) {
        const coordinates = createSquareBoundingboxByMilesDistance(
          mapMarkerCircleCenter.instance.getPosition().lat(),
          mapMarkerCircleCenter.instance.getPosition().lng(),
          mapObjectCircle.instance.getRadius());

        //center the element on map
        centerByFitBounds(coordinates);
      }
    }
  })

  /******************************************************
   * Add the Circle object into google maps to google.maps.Circle
   * @param {string} id of mapObject 
   ******************************************************/
  const updateSquarePoint = useCallback((id, mouseEvent = null) => {
    let mapObjectSquare = mapObjects.find((item) => item.id == id && item.type == TypeObjectMap.Square);
    let findMarkerSquareObject = mapObjects.find(item => item.type == TypeObjectMap.Marker && item.layerName == TypeLayer.SquareCreate);
    if (mapObjectSquare) {
      //update Position
      if (mouseEvent) {
        if (!mouseEvent?.latLng?.toJSON()) return

        const { lat, lng } = mouseEvent.latLng.toJSON();

        if (validateValidSquare(mouseEvent)) {

          //create square at the minimun value (50 feet)
          const nPositionMarker = updateSquareByDistanceMin(mapObjectSquare, 15.24)

          //send the drag event to square
          google.maps.event.trigger(findMarkerSquareObject.instance, 'drag', { newPosition: nPositionMarker, paramMarker: findMarkerSquareObject });
          findMarkerSquareObject.instance.setDraggable(false);
          google.maps.event.removeListener(findMarkerSquareObject.dragEvent);
          google.maps.event.removeListener(findMarkerSquareObject.dragEndEvent);
          findMarkerSquareObject.instance.setMap(null);

          removeMapObject(findMarkerSquareObject.id, findMarkerSquareObject.type)
          findMarkerSquareObject = null

          setTimeout((nPosition, mapObjectSquareParam) => {
            const newNameMarker = `MarkerSquare${new Date()}`
            const mapObjectMarkerSquare = new MapObject().buildMarker({
              id: newNameMarker,
              position: nPosition,
              layerName: TypeLayer.SquareCreate,
              label: '',
              heading: 0,
              color: '000',
              eventName: 'no event',
              draggable: false,
              saveDragEvent: false,
              url: `${pathFile}/icons/maps/zoom_out_map/253858.png`
            });
            const MarkerObjectSquare = addMapObject(mapObjectMarkerSquare);

            MarkerObjectSquare.instance.setDraggable(true);
            const dragEvent = MarkerObjectSquare.instance.addListener('drag', function (event) {
              if (event?.paramMarker?.instance) {
                event.paramMarker.instance.setPosition(event.newPosition)
              } else {
                updateSquarePoint('mapObject_01', event)
              }
            });

            const dragStartEvent = MarkerObjectSquare.instance.addListener('dragstart', onDragStartSquareEvent);
            const dragEndEvent = MarkerObjectSquare.instance.addListener('dragend', onDragEndSquareEvent);

            MarkerObjectSquare.dragEvent = dragEvent
            MarkerObjectSquare.dragEndEvent = dragEndEvent
            MarkerObjectSquare.dragStartEvent = dragStartEvent

            google.maps.event.trigger(mapObjectSquareParam.instance, 'drag', null);
          }, 500, nPositionMarker, mapObjectSquare);

        } else {

          if (mapObjectSquare.freeRectangle) {
            //define the new bounds to square object
            const newBounds = {
              ...mapObjectSquare?.boundsRectangle,
              south: lat,
              east: lng,
            };
            mapObjectSquare.boundsRectangle = newBounds;

            //update the square size
            mapObjectSquare.instance.setBounds(newBounds)
            //update marker position into objects
            findMarkerSquareObject.position = { lat, lng };
          } else {
            updateSquareSizeToDragEvents(mapObjectSquare, mouseEvent)
            findMarkerSquareObject.position = { lat, lng };
          }
        }

      }
    }
  })

  /******************************************************
   * Create the min square object on map
   ******************************************************/
  const updateSquareByDistanceMin = (findSquareObjectToUpdate, radiusInMetter) => {
    //calc the point to marker
    const newLngOnMarkerDrag = getLngByDistance(findSquareObjectToUpdate.position.lat,
      findSquareObjectToUpdate.position.lng,
      radiusInMetter)
    const position = { lat: findSquareObjectToUpdate.position.lat, lng: newLngOnMarkerDrag }

    const findMarkerSquareObject = mapObjects.find(item => item.type == TypeObjectMap.Marker &&
      item.layerName == TypeLayer.SquareCreate)
    findMarkerSquareObject.position = position
    findMarkerSquareObject.instance.setPosition(position)

    const radioSquareInMeters = calcDistanceToPoints(findSquareObjectToUpdate.position.lat, findSquareObjectToUpdate.position.lng,
      position.lat, position.lng);


    const coordinatesFromCenter = createSquareBoundingboxByMilesDistance(findSquareObjectToUpdate.position.lat,
      findSquareObjectToUpdate.position.lng,
      radioSquareInMeters);

    const boundsToSquare = {
      north: coordinatesFromCenter[0].latitude,
      south: coordinatesFromCenter[1].latitude,
      east: coordinatesFromCenter[0].longitude,
      west: coordinatesFromCenter[2].longitude,
    };
    findSquareObjectToUpdate.boundsRectangle = boundsToSquare;

    //update the square size
    findSquareObjectToUpdate.instance.setBounds(boundsToSquare)
    //center the element on map
    centerByFitBounds(boundsToSquare);

    const coordinates = convertBoundsToCoordinates(boundsToSquare)
    findSquareObjectToUpdate.coordinates = coordinates;

    const distanceInMetters = calcDistanceToPoints(findSquareObjectToUpdate.position.lat,
      findSquareObjectToUpdate.position.lng,
      position.lat,
      position.lng);

    findSquareObjectToUpdate && findSquareObjectToUpdate?.onUpdateList && findSquareObjectToUpdate?.onUpdateList(distanceInMetters)

    return position
  }

  /************************************************************************
   * Update the styles at the polygon create object 
   ************************************************************************/
  const updateStyleToCreateObject = () => {
    //get the polygon object
    const polygonCreateSettings = mapObjects.find((item) => item.type == TypeObjectMap.Settings && item.id == 'mapCreateObjectSettings')
    const polygonObject = mapObjects.find((item) => item.type == TypeObjectMap.Polygon && item.layerName == TypeLayer.PolygonCreate);
    if (polygonCreateSettings && polygonObject) {
      const { color, strokeColor } = polygonCreateSettings
      //find the polygon and change the styles
      polygonObject.instance.setOptions({ strokeColor: strokeColor, fillColor: color });

      //find the markers and update the icons
      const markersOnPolygon = mapObjects.filter((item) => item.type == TypeObjectMap.Marker && item.layerName == TypeLayer.PolygonCreate)
      if (markersOnPolygon) {
        const newMarkerStyle = getIconForPolygonCreate(color); //get new style for icons
        markersOnPolygon.forEach((marker) => {
          marker.instance.setIcon(newMarkerStyle)
        })
      }
    }

    //if is circle object
    const circleObject = mapObjects.find((item) => item.type == TypeObjectMap.Circle && item.layerName == TypeLayer.CircleCreate);
    if (polygonCreateSettings && circleObject) {
      const { color, strokeColor } = polygonCreateSettings
      //find the polygon and change the styles
      circleObject.instance.setOptions({ strokeColor: strokeColor, fillColor: color });
    }

    //if is square object
    const squareObject = mapObjects.find((item) => item.type == TypeObjectMap.Square && item.layerName == TypeLayer.SquareCreate);
    if (polygonCreateSettings && squareObject) {
      const { color, strokeColor } = polygonCreateSettings
      //find the polygon and change the styles
      squareObject.instance.setOptions({ strokeColor: strokeColor, fillColor: color });
    }
  }

  /************************************************************************
   * Validate the parameters to square object
   * @param {object} mouseEvent 
   * @returns 
   ************************************************************************/
  const validateValidSquare = (mouseEvent) => {
    const { lat, lng } = mouseEvent.latLng.toJSON();
    if (lat > squareLatMin)
      return true //is not valid

    if (lng < squareLngMin)
      return true //is not valid

    return false; //not found errors
  }

  /************************************************************************
   * Return the value to square lat / lng, preveng the overlap lines to square control
   * @param {float} position 
   * @param {float} newPosition 
   * @param {float} valueDecrease 
   * @returns value to drecrease
   ************************************************************************/
  const decreaseLatitudeToDrawSquare = (position, newPosition) => {
    let absoluteValuePos = Math.abs(position), absoluteValueNewPos = Math.abs(newPosition);
    let valueToDecrease = absoluteValuePos > absoluteValueNewPos
      ? absoluteValuePos - absoluteValueNewPos
      : absoluteValueNewPos - absoluteValuePos;
    valueToDecrease = valueToDecrease * 3;
    if (position >= 0 && newPosition > 0) {
      return valueToDecrease * -1
    } else if (position >= 0 && newPosition < 0) {
      return valueToDecrease * -1
    } else if (position <= 0 && newPosition < 0) {
      return valueToDecrease * -1
    }
    return valueToDecrease;
  }

  const decreaseLongitudeToDrawSquare = (position, newPosition) => {
    let absoluteValuePos = Math.abs(position), absoluteValueNewPos = Math.abs(newPosition);
    let valueToDecrease = absoluteValuePos > absoluteValueNewPos
      ? absoluteValuePos - absoluteValueNewPos
      : absoluteValueNewPos - absoluteValuePos;
    valueToDecrease = valueToDecrease * 3;
    if (position <= 0 && newPosition < 0) {
      return valueToDecrease
    } else if (position >= 0 && newPosition > 0) {
      return valueToDecrease
    } else if (position <= 0 && newPosition > 0) {
      return valueToDecrease
    }
    return valueToDecrease;
  }

  /******************************************************
   * Add the Polygon objecto into google maps to google.maps.Polygon
   * @param {string} id of mapObject 
   ******************************************************/
  const movePolygonPoint = useCallback((mouseEvent, id, markerObject) => {
    //poly.getPath().removeAt(polyIndex);

    const mapObjectPolygon = mapObjects.find((item) => item.id == id);
    if (mapObjectPolygon) {
      const path = mapObjectPolygon.instance.getPath();

      if (path.getArray().length > 0) {
        path.getArray()[markerObject.index - 1] = mouseEvent.latLng.toJSON();
      }

      //TODO: make the validation polygon
      mapObjectPolygon.instance.setPath(path.getArray());

      /* const validationResult = validatePolygon(tempPath.Kb)
      if(validationResult.status && markerObject.index > 1){
        //Update new position polygon
        mapObjectPolygon.instance.setPath(tempPath.Kb);
      }else{
        markerObject.instance.setPosition(oldValue)
      } */
    }
  })

  /******************************************************
   * Remove the point at the polygon
   * @param {string} id of mapObject
   * @param {Object} markerObject of mapObject 
   ******************************************************/
  const removePolygonPoint = useCallback((id, markerObject) => {
    const mapObjectPolygon = mapObjects.find((item) => item.id == id);
    if (mapObjectPolygon) {
      const index = markerObject.index;
      const path = mapObjectPolygon.instance.getPath();

      if (path.getArray().length > 0) {
        path.getArray().splice(index - 1, 1);
      }

      mapObjectPolygon.instance.setPath(path.getArray());

      removeItemsByIdAndType([markerObject.id])
      let markerList = mapObjects.filter((item) => item.layerName == markerObject.layerName &&
        item.type == TypeObjectMap.Marker);
      // sort by value
      markerList.sort(function (a, b) {
        return a.index - b.index;
      });

      let countIndex = 1
      markerList.forEach(item => {
        item.index = countIndex
        countIndex += 1
      });

      //Rename elements
      markerList = markerList.map(item => {
        item.id = `markerLandmark_${item.index}`
        return item
      })

      mapClickItems -= 1;
    }
  })

  /******************************************************
   * Get the coordinates from polygon
   ******************************************************/
  const getPolygonCoordinates = useCallback(() => {
    const mapObjectPolygonMarkers = mapObjects.filter((item) => item.type == TypeObjectMap.Marker &&
      item.layerName == TypeLayer.PolygonCreate);
    if (mapObjectPolygonMarkers && mapObjectPolygonMarkers.length > 0) {

      const LatLngList = [], coordinates = []
      mapObjectPolygonMarkers.forEach((element) => {
        LatLngList.push(new google.maps.LatLng(element.instance.getPosition().lat(), // eslint-disable-line no-undef
          element.instance.getPosition().lng()));
        coordinates.push({
          latitude: element.instance.getPosition().lat(),
          longitude: element.instance.getPosition().lng()
        });
      })
      //Close the polygon object
      LatLngList.push(LatLngList[0])
      coordinates.push(coordinates[0])

      let latlngbounds = new google.maps.LatLngBounds(); // eslint-disable-line no-undef

      LatLngList.forEach((latLng) => {
        latlngbounds.extend(latLng);
      });

      let center = latlngbounds.getCenter();
      return { coordinates, center: { latitude: center.lat(), longitude: center.lng() } };
    }
    return { coordinates: [], center: { latitude: 0, longitude: 0 } };
  })

  /******************************************************
   * Get the circle object to create 
   ******************************************************/
  const getCircleCoordinates = useCallback(() => {
    const mapObjectCircleMarker = mapObjects.find((item) => item.type == TypeObjectMap.Marker &&
      item.layerName == TypeLayer.CircleCreate);
    if (mapObjectCircleMarker) {
      const coordinates = [];
      coordinates.push({
        latitude: mapObjectCircleMarker.instance.getPosition().lat(),
        longitude: mapObjectCircleMarker.instance.getPosition().lng()
      });
      return coordinates;
    }
    return null;
  })

  /******************************************************
   * Reduce the click items into the view
  ******************************************************/
  const reduceMapClickItems = useCallback(() => mapClickItems -= 1)

  /*******************************************************
   * Create the icon into the realtime map
   * @param {string} iconColor | color to icon
   * @param {int} rotation | grades to rotation
   ******************************************************/
  const getIconAttributes = (paramIconColor, rotation, url = null, styleImage = {}, mapObject = null, path = null) => {
    //BACKWARD_CLOSED_ARROW, BACKWARD_OPEN_ARROW, CIRCLE, FORWARD_CLOSED_ARROW, FORWARD_OPEN_ARROW
    let iconColor = '#d7d7d6'; //set the default color to markers

    const labelPosition = new google.maps.Point(20, 110); // eslint-disable-line no-undef

    if (paramIconColor) {
      iconColor = paramIconColor.toString().replace('##', '#');
    }

    if (url) {
      let imageIcon = {
        labelOrigin: labelPosition,
        url: url,
        scale: 1.8, //Yessica
        fillColor: iconColor,
        fillOpacity: 1,
        strokeColor: 'white', //'#6f6f6f',
        sstrokeWeight: 2,
        rotation: parseInt(rotation),
        anchor: new google.maps.Point(9, 9)  // eslint-disable-line no-undef
      };
      if (styleImage) {
        delete imageIcon['scale']
        delete imageIcon['rotation']
        imageIcon = {
          ...imageIcon,
          labelOrigin: labelPosition,
          ...styleImage
        }
      }
      return imageIcon;
    }

    if (mapObject) {
      const pathImage = changePathMarker(mapObject.eventName)
      const routeMarkers = mapObject.hasVideo ? 'markervideo' : 'marker'
      //get direction Image
      const imageRotation = changeMarker(rotation, `/assets/icons/${routeMarkers}/${pathImage}/`);

      return {
        labelOrigin: labelPosition, // eslint-disable-line no-undef
        url: imageRotation,
        origin: new google.maps.Point(0, 0), // eslint-disable-line no-undef
        //x-y  
        anchor: new google.maps.Point(20, 20) // eslint-disable-line no-undef
      };
    } else {
      return {
        labelOrigin: labelPosition, // eslint-disable-line no-undef
        //path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW, // eslint-disable-line no-undef
        path: path ? path : `
          M 12.11,2.48
          C 12.11,2.48 4.61,20.77 4.61,20.77
            4.61,20.77 5.32,21.48 5.32,21.48
            5.32,21.48 12.11,18.48 12.11,18.48
            12.11,18.48 18.90,21.48 18.90,21.48
            18.90,21.48 19.61,20.77 19.61,20.77
            19.61,20.77 12.11,2.48 12.11,2.48 Z`,
        //size: new google.maps.Size(30, 30),
        scale: 1.8,
        fillColor: iconColor,
        fillOpacity: 1,
        strokeColor: 'white', //'#6f6f6f',
        sstrokeWeight: 2,
        rotation: parseInt(rotation),
        //x-y  
        anchor: new google.maps.Point(12, 16), // eslint-disable-line no-undef
        ...styleImage
      };
    }
  };

  /*******************************************************
   * Get the icon style for polygon create on map
   * @param {string} color the string must be contain the # char
   *******************************************************/
  const getIconForPolygonCreate = (color) => {
    const markerPolygon = window.btoa(polygonMarkerPointSvg(color));
    const iconMarker = {
      url: `data:image/svg+xml;base64,${markerPolygon}`,
      scaledSize: new google.maps.Size(18, 18),
      anchor: new google.maps.Point(9, 9)  // eslint-disable-line no-undef
    };
    return iconMarker;
  }

  /*******************************************************
   * Create the cluster element to Cluster Icons Markers
   * @param {array} markers 
  *******************************************************/
  const showMarkerCluster = useCallback((show = false, realtimeMap = false, showLabels = false) => {
    const id = 'unitCluster'
    let objectCluster = getObject(TypeObjectMap.MarkerCluster, id)
    //if (show && !objectCluster || (objectCluster && !objectCluster.instance) || (realtimeMap && objectCluster && objectCluster.instance)) {
    if (show && !objectCluster || show && objectCluster && !objectCluster.instance) {
      if (!objectCluster) {
        objectCluster = new MapObject().buildMarkerCluster({
          id: id
        })
      }

      const markers = []
      mapObjects.forEach((item) => {
        if (item.type == TypeObjectMap.Marker && item.layerName == TypeLayer.Units && (item.instance && item.instance.getMap())) {
          markers.push(item.instance)
        }
      })

      if (markers.length <= 0) {
        mapObjects.push(objectCluster)
        return
      }

      const svgBlue = window.btoa(clusterSvgString('#1aa0da'));
      const svgYellow = window.btoa(clusterSvgString('#e8a317'));
      const svgRed = window.btoa(clusterSvgString('#ff0000'));

      const clusterMarkers = new MarkerClusterer({
        markers,
        map: mapItem,
        algorithm: new SuperClusterAlgorithm({ maxZoom: maxClusterZoom, radius: 200/* , minPoints: 5, radius: 60 */ }),
        renderer: {
          render: ({ markers, _position: position }) => {
            let varSvg = svgBlue
            if (markers.length > 20 && markers.length < 100) {
              varSvg = svgYellow
            } else if (markers.length >= 100) {
              varSvg = svgRed
            }
            return new google.maps.Marker({
              position,
              icon: {
                url: `data:image/svg+xml;base64,${varSvg}`,
                scaledSize: new google.maps.Size(50, 50),
              },
              label: { text: String(markers.length), color: "white", fontSize: "15px" },
            });
          },
        },
      });

      //remove old marker Cluster
      restarLabelsToDefault()
      removeAllbyItemObject(TypeObjectMap.MarkerCluster)
      objectCluster.instance = clusterMarkers
      mapObjects.push(objectCluster)

      if (showLabels) {
        addMapClusterListener()
      }

    } else if (!show && objectCluster && objectCluster?.instance) {
      //remove cluster units
      objectCluster.instance.clearMarkers();

      //show the units in map again
      props?.showUnitsByPreferences && props.showUnitsByPreferences()
      //showLayer(TypeLayer.Units, true);

      restarLabelsToDefault();
      removeMapClusterListener();
      removeAllbyItemObject(TypeObjectMap.MarkerCluster);

      const objectNoOverlapSettings = getObject(TypeObjectMap.Settings, 'noOverlapSettings');
      if (objectNoOverlapSettings && objectNoOverlapSettings.enable) {
        startingOverlapSettings()
      }
    }
  })


  /*******************************************************
   * Create the cluster icon elements to landmarks
   * @param {array} landmarks 
   *******************************************************/
  /* eslint-disable no-unused-vars */
  const clusterIconsLandmark = (landmarks) => {
    // Add a marker clusterer to manage the markers.
    const clusterLandmark = new MarkerClusterer(mapItem, landmarks, { // eslint-disable-line no-undef
      imagePath: "/src/components/Map/svg/map_cluster",
      maxZoom: 20,
      imageExtension: "svg",
      Size: 60
    });

    clusterLandmark.setImageSizes(60);
    clusterLandmark.setClusterClass('circleMarkerClass');
    return clusterLandmark;
  }
  /* eslint-enable no-unused-vars */

  /*******************************************************
   * Draw the landmark temp into the realtime map
   *******************************************************/
  const drawLandmark = useCallback((item, radius, coordinates, clickEvent = false) => {
    const mapObject = new MapObject().buildMarker({
      id: clickEvent ? item.id : 'tempObject01', //load the id from element
      position: { lat: item.latitude, lng: item.longitude },
      layerName: TypeLayer.TemporalObjects,
      label: '',
      heading: 0,
      color: '000',
      eventName: 'no event',
      draggable: false,
      hideMarker: item?.hideMarker
    });

    const marker = addMapObject(mapObject)

    if (clickEvent && marker && marker.instance) {
      marker.instance.addListener('click', () => {
        //Return marker click option
        if (props.modalPopupEvent) {
          const findMapObject = mapObjects.find(item => item.id == marker.id)
          const obj = {
            id: findMapObject.id,
            position: findMapObject.instance.getPosition(),
            icon: {
              rotation: 0
            },
            layerName: TypeLayer.Landmark
          }
          props.modalPopupEvent(obj, obj);
        }
      })
    }

    if (radius > 0) { //is circle
      //Create the Circle Object
      const circle = new MapObject().buildCircle({
        id: 'tempObject02', //Ref to move elements in marker event
        position: coordinates[0],
        layerName: TypeLayer.TemporalObjects,
        label: '',
        radius: radius,
        heading: 0,
        color: '#87aefc',
        eventName: 'no event',
        draggable: true
      })
      addMapObject(circle)
      return true
    } else if (coordinates && coordinates.length > 0) { //polygon
      const tempCoordinates = coordinates.map(element => {
        return { lat: element.latitude, lng: element.longitude }
      })
      addMapObject(new MapObject().buildPolygon({
        id: 'tempObject03',
        coordinates: tempCoordinates,
        layerName: TypeLayer.TemporalObjects,
        label: 'Create Label...', //Add label name of landmark
        heading: 0,
        color: '#87aefc'
      }))
      return true
    }
    return false
  })

  const drawShapeObjectTempOnMap = (item, tag, isLandmark = true) => {
    let objectColor = '8677D9', landmarkIcon = null;

    objectColor = item.color ? item.color : tag?.color  //add the default color to pallete
    landmarkIcon = tag && tag.iconName ? tag.iconName : '' //get icon from landmark tag
    landmarkIcon = item.iconName ? item.iconName : getIconGroupWithAnotherColor(landmarkIcon, item.color)  // get icon from landmark

    let position = validateLatLng(item);
    let childType = null;
    if (item.shape) {
      childType = item.shape == "circle" ? TypeObjectMap.Circle : TypeObjectMap.Polygon
    } else {
      childType = item.radius > 0 ? TypeObjectMap.Circle : TypeObjectMap.Polygon
    }

    let coordinates = []
    if (childType == TypeObjectMap.Polygon && item.coordinates) {
      coordinates = item.coordinates.map(element => {
        return { lat: parseFloat(element.latitude), lng: parseFloat(element.longitude) }
      })
      if ((!position.lat || !position.lng) && coordinates && coordinates.length > 0) {
        position.lat = coordinates[0].lat.toString();
        position.lng = coordinates[0].lng.toString();
      }
    }

    if ((!position.lat || !position.lng) && item.radius > 0 && item.coordinates && item.coordinates.length > 0) {
      position = { lat: item.coordinates[0].latitude, lng: item.coordinates[0].longitude }
    }

    let mapObject = null;
    if (isLandmark) {
      mapObject = new MapObject().buildLandmark({
        id: `tempObject01_${item.id}`,
        index: item.id,
        position: { lat: item.latitude, lng: item.longitude },
        groupId: item && item.tagsId ? item.tagsId.split(',') : [],
        url: landmarkIcon,
        radius: radiusFeetToMeters(item.radius),
        layerName: TypeLayer.TemporalObjects,
        label: cutNotes(item.name, 30),
        showLabel: false,
        hasChild: true,
        childType: childType,
        coordinates: item.radius == 0 ? coordinates : [],
        color: `#${objectColor}`
      })
    } else {
      mapObject = new MapObject().buildGeofence({
        id: `tempObject01_${item.id}`,
        index: item.id,
        position,
        groupId: item && item.tagsId ? item.tagsId.split(',') : [],
        radius: item.radius,
        layerName: TypeLayer.TemporalObjects,
        label: cutNotes(item.name, 30),
        showLabel: false,
        hasChild: false,
        childType: childType,
        coordinates: coordinates,
        color: `#${objectColor}`
      })
    }

    addMapObject(mapObject)
  }

  /*******************************************************
   * Suscription to props.units
   *******************************************************/
  const validateNewObject = (mapObject) => {
    let validate = true
    if (!mapObject.position || mapObject.position && (!mapObject.position.lat || !mapObject.position.lng)) {
      validate = false
    } else if (existsObject(mapObject)) {
      validate = false
    }
    return validate
  }

  /*******************************************************
   * Center the object into the realtime maps
  *******************************************************/
  const centerObjectOnMap = useCallback((type, id, zoom = 16, panBy = 0, getInstance = false, currentZoom = false) => {
    if (!mapObjects) {
      return
    }

    if(currentZoom && mapItem){
      zoom =  mapItem?.getZoom();
    }

    const mapObject = mapObjects.find((element) => element.type == type && element.id == id);
    if (mapObject) {
      if(getInstance || mapObject?.instance) {
        centerPointOnMap(mapObject?.instance?.getPosition()?.lat(), mapObject?.instance?.getPosition()?.lng(), zoom, panBy);
      }else{
        centerPointOnMap(mapObject.position?.lat, mapObject.position?.lng, zoom, panBy);
      }
      return mapObject
    } else {
      //Only set zoom elements
      if (mapItem) {
        mapItem.setZoom(zoom);
      }
    }
    return null
  })

  /*******************************************************
   * Center the object into the realtime maps
   * @param {TypeObjectMap} is TypeObjectMap object
   * @param {string} id Element for mapObject
  *******************************************************/
  const getObject = useCallback((type, id = '') => {
    if (id) {
      const mapObject = mapObjects.find((element) => element.type == type && element?.id?.toString() == id?.toString());
      if (mapObject) {
        return mapObject
      }
    }
    return null
  })

  /*******************************************************
   * Center the object into the realtime maps
  *******************************************************/
  const getObjectsByType = useCallback((type) => {
    const mapObject = mapObjects.find((element) => element.type == type);
    if (mapObject) {
      return mapObject
    }
    return null
  })

  /*******************************************************
   * Center the object into the realtime maps
  *******************************************************/
  const getObjectsByTypeByLayer = useCallback((type, layer = TypeLayer.Default) => {
    let mapObject = mapObjects.filter((element) => element.type == type && element.layerName == layer);

    if (mapObject) {
      return mapObject
    }
    return null
  })

  /*******************************************************
   * Set enable true or false by idGroup
  *******************************************************/
  const setEnableObjectsByGroup = useCallback((type, groupId, enable = true) => {
    const mapElements = mapObjects.filter((element) => element.type == type && element.groupId.includes(groupId));

    if (mapElements && mapElements.length > 0) {
      mapElements.forEach((element) => {
        element.enable = enable
      })
    }
  })

  /*******************************************************
   * Set enable true or false by idGroup
  *******************************************************/
  const setEnableObjectsByType = useCallback((type, enable = true) => {
    const mapElements = mapObjects.filter((element) => element.type == type);

    if (mapElements && mapElements.length > 0) {
      mapElements.forEach((element) => {
        element.enable = enable
      })
    }
  })

  /*******************************************************
   * Update the mapType view in realtime map
   *******************************************************/
  const updateMapType = useCallback((type) => {
    if (mapItem) {
      mapItem.setMapTypeId(type);
    }
  })

  /*******************************************************
   * Show the vehicle trails components into the realtime maps
   *******************************************************/
  const showVehicleTrails = useCallback((showVehicleTrails) => {
    if (showVehicleTrails) {
      //remove old vehicletrails layers, able to pagination
      removeLayer(TypeLayer.VechileTrails);
      //hide marker cluster
      showMarkerCluster(false);
      //hide Markers
      showLayer(TypeLayer.Units, false);
      showLayer(TypeLayer.UnitLabel, false);
    } else {
      //hide trails
      removeLayer(TypeLayer.VechileTrails);
      //show Markers
      props?.showUnitsByPreferences && props.showUnitsByPreferences()

      const objectUnitCluster = getObject(TypeObjectMap.Settings, 'unitClusterSettings');
      const objectShowLabelSettings = getObject(TypeObjectMap.Settings, 'unitLabelSettings');
      //const objectNoOverlapSettings = getObject(TypeObjectMap.Settings, 'noOverlapSettings');

      const showLabel = (objectShowLabelSettings && objectShowLabelSettings.enable) || false;
      const showCluster = (objectUnitCluster && objectUnitCluster.enable) || false

      if (showLabel) {
        if (!showCluster) {
          addLabelMarkers(TypeLayer.UnitLabel, true, false)
        } else {
          showOrHideLabelsOnCluster()
        }
      } else {
        removeLayer(TypeLayer.UnitLabel)
        if (showCluster && !showLabel) {
          removeMapClusterListener();
        }
      }

      if (showCluster) {
        if (!showLabel) {
          removeMapClusterListener()
        }
        showMarkerCluster(true, false, showLabel)
      }
    }
  })

  /*******************************************************
   * Center the map with the coordinates to params, panBy can be used for to move the center area vertically
   * @param {float} lat 
   * @param {float} lng 
   * @param {int} zoom 
   * @param {int} panBy  
   *******************************************************/
  const centerPointOnMap = useCallback((lat, lng, zoom = 0, panBy, milliseconsToPanBy = 0) => {
    if (!mapItem || !lat || !lng) {
      return;
    }

    if (zoom) {
      mapItem.setZoom(zoom);
      if(!isNaN(lat) && !isNaN(lng)){
        mapItem.panTo({ lat: lat, lng: lng });
        if (panBy) {
          if(milliseconsToPanBy > 0){
            mapItem.panBy(0, panBy, milliseconsToPanBy);
          }else{
            mapItem.panBy(0, panBy);
          }
        }
      }
    } else {
      centerByFitBounds([{ lat: lat, lng: lng }]);
    }
  })

  /*******************************************************
   * Add the event to add map click
  *******************************************************/
  const initMapClick = useCallback((eventClick = null) => {
    if (mapItem) {
      mapClickEvent = mapItem.addListener("click", (mapsMouseEvent) => { // eslint-disable-line no-undef
        if (mapsMouseEvent && eventClick) {
          mapClickItems = mapClickItems + 1
          eventClick(mapsMouseEvent.latLng.toJSON(), mapsMouseEvent, mapClickItems)
        }
      })
    }
  })

  /*******************************************************
   * Add the event to remove map click
  *******************************************************/
  const removeMapClick = useCallback(() => {
    if (mapClickEvent) {
      google.maps.event.removeListener(mapClickEvent); // eslint-disable-line no-undef
      mapClickItems = 0
    }
  })


  /*******************************************************
   * Add the event to cluster 
  *******************************************************/
  const addMapClusterListener = () => {
    if (!mapClusterEvent) {
      const id = 'unitCluster'
      let clusterMarkers = getObject(TypeObjectMap.MarkerCluster, id)
      if (clusterMarkers && clusterMarkers.instance) {
        mapClusterEvent = google.maps.event.addListener(clusterMarkers.instance, 'clusteringend', showOrHideLabelsOnCluster); // eslint-disable-line no-undef
      }
    }
  }

  /*******************************************************
   * Remove the cluster event 
  *******************************************************/
  const removeMapClusterListener = useCallback(() => {
    if (mapClusterEvent) {
      google.maps.event.removeListener(mapClusterEvent); // eslint-disable-line no-undef
      mapClusterEvent = null;
    }
  })

  /*******************************************************
   * Add the event to cluster 
  *******************************************************/
  const addMapClickRouteListener = () => {
    if(!mapClickRouteEvent && mapItem){
      mapClickRouteEvent = mapItem.addListener("click", (mapsMouseEvent) => { // eslint-disable-line no-undef
        if(props?.onRouteMapClick){
          props.onRouteMapClick()
        }
      })
    } 

  }

  /*******************************************************
   * Remove the cluster event 
  *******************************************************/
  const removeMapClickRouteListener = useCallback(() => {
    if (mapClickRouteEvent && mapItem) {
      google.maps.event.removeListener(mapClickRouteEvent); // eslint-disable-line no-undef
      mapClickRouteEvent = null;
    }
  })

  /*******************************************************
   * Remove Route events
  *******************************************************/
  const removeRouteListener = useCallback(() => {
    if (eventMouseOverFirstPoint) {
      google.maps.event.removeListener(eventMouseOverFirstPoint)
      eventMouseOverFirstPoint = null;
    }

    if (eventMouseOutFirstPoint) {
      google.maps.event.removeListener(eventMouseOutFirstPoint)
      eventMouseOutFirstPoint = null;
    }

    if (eventMouseOverFirstMarker) {
      google.maps.event.removeListener(eventMouseOverFirstMarker)
      eventMouseOverFirstMarker = null;
    }

    if (eventMouseOutFirstMarker) {
      google.maps.event.removeListener(eventMouseOutFirstMarker)
      eventMouseOutFirstMarker = null;
    }

    if (eventInfoWindow) {
      google.maps.event.removeListener(eventInfoWindow)
      eventInfoWindow = null;
    }

    if (startPointMarkerDragend) {
      google.maps.event.removeListener(startPointMarkerDragend)
      startPointMarkerDragend = null;
    }

    if (endPointMarkerDragend) {
      google.maps.event.removeListener(endPointMarkerDragend)
      endPointMarkerDragend = null;
    }

    if (infoWindowMarker && infoWindowMarker.getMap()) {
      infoWindowMarker.setMap(null)
      infoWindowMarker = null;
    }
    durationRoute = 0;

  })

  /*******************************************************
   * Add the event to cluster 
   *******************************************************/
  const addMapBoundsChangedListener = () => {
    const bounds = mapItem.getBounds();

    //String lan1 and lng1 properties
    if (bounds && bounds.getSouthWest() && bounds.getNorthEast()) {
      lat1 = bounds.getSouthWest().lat(), lat2 = bounds.getNorthEast().lat();
      lng1 = bounds.getSouthWest().lng(), lng2 = bounds.getNorthEast().lng();
    }

    if (!mapBoundsChangedEvent) {
      mapBoundsChangedEvent = mapItem.addListener('bounds_changed', (e) => { // eslint-disable-line no-undef
        //Remove weather layer until the 
        if (isWeatherEnabled) {
          onEnableWeatherBounds = false;
          mapItem?.setOptions({ minZoom: 0, maxZoom: 22 });
          mapItem?.overlayMapTypes.removeAt(0);
        }

        if (timerLastEventCount) {
          clearInterval(timerLastEventCount)
        }
        timerLastEventCount = setInterval(() => { onEndBoundsChanged() }, 300)
      });
    }
  }

  /*******************************************************
   * Remove the cluster event 
  *******************************************************/
  const removeMapBoundsChangedListener = useCallback(() => {
    if (mapBoundsChangedEvent) {
      google.maps.event.removeListener(mapBoundsChangedEvent); // eslint-disable-line no-undef
      mapBoundsChangedEvent = null;
    }
  })

  /*******************************************************
   * Remove the cuslter event 
  *******************************************************/
  const removeMapDraw = useCallback(() => {
    if (mapMouseUpEvent && mapMouseDownEvent) {
      google.maps.event.removeListener(mapMouseUpEvent); // eslint-disable-line no-undef
      google.maps.event.removeListener(mapMouseDownEvent); // eslint-disable-line no-undef
      google.maps.event.clearListeners(mapItem.getDiv(), 'mousedown');
      enable();
      mapMouseUpEvent = null;
      mapMouseDownEvent = null;
    }
  })

  /*******************************************************
   * Center the vehicles into the map
  *******************************************************/
  const centerVehicles = useCallback(() => {
    const vehicles = mapObjects.filter(
      item => item.type == TypeObjectMap.Marker &&
        item.layerName == TypeLayer.Units &&
        item.position.lat &&
        item.position.lng &&
        (item.instance && item.instance.getMap()))
    if (vehicles && vehicles.length > 0) {
      const latLng = vehicles.map(item => {
        return { lat: item.position.lat, lng: item.position.lng }
      })
      centerByFitBounds(latLng)
    }
  })

  /*******************************************************
  * Center the map animated
  *******************************************************/
  const centerMapAnimated = useCallback((lat, lng) => {
    //validate is number
    if (!isNaN(parseFloat(lat)) && isFinite(lat) || !isNaN(parseFloat(lng)) && isFinite(lng)) {
      return;
    }

    mapItem.panTo(new google.maps.LatLng(parseFloat(lat), parseFloat(lng))); // eslint-disable-line no-undef
    if (openInfoWindow) mapItem.panBy(0, -190);
  })

  /*******************************************************
  * Center map by lat lng positions
  *******************************************************/
  const centerByFitBounds = useCallback((arrayLatLng, radius = 0) => {
    let center = null

    if (arrayLatLng) {
      const LatLngList = []
      try {
        arrayLatLng.forEach((element) => {
          if (!isNaN((element.lat || element.latitude)) && !isNaN((element.lng || element.longitude))) {
            LatLngList.push(new google.maps.LatLng(element.lat ? element.lat : element.latitude, // eslint-disable-line no-undef
              element.lng ? element.lng : element.longitude))
          }
        })
      } catch (error) {
      }
      

      if (LatLngList && LatLngList.length == 0) return;

      let latlngbounds = new google.maps.LatLngBounds(); // eslint-disable-line no-undef

      LatLngList.forEach((latLng) => {
        latlngbounds.extend(latLng);
      });

      mapItem.setCenter(latlngbounds.getCenter());
      center = latlngbounds.getCenter();
      if (radius == 0) {
        mapItem.fitBounds(latlngbounds);
      } else {
        var circle = new google.maps.Circle({ // eslint-disable-line no-undef
          map: mapItem,
          radius: radius,
          center: LatLngList[0]
        });
        mapItem.fitBounds(circle.getBounds());
        circle.setMap(null);
        circle = null;
      }
    }
    return center;
  })

  /*******************************************************
   * Draw Route into the realtime maps.
  *******************************************************/
  const drawRoute = useCallback((propsMap, mapObjectRoute, points, errorDrawRoute, otherProps = {}) => {
    
    const directionsRenderer = mapObjectRoute.instance
    let updatePointers = false;
    let labelOnMarker = ''

    const isRouteModule = otherProps && otherProps?.routeModule

    if (points && points.length > 1) {
      var directionsService = new google.maps.DirectionsService(); // eslint-disable-line no-undef

      //start route
      const start = isRouteModule ? getPositionMarker(points[0].id, points[0]?.layerName) : getPositionMarker(points[0].id);
      //end route
      const end = isRouteModule ? getPositionMarker(points[points.length - 1].id, points[0]?.layerName) : getPositionMarker(points[points.length - 1].id);

      if (!start || !end) return;

      const arrayWaypoints = [];

      if (points.length == 2) {
        points.forEach((item) => {
          labelOnMarker = !labelOnMarker ? item?.label : labelOnMarker
          if (!updatePointers)
            updatePointers = item?.updatePointers ? true : false
        })
      }
      
      if (points.length > 2) {
        points.forEach((item, index) => {
          if (index != 0 && index != points.length - 1) {
            const position = isRouteModule ? getPositionMarker(item.id, points[0]?.layerName || null) : getPositionMarker(item.id);
            if (position) {
              arrayWaypoints.push({
                location: position,
                stopover: true
              })
            }
          }
        })
      }

      let propsToRoute = {
        ...otherProps
      }

      //get the limit
      let limit = 0;
      if (propsToRoute.limit) {
        limit = propsToRoute.limit;
        //remove the props no valid to the structure
        delete propsToRoute['limit'];
      }
      //get the color
      let color = "";
      if (propsToRoute.color) {
        color = propsToRoute.color;
        //remove the props no valid to the structure
        delete propsToRoute['color'];
      }
      delete propsToRoute['draggable'];
      delete propsToRoute['hideLabelsOnMarkers'];
      delete propsToRoute['onErrorRoute'];
      delete propsToRoute['saveCoordinates'];
      delete propsToRoute['drawSpeedLimits'];
      delete propsToRoute['isSpeedOverride'];
      delete propsToRoute['startPointColor'];
      delete propsToRoute['endPointColor'];      
      delete propsToRoute['callbackWayPoints'];
      delete propsToRoute['showDirectionsPanel'];
      delete propsToRoute['callbackDistancePoints'];
      delete propsToRoute['noDrawMap'];
      delete propsToRoute['preserveViewport'];

      var request = {
        ...propsToRoute,
        origin: start,
        destination: end,
        waypoints: arrayWaypoints,
        provideRouteAlternatives: false,
        travelMode: google.maps.TravelMode.DRIVING, // eslint-disable-line no-undef
        unitSystem: google.maps.UnitSystem.IMPERIAL, // eslint-disable-line no-undef
      };

      //implements new route module
      if(isRouteModule){

        if(mapObjectRoute?.mapRoute){
          mapObjectRoute.mapRoute.removeRoute()
          mapObjectRoute.mapRoute = null;
        }

        let newProps = {}
        if(otherProps){
          newProps = {
            ...otherProps,
            opUpdateDirectionRenderer : (instanceDirectionRenderer) => {
              const validateInstanceRouteOnMap = getObject(TypeObjectMap.Routes, mapObjectRoute.id)
              if(validateInstanceRouteOnMap){
                validateInstanceRouteOnMap.instance = instanceDirectionRenderer; 
              }else if(instanceDirectionRenderer){
                //clear old instances for direction renderer
                instanceDirectionRenderer.setMap(null);
              }
            }
          }
          
        }

        const instanceMapRoute = new MapRoute({ 
          mapItem, 
          propsMap, 
          directionsRenderer, 
          points, 
          errorDrawRoute, 
          otherProps : newProps,
          request,
          googleProviderReference: instance
        })
        
        instanceMapRoute.drawRoute();

        //set the instance into mapObject
        mapObjectRoute.mapRoute = instanceMapRoute; 
        return;
      }

      directionsService.route(request, function (response, status) {
        if (status == 'OK') {

          //validate limit in miles
          if (response && limit > 0) {
            const toMiles = (response.routes[0].legs[0].distance.value / 1609).toFixed(1);
            if (toMiles > limit) {
              //remove the last point on map
              otherProps?.onErrorRoute && otherProps?.onErrorRoute(points)
              //no draw route
              const existMarker = getObject(TypeObjectMap.Marker, `temporalRouteMarker_startMarker`)

              if (!existMarker) {
                var startMarker = new google.maps.Marker({
                  position: response.routes[0].legs[0].start_location,
                  map: mapItem,
                  draggable: false,
                  icon: {
                    //url: `${pathFile}/icons/maps/place/DE350D.png`,
                    url: otherProps?.isSpeedOverride ? `/assets/icons/pin_destination.png` : `/assets/icons/pin_vehicle.png`,
                    scaledSize: new google.maps.Size(30, 30),
                    size: new google.maps.Size(30, 30),
                    anchor: new google.maps.Point(15, 15)
                  }
                });
                //create temporal marker
                tempMapObjectRoute('startMarker', startMarker, { lat: response.routes[0].legs[0].start_location.lat(), lng: response.routes[0].legs[0].start_location.lng() });
              }

              return false
            }
          }

          //into the draw Speed Limits on map
          if(otherProps?.drawSpeedLimits && response && response?.routes && response?.routes[0]){
            const route = response.routes[0];
            const polyline = route?.overview_polyline;
            const path = google.maps.geometry.encoding.decodePath(polyline);
            //const encodedPath = google.maps.geometry.encoding.encodePath(path);
            getSpeedValues(path, onDrawSpeedLimits)
          } else {
            directionsRenderer.setDirections(response);
          }

          if (otherProps?.saveCoordinates) {
            // get coordinates from the route
            const routeCoordinates = getRouteCoordinatesFromDirectionService(response)
            //delete old object route
            removeMapObject('routeCoordinates', TypeObjectMap.Routes)
            //create the object again
            tempMapObjectRoute('', null, routeCoordinates[0], 'routeCoordinates', routeCoordinates);
          }



          if (color != "") {
            directionsRenderer.setOptions({
              polylineOptions: {
                strokeColor: color,
                strokeOpacity: 0.7,
                strokeWeight: 5
              }
            })
          }

          if (updatePointers) // draw ETA
            drawCustomRoute(directionsRenderer, response, labelOnMarker, points, otherProps)

        }
        if (status == 'ZERO_RESULTS') {
          if (errorDrawRoute) {
            errorDrawRoute(propsMap, 'realtimemaps_route_error')
          }
        }
        otherProps?.callbackWayPoints && returnOptimizeRoutePoints(response, otherProps?.callbackWayPoints)
      });

      //Remove the points into the map
      if (!updatePointers) {
        removeLayer(TypeLayer.Pointers)
      }

      return true
    }
  })

  const onDrawSpeedLimits = (data, googleSpeedLimits) => {
    let speed_limit = null;
    let pathCoordinates = [];
    let firstChange = false;

    let speedInMiles = 0
    data.map((item, index) => {

      if (speed_limit == null) {
        speed_limit = item.speedLimit;
      }

      if (speed_limit == item.speedLimit) {

        if (index > 0 && firstChange) {
          pathCoordinates.push({
            lat: data[index - 1].lat,
            lng: data[index - 1].lng
          })
        }

        pathCoordinates.push({
          lat: item.lat,
          lng: item.lng
        });
      } else {
        firstChange = true;

        pathCoordinates.push({
          lat: item.lat,
          lng: item.lng
        });
        speedInMiles = speed_limit * 0.621371;
        addMapObject(new MapObject().buildPolyLine({
          id: `drawSpeedRoute${index}`,
          coordinates: pathCoordinates,
          layerName: TypeLayer.RouteLayer,
          heading: 0,
          color: getRouteColorFromSpeed(Math.round(speedInMiles)).color,
          fillOpacity: 0.8,
          strokeWeight: 6
        }));

        const lastElement = pathCoordinates.pop()
        pathCoordinates = [];
        pathCoordinates.push(lastElement)
        speed_limit = item.speedLimit;
      }
    });

    //add the last element before the map
    if (pathCoordinates && pathCoordinates.length > 0) {
      speedInMiles = speed_limit * 0.621371;
      addMapObject(new MapObject().buildPolyLine({
        id: `drawSpeedRoute${data.length}`,
        coordinates: pathCoordinates,
        layerName: TypeLayer.RouteLayer,
        heading: 0,
        color: getRouteColorFromSpeed(Math.round(speedInMiles))?.color,
        fillOpacity: 0.8,
        strokeWeight: 6
      }));

    }

    const keyOfSettingsRoute = 'drawSpeedRoute_dataForGoogleSpeed';
    removeMapObject(keyOfSettingsRoute, TypeObjectMap.Settings)
    //saved the speed limits into map objects
    const objectSettings = new MapObject().buildSettings({
      id: keyOfSettingsRoute,
      enable: true,
      layerName: TypeLayer.RouteLayer,
      value: JSON.stringify(googleSpeedLimits)
    });
    mapObjects.push(objectSettings);

  }



  /*******************************************************
   * Add the options to draw the custom markers and colors
   * associated to the route using the directionsRenderer object
  *******************************************************/
  const drawCustomRoute = (directionsRenderer, response, labelOnMarker, points, otherProps = {}) => {

    directionsRenderer.setOptions({
      suppressMarkers: true,
      preserveViewport: true
    });

    const { draggable, hideLabelsOnMarkers, drawSpeedLimits, isSpeedOverride } = otherProps

    const toMiles = (response.routes[0].legs[0].distance.value / 1609).toFixed(1);
    const toKilometers = (response.routes[0].legs[0].distance.value / 1000).toFixed(1);

    const distance = props.isKph ? toKilometers : toMiles;
    const speedFormat = props.isKph ? messages['kilometers'] : messages['miles'];

    const valueTime = convertSecondsToTime(response.routes[0].legs[0].duration.value || 0)

    const timeFormatToShow = `
          ${valueTime?.days > 0 ? `${valueTime?.days} ${valueTime?.days == 1 ? props?.messages['Day'] : props?.messages['Days']}` : ''} 
          ${valueTime?.hour > 0 ? `${valueTime?.hour} ${valueTime?.hour == 1 ? props?.messages['hour'] : props?.messages['hours']}` : ''} 
          ${valueTime?.min > 0 ? `${valueTime?.min} ${valueTime?.min == 1 ? props?.messages['min'] : props?.messages['mins']}` : ''} `

    removeRouteListener()

    durationRoute = response.routes[0].legs[0].duration.value;

    if(points[0]?.isUnit){
      if(points.length){
        const position = validateLatLng(points[0]);
        addMapObject(new MapObject().buildMarker({
          id: '1',
          deviceId: points[0]?.id,
          position,
          layerName: TypeLayer.Units,
          label: points[0]?.label,
          heading: points[0]?.heading,
          color: validateEventColor(points[0]),
          eventCode: points[0]?.eventCode,
          eventName: points[0]?.eventName,
          draggable: points[0]?.draggable || false,
          updatePointers: false,
          hasVideo: false,
          hideMarker: false,
          value: '',
          hasChild: false,
          isRouteTracker: false,
          trail:  null,
          trailTimestamp: 0,
          timeFormatToShow: timeFormatToShow,
          distance: distance,
          speedFormat: speedFormat
        }))
      }
    }else {
      var startPoint = new google.maps.Marker({
        position: response.routes[0].legs[0].start_location,
        map: mapItem,
        icon: {
          url: `${pathFile}/icons/maps/trip_origin/253858.png`,
          scaledSize: new google.maps.Size(15, 15),
          size: new google.maps.Size(15, 15),
          anchor: new google.maps.Point(8, -4)
        }
      });
  
      eventMouseOverFirstPoint = startPoint.addListener('mouseover', function () {
        startPoint.setLabel({
          color: '#FFFFFF',
          className: 'labelRouteMarker',
          text: labelOnMarker ? labelOnMarker.toString() : '',
        });
      });
  
      // assuming you also want to hide the infowindow when user mouses-out
      eventMouseOutFirstPoint = startPoint.addListener('mouseout', function () {
        startPoint.setLabel("");
      });
  
      tempMapObjectRoute('startPoint', startPoint, { lat: response.routes[0].legs[0].start_location.lat(), lng: response.routes[0].legs[0].start_location.lng() });
  
      var startMarker = new google.maps.Marker({
        position: response.routes[0].legs[0].start_location,
        map: mapItem,
        draggable: draggable || false,
        icon: {
          //url: `${pathFile}/icons/maps/place/253858.png`,
          url: isSpeedOverride ? `/assets/icons/pin_destination.png` : `/assets/icons/pin_vehicle.png`,
          scaledSize: new google.maps.Size(30, 30),
          size: new google.maps.Size(30, 30),
          anchor: new google.maps.Point(15, 15)
        }
      });
  
      if (draggable) {
        startPointMarkerDragend = startMarker.addListener('dragend', function (event) {
          const { lat, lng } = event.latLng.toJSON();
          let tempPointers = [...points];
          tempPointers[0].lat = parseFloat(lat || 0)
          tempPointers[0].lng = parseFloat(lng || 0)
          tempPointers[0].label = "" //clear label to search address again
          //dispatch(addPointers([]));
          dispatch(addPointers([...tempPointers]));
        });
      }
  
      if (hideLabelsOnMarkers) {
        eventMouseOverFirstMarker = startMarker.addListener('mouseover', function () {
          startPoint.setLabel({
            color: '#FFFFFF',
            className: 'labelRouteMarker',
            text: labelOnMarker ? labelOnMarker.toString() : '',
          });
        });
  
        // assuming you also want to hide the infowindow when user mouses-out
        eventMouseOutFirstMarker = startMarker.addListener('mouseout', function () {
          startPoint.setLabel("");
        });
      }
  
      if (!drawSpeedLimits) {
        infoWindowMarker = new google.maps.InfoWindow({
          content: `<div ${props?.isMobile ? 'style="margin: 3px;"' : ''}>
                      <p style="color: green; font-family: Outfit, Arial, sans-serif; font-size: 16px; font-weight: 700; margin: 0px;">
                        ${timeFormatToShow}
                      </p>
                      <p style="color: black; font-family: Outfit, Arial, sans-serif; font-size: 12px; font-weight: 500; margin: 0px;">
                        ${distance} ${speedFormat}
                      </p>
                      </div>`,
          shouldFocus: false,
        });
  
        eventInfoWindow = startMarker.addListener('click', () => {
          infoWindowMarker && infoWindowMarker.open({ anchor: startMarker, mapItem, shouldFocus: false });
        });
      }
  
  
  
      tempMapObjectRoute('startMarker', startMarker, { lat: response.routes[0].legs[0].start_location.lat(), lng: response.routes[0].legs[0].start_location.lng() });
    }

    

    var endPoint = new google.maps.Marker({
      position: response.routes[0].legs[0].end_location,
      map: mapItem,
      icon: {
        url: `${pathFile}/icons/maps/trip_origin/253858.png`,
        scaledSize: new google.maps.Size(15, 15),
        size: new google.maps.Size(15, 15),
        anchor: new google.maps.Point(8, -4)
      }
    });
    tempMapObjectRoute('endPoint', endPoint, { lat: response.routes[0].legs[0].end_location.lat(), lng: response.routes[0].legs[0].end_location.lng() });

    var endMarker = new google.maps.Marker({
      position: response.routes[0].legs[0].end_location,
      map: mapItem,
      draggable: draggable || false,
      icon: {
        url: isSpeedOverride ? `/assets/icons/pin_vehicle.png` : `/assets/icons/pin_destination.png`,
        scaledSize: new google.maps.Size(30, 30),
        size: new google.maps.Size(30, 30),
        anchor: new google.maps.Point(15, 15)
      }
    });
    tempMapObjectRoute('endMarker', endMarker, { lat: response.routes[0].legs[0].end_location.lat(), lng: response.routes[0].legs[0].end_location.lng() });

    if (draggable) {
      endPointMarkerDragend = endMarker.addListener('dragend', function (event) {
        const { lat, lng } = event.latLng.toJSON();
        let tempPointers = [...points];
        tempPointers[1].lat = parseFloat(lat || 0)
        tempPointers[1].lng = parseFloat(lng || 0)
        tempPointers[1].label = "" //clear label to search address again
        //dispatch(addPointers([]));
        dispatch(addPointers([...tempPointers]));
      });
    }

    if (!drawSpeedLimits) {
      if (props?.isMobile) {
        infoWindowMarker && infoWindowMarker.open({ anchor: startMarker, mapItem, shouldFocus: false });
        setTimeout(() => {
          infoWindowMarker && infoWindowMarker.open({ anchor: startMarker, mapItem, shouldFocus: false });
        }, 500);
      } else {
        infoWindowMarker && infoWindowMarker.open({ anchor: startMarker, mapItem, shouldFocus: false });
      }
    }

  }

  /*******************************************************
   * add the instance markers objects into the map Objects 
   * associated to the route
  *******************************************************/
  const tempMapObjectRoute = (id, instance, position, completeId = '', coordinates = []) => {
    const newMapObject = new MapObject().buildRoute({
      id: completeId ? completeId : `temporalRouteMarker_${id}`,
      position: {
        lat: parseFloat(position?.lat || 0),
        lng: parseFloat(position?.lng || 0)
      },
      layerName: TypeLayer.RouteLayer,
      heading: 0,
      color: '#B0B0B0',
      instance: instance,
      coordinates: coordinates
    })
    mapObjects.push(newMapObject)
  }

  /*******************************************************
   * Update the color at the route
  *******************************************************/
  const updateRouteStyles = useCallback((routeProperties, id = "") => {
    let objectRoute = null;
    if (id) {
      objectRoute = mapObjects.find((item) => item.id == id && item.type == TypeObjectMap.Routes && item.instance)
    } else {
      objectRoute = mapObjects.find((item) => item.type == TypeObjectMap.Routes && item.instance)
    }

    if (!objectRoute) return

    //remove the route at the map
    objectRoute.instance.setMap(null)

    objectRoute.instance.setOptions({
      polylineOptions: {
        strokeColor: routeProperties?.color,
        strokeOpacity: 0.7,
        strokeWeight: 5
      }
    })

    //repaint route on map
    objectRoute.instance.setMap(mapItem)

  })

  /*******************************************************
   * Get the positions at the marker
  *******************************************************/
  const getPositionMarker = useCallback((id, typeLayer = TypeLayer.Pointers) => {
    let pos = null;
    let mapObjectMarker = mapObjects.find((item) => item.id == id && item.layerName == typeLayer && item.type == TypeObjectMap.Marker);
    if (mapObjectMarker && mapObjectMarker.instance && mapObjectMarker.instance.getMap()) {
      pos = new google.maps.LatLng(mapObjectMarker.instance.getPosition().lat(), // eslint-disable-line no-undef
        mapObjectMarker.instance.getPosition().lng());
    }else if(mapObjectMarker?.instance?.getPosition()){
      //when the unit is not show on map
      pos = new google.maps.LatLng(mapObjectMarker.instance.getPosition().lat(), // eslint-disable-line no-undef
        mapObjectMarker.instance.getPosition().lng());
    }
    return pos;
  })

  /*******************************************************
   * Return the eta for the last route
  *******************************************************/
  const getDurationRoute = useCallback(() => {
    return durationRoute || 0;
  })

  /*******************************************************
   * Get the current zoom of the map
  *******************************************************/
  const getCurrentZoom = useCallback(() => {
    return mapItem?.getZoom();
  });

  /*******************************************************
   * Draw a polygon
  *******************************************************/
  const drawPolygon = useCallback(() => {
    if (!mapItem) return

    disable();
    mapMouseDownEvent = mapItem.addListener('mousedown', function (e) {
      removeMapObject('drawn_polygon', TypeObjectMap.Polygon);
      dispatch(setListUnits([], false));
      let poly = new google.maps.Polyline({ map: mapItem, clickable: false, strokeColor: '#00acfb', color: "#00acfb" });

      let move = google.maps.event.addListener(mapItem, 'mousemove', function (e) {
        poly.getPath().push(e.latLng);
      });

      mapMouseUpEvent = google.maps.event.addListenerOnce(mapItem, 'mouseup', function (e) {
        google.maps.event.removeListener(move);
        let path = poly.getPath();

        let bounds = [], coordinates = [];
        for (var i = 0; i < path.length; i++) {
          var point = [
            path.getAt(i).lat(),
            path.getAt(i).lng()
          ];
          coordinates.push({ lat: path.getAt(i).lat(), lng: path.getAt(i).lng() })
          bounds.push(point);
        }

        let units = getObjectsByTypeByLayer(TypeObjectMap.Marker, TypeLayer.Units); //lat, lng, id. 

        let unitSelection = []
        units.map((unit) => {
          if (pointInPolygon([unit.position.lat, unit.position.lng], bounds)) {
            unitSelection.push(+unit.id);
          }
        })
        if (unitSelection.length > 0) dispatch(setListUnits(unitSelection, false));
        else {
          dispatch(setListUnits([], true))
        }

        poly.setMap(null);

        addMapObject(new MapObject().buildPolygon({
          id: 'drawn_polygon',
          coordinates: coordinates,
          layerName: TypeLayer.UnitSelection,
          label: 'Create Label...',
          heading: 0,
          color: "#00acfb",
          fillOpacity: 0.01,
          strokeWeight: 2,
        }))
      });
    })
  });

  /*******************************************************
   * Get the geometry encoding for the object with coordinates in map
  *******************************************************/
  const getGeometryEncoded = useCallback((id = 'routeCoordinates', type = TypeObjectMap.Routes, layerName = TypeLayer.RouteLayer) => {
    const routeObject = mapObjects.find((item) => item.id == id && item.type == type && item.layerName == layerName)
    if (routeObject) {
      var encodedPolygon = google.maps.geometry.encoding.encodePath(routeObject.coordinates);
      return encodedPolygon
    }
    return null
  })

  /*******************************************************
   * draw the route asociated to the encoded Path
  *******************************************************/
  const drawPolygonWithEncodedPath = useCallback((encodedPath, color, startPoint, endPoint) => {

    if (!encodedPath) return;

    removeMapObject('polyline_decodedPath', TypeObjectMap.Polyline)
    removeLayer(TypeLayer.RouteLayer);

    var decodedPath = google.maps.geometry.encoding.decodePath(encodedPath);

    addMapObject(new MapObject().buildPolyLine({
      id: 'polyline_decodedPath',
      coordinates: decodedPath,
      layerName: TypeLayer.RouteLayer,
      heading: 0,
      color: color,
      fillOpacity: 0.7,
      strokeWeight: 5
    }));

    const coordinates = []
    decodedPath.forEach((item) => {
      coordinates.push({
        latitude: item.lat(),
        longitude: item.lng(),
      })
    })

    //center route on map
    centerByFitBounds(coordinates)

    //create start Route Marker Point
    var startMarker = new google.maps.Marker({
      position: startPoint,
      map: mapItem,
      draggable: false,
      icon: {
        //url: `${pathFile}/icons/maps/place/DE350D.png`,
        url: `/assets/icons/pin_destination.png`, //inverted the colors
        scaledSize: new google.maps.Size(30, 30),
        size: new google.maps.Size(30, 30),
        anchor: new google.maps.Point(15, 15)
      }
    });
    tempMapObjectRoute('temp_startMarker', startMarker, startPoint);

    //create end Route Marker Point
    var endMarker = new google.maps.Marker({
      position: endPoint,
      map: mapItem,
      draggable: false,
      icon: {
        url: `/assets/icons/pin_vehicle.png`, //inverted the colors
        scaledSize: new google.maps.Size(30, 30),
        size: new google.maps.Size(30, 30),
        anchor: new google.maps.Point(15, 15)
      }
    });
    tempMapObjectRoute('temp_endMarker', endMarker, endPoint);

  })

  const drawHistoryTrailOnMap = (trailArray, layer = TypeLayer.VechileTrailsTrailHistory) => {
    
    if(!isArray(trailArray) || trailArray.length < 0 ) return;

    let newIcon = IconMarkerVehicleTrailsPoint({ color: '#808080'}); //increase the number because start in 1
    let iconToRoutePoint = {
      // Convierte SVG to URL
      url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(newIcon), 
      scaledSize: new google.maps.Size(20, 20), // size of the icon
      anchor: new google.maps.Point(10, 10),
    };

    let mapObjectMarker = [];
    const arrayPositions = [];
    trailArray.forEach((item, index) => {
      const position = validateLatLng(item);
      arrayPositions.push(position)

      const mapObject = new MapObject().buildMarker({
        id: 'trailMarker' + index,
        position,
        layerName: layer,
        label: index,
        draggable: false,
        hideMarker: false,
        icon: iconToRoutePoint,
        optimized: true,
      })
      mapObjectMarker.push(mapObject)


    })
    addMapObjects(mapObjectMarker);

    //center the trails objects on the map
    //centerByFitBounds(arrayPositions);
  }

  const updateMarkersVisible = (markersIds, visible = false) => {

    if(!isArray(markersIds) || !markersIds.length) return;
    mapObjects.forEach((item) => {
      if(markersIds.includes(item.id.toString())){
        item.instance.setVisible(visible)
        item.hideMarker = !visible;
      }
    })
  }

  const enable = () => {
    mapItem.setOptions({
      draggable: true,
    });
  }

  const disable = () => {
    mapItem.setOptions({
      draggable: false,
    });
  }

  return (
    <Fragment>
      <div style={{ height: '100%', width: '100%' }} ref={divMap} className='map-container'></div>

      {/* zoom button */}
      <div className={!props.generatePrint ? "zoomButtonsIn mapButton" : "control-hidden"}>
        <Tooltip placement="left" title={messages['zoomIn']}>
          <button type="button" className='mapButtonZoom' id={'zoomIn-idcontrol'}>+</button>
        </Tooltip>
      </div>

      <div className={!props.generatePrint ? "zoomButtonsOut mapButton" : "control-hidden"}>
        <Tooltip placement="left" title={messages['zoomOut']}>
          <button type="button" className='mapButtonZoom' id={'zoomOut-idcontrol'}>−</button>
        </Tooltip>
      </div>
      {/* Map Settings button */}
      {
        HasPermission(user, "", [5]) && // TODO submodule-approved
        <div id={'mapSettings-idcontrol'} className={!props.generatePrint && !props.isMobile && !props.showEtaLink ? 'mapButton' : "control-hidden"}>
          {props.mapSettings}
        </div>
      }
      {/* Draw button */}
      <div id={'draw-idcontrol'} className={!props.generatePrint && !props.isMobile && !props.showEtaLink ? "mapButton" : "control-hidden"}>
        {props.drawPolygonMap}
      </div>
      {/* Layer button */}
      <div id={'layer-idcontrol'} className={!props.generatePrint && !props.isMobile && !props.showEtaLink ? "mapButton" : "control-hidden"}>
        {props.layer}
      </div>
      {/* legends button */}
      {!props.hiddenLegends && (
        <div id={'legends-idcontrol'} className={!props.generatePrint && !props.isMobile && !props.showEtaLink ? 'controls legends-control' : "control-hidden"}>
          {props.legends}
        </div>
      )}

      {/* traffic button */}
      {!props.hiddenTraffic && (
        <div id={'traffic-idcontrol'} className={!props.generatePrint ? "controls traffic-control" : "control-hidden"}>
          <TrafficToggle
            onShowTraffic={showTraffic}
            onHideTraffic={hideTraffic}
            isTrafficEnabled={traffic}
          />
        </div>
      )}
      
      <div id={'weather-idcontrol'} className={!props.generatePrint ? "controls traffic-control" : "control-hidden"}>
      {(!props.noRealtime && !props.hiddenWeather && weatherPermision) &&
          <WeatherToggle
            onShowWeather={showWeather}
            onHideWeather={hideWeather}
            isWeatherEnabled={isWeatherEnabled}
          />
      }
      </div>

      {/* legends button */}
      <div id={'speed-override-idcontrol'} className={props.showSpeedOverride ? 'controls speed-override-control' : "control-hidden"}>
        {props.speedOverrideControl}
      </div>

      <div id={'route-info-idcontrol'} className={props.showRoutesInfoMap ? 'controls route-info-control' : "control-hidden"}>
        {props.routesInfoMapControl}
      </div>

      {/* Input search information*/}
      {!props.hiddenSearchMap && (
        <div id={'input_SearchMap'} className={!props.generatePrint ? "controlSearch inputsearch-control" : "control-hidden"}>
          {props.inputSearchMap}
        </div>
      )}
      {/* Map Pagination Control*/}
      <div id={'input_Pagination'} className={props.enableVehicleTrails ? 'controls pagination-control' : 'controls pagination-controlhidden'}>
        {props.mPagination}
      </div>

      {/* Map Settings button */}
      <div id={'draw_LayerMap'} className={!props.generatePrint && !props.isMobile && !props.showEtaLink ? 'mapButton' : "control-hidden"}>
        {props.drawLayerMap}
      </div>

      {/* Map button Center Vehicles */}
      <div id={'center_vehicles'} className={!props.generatePrint && props.showButtonZoomVehicles ? 'mapButton' : "control-hidden"}>
        {props.zoomVehicles}
      </div>

      {props.showEtaLink && (
        <div id={'div_etaLink'} className={props.showEtaLink ? props.isMobile ? "etaLink-control etaLink-control-mobile" : "etaLink-control" : "control-hidden"}>
          {props.panelEtaLink}
        </div>
      )}

      {/* Units Label */}
      {/* <div>
        {props.unitLabel}
      </div> */}

      <div id={'info-bubble'}>
        <InfoWindow
          mapInstance={mapItem}
          open={openInfoWindow}
          overlay={customOverlay}
          messages={props.messages}
          close={() => {
            hideMarkerModal();
          }}
        >
          {infoBubbleContent}
        </InfoWindow>
      </div>
    </Fragment>
  )
})


export default GoogleProvider