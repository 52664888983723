//Module Keys
let locales = {};

//Merge Views
import main from 'Modules/realtimeMaps/main/lan/es';
locales = { ...locales, ...main };

import mainView from 'Modules/realtimeMaps/main/components/MapUtilitiesMenu/lan/es';
locales = { ...locales, ...mainView };

import vehicleList from 'Modules/realtimeMaps/main/components/VehicleList/lan/es';
locales = { ...locales, ...vehicleList };

import ModalVisibleGroupsTags from 'Modules/realtimeMaps/main/components/modalVisibleGroupTags/lan/es';
locales = { ...locales, ...ModalVisibleGroupsTags };

import routes from 'Modules/realtimeMaps/main/components/routes/lan/es';
locales = { ...locales, ...routes };

import vehicleTrail from 'Modules/realtimeMaps/main/components/VehicleTrails/lan/es';
locales = { ...locales, ...vehicleTrail };

import landmarks from 'Modules/realtimeMaps/main/components/LandmarksListMain/lan/es';
locales = { ...locales, ...landmarks };

import landmarkMain from 'Modules/realtimeMaps/main/components/landmarks/lan/es';
locales = { ...locales, ...landmarkMain };

import landmarkList from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkList/lan/es';
locales = { ...locales, ...landmarkList };

import landmarkSettings from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkSettings/lan/es';
locales = { ...locales, ...landmarkSettings };

import landmarkVisibleGroups from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkVisibleGroups/lan/es';
locales = { ...locales, ...landmarkVisibleGroups };

import oldGeofence from 'Modules/realtimeMaps/main/components/GeofenceListMain/lan/es';
locales = { ...locales, ...oldGeofence };

import geofence from 'Modules/realtimeMaps/main/components/geofences/lan/es';
locales = { ...locales, ...geofence };

import SendMessage from 'Modules/realtimeMaps/main/components/SendMessage/lan/es';
locales = { ...locales, ...SendMessage };

import vehicleDirections from 'Modules/realtimeMaps/main/components/VehicleDirections/lan/es';
locales = { ...locales, ...vehicleDirections };

import LayerMap from 'Modules/realtimeMaps/main/components/LayerMap/lan/es';
locales = { ...locales, ...LayerMap };

import findNearest from 'Modules/realtimeMaps/main/components/findNearest/lan/es';
locales = { ...locales, ...findNearest };

import UnitSelection from 'Modules/realtimeMaps/main/components/UnitSelection/lan/es';
locales = { ...locales, ...UnitSelection };

import mapSetting from 'Modules/realtimeMaps/main/components/MapSetting/lan/es';
locales = { ...locales, ...mapSetting };

import eta from 'Modules/realtimeMaps/main/components/eta/lan/es';
locales = { ...locales, ...eta };

import speedOverride from 'Modules/realtimeMaps/main/components/speedOverride/lan/es';
locales = { ...locales, ...speedOverride };

import vicinityTool from 'Modules/realtimeMaps/main/components/vicinityTool/lan/es';
locales = { ...locales, ...vicinityTool };

import etaCreateEdit from 'Modules/realtimeMaps/main/components/eta/components/etaCreateEdit/lan/es';
locales = { ...locales, ...etaCreateEdit };

import etaTable from 'Modules/realtimeMaps/main/components/eta/components/etaTable/lan/es';
locales = { ...locales, ...etaTable };

import etaDelete from 'Modules/realtimeMaps/main/components/eta/components/etaDelete/lan/es';
locales = { ...locales, ...etaDelete };

export default locales;